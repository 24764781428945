<script setup lang="ts">
import { FileAttachment } from "@/bapi-client/types/common/attachment";
import { computed } from "vue";

interface Props {
  file: FileAttachment;
}

const props = defineProps<Props>();

const icons: Record<string, string> = {
  csv: "file-csv",
  xls: "file-excel",
  xlsx: "file-excel",
  pdf: "file-pdf",
  txt: "file-lines",
  rtf: "file-lines",
  doc: "file-word",
  docx: "file-word",
  mov: "file-video",
  mpv: "file-video",
  mp4: "file-video",
};

const icon = computed(() => icons[props.file.extension] ?? "file");
</script>

<template>
  <span
    class="w-18 flex h-12 items-center gap-2 rounded-full border bg-white px-4 py-6 align-middle text-sm transition hover:bg-white hover:shadow-md hover:ring hover:ring-blue"
  >
    <i class="fa-light fa-2x ml-2" :class="['fa-' + icon]"></i>
    <span class="flex items-center border-l-2 border-l-gray-400 pl-2 font-medium">
      {{ file.fileName }}
    </span>
  </span>
</template>
