<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import { usePapiClient } from "@/composables/papiClient";
import { BookingListPatternsRequest, ListPatternsResponse } from "@telegraphio/papi-client";
import { TgPagination, TgTable } from "@/components/common";
import PatternListItem from "./PatternListItem.vue";
import { LayoutBox } from "@/components/layout";
import useNotificationStore from "@/stores/notifications";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useSorted } from "@/composables/sorted";

const props = defineProps<{
  companyId: string;
}>();

const { papiApi } = usePapiClient();
const router = useRouter();

const { user } = useUserStore();
const notifier = useNotificationStore();
const trackers = useTrackerStore();

const currentPage = ref(1);
const pageSize = ref(25);
const createdByFilter = ref("all");
const keywordFilter = ref("");
const patterns = ref<ListPatternsResponse["patterns"]>([]);
const columns = [
  { label: "Pattern Name", value: "label" },
  { label: "Created By", value: "createdBy" },
  { label: "Last Used", value: "lastUsedDate" },
  "",
];

const filteredPatterns = computed(() => {
  let filtered = patterns.value ?? [];

  if (createdByFilter.value === "user") {
    filtered = filtered.filter((pattern) => pattern.createdBy === user?.userId);
  }

  if (keywordFilter.value) {
    const keywords = keywordFilter.value.toLowerCase();
    filtered = filtered.filter((pattern) => pattern.label?.toLowerCase().includes(keywords));
  }

  return filtered;
});

const { sorted, sortColumn, sortDirection } = useSorted(filteredPatterns, "label", "ASC");

const paginatedPatterns = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return sorted.value.slice(start, end);
});

const totalPages = computed(() => Math.ceil(filteredPatterns.value.length / pageSize.value));

onMounted(async () => {
  try {
    const request: BookingListPatternsRequest = {
      customerId: props.companyId,
      listPatternsRequest: {},
    };

    const response = await papiApi.bookingListPatterns(request);
    patterns.value = response.patterns;
  } catch (error) {
    console.log(error);
  }
});

async function handleDeletePattern(patternId: string) {
  try {
    await papiApi.bookingDeletePattern({
      customerId: props.companyId,
      patternId,
    });

    patterns.value = patterns.value?.filter((pattern) => pattern.patternId !== patternId);
    notifier.setToast("success", "Pattern deleted");
  } catch (error) {
    notifier.setToast("danger", "Failed to delete pattern");
  }
}

async function handleDuplicatePattern(patternId: string) {
  try {
    notifier.setLoading("Duplicating pattern");

    const hostPattern = await papiApi.bookingGetPattern({
      customerId: props.companyId,
      getPatternRequest: { patternId },
    });

    const { label, pattern, inputs } = hostPattern;

    const newPattern = await papiApi.bookingUpsertPattern({
      customerId: props.companyId,
      upsertPatternRequest: {
        label: `${label} - DUPLICATE`,
        pattern,
        inputs,
      },
    });

    router.push({ name: "editPattern", params: { patternId: newPattern.patternId } });
  } catch (error) {
    notifier.setToast("danger", "Duplication failed");
  } finally {
    notifier.setLoading();
  }
}

function handleSort(column: string | number | string[]) {
  sortDirection.value = sortDirection.value === "DESC" ? "ASC" : "DESC";
  sortColumn.value = column as string;
}

function handleTrackToggle(filter: any) {
  trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_PATTERN_LIST_TOGGLE_FILTER, { filter });
}
</script>

<template>
  <div>
    <LayoutBox class="mb-6 flex justify-start gap-6 p-6">
      <FormKit
        v-model="keywordFilter"
        type="text"
        name="filter"
        placeholder="Filter by pattern name"
        :classes="{ wrapper: 'w-72', outer: 'w-72 !flex-grow-0', inner: '!w-72', prefixIcon: '!text-gray-400' }"
        prefix-icon="search"
      />
      <FormKit
        v-model="createdByFilter as any"
        enforced
        type="togglebuttons"
        name="created_by"
        :options="{ user: 'My Patterns', all: 'All Patterns' }"
        @input="handleTrackToggle"
      />
    </LayoutBox>

    <LayoutBox>
      <TgPagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :last-page="totalPages"
        :total="filteredPatterns.length"
        with-page-size
        class="p-6"
      />
      <TgTable
        class="pt-3"
        :columns="columns"
        :sort-column="sortColumn"
        :sort-direction="sortDirection"
        @sort="handleSort"
      >
        <PatternListItem
          v-for="pattern in paginatedPatterns"
          :key="pattern.patternId"
          :pattern="pattern"
          @delete="handleDeletePattern"
          @duplicate="handleDuplicatePattern"
        />
      </TgTable>
    </LayoutBox>
  </div>
</template>
