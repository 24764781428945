import { defineStore } from "pinia";
import { computed, ref } from "vue";
import dayjs from "dayjs";
import { useFetch } from "@/composables/fetch";
import { useUserStore } from "./user";
import useNotificationStore from "./notifications";
import { PipelineAsset, PipelineAssetResponse, makePipelineAsset } from "@/models/assets/pipelineAsset";
import { toAlphanumeric } from "@/utils/text";
import useTrackerStore from "./trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { createCsv, downloadCsv } from "@/utils/csv";
import { useFiltered } from "@/composables/filtered";
import { useSorted } from "@/composables/sorted";
import { useReportingGroups } from "@/composables/reportingGroups";

interface ReportingGroupDestination {
  id: string;
  name: string;
  searchTag: string;
}

export const usePipelineStore = defineStore("pipeline", () => {
  const reportingGroupDestinations = ref<ReportingGroupDestination[]>([]);
  const selectedReportingGroups = ref<string[]>([]);
  const selectedDestinations = ref<string[]>([]);
  // Yes it's a number but the API wants a string of a number
  // and our select menus don't support numbers right now.
  const maxAge = ref("2");
  const assets = ref<PipelineAsset[]>([]);
  const userStore = useUserStore();
  const pipelineURL = computed(() => `/reports/${userStore.companyId}/inbound_pipeline`);
  const destinationsURL = computed(() => `/reports/${userStore.companyId}/destinations`);
  const count = ref(0);
  const today = ref(dayjs());
  const { filtered, filters, resetFilters, hasFilters } = useFiltered<PipelineAsset>(assets);
  const { sorted, sortColumn, sortDirection } = useSorted(filtered, "daysSinceLastSighting", "DESC");
  const { reportingGroups } = useReportingGroups();

  filters.value = {
    daysSinceLastSighting: { operand: "greater_than_or_equals", value: 0 },
    loadEmptyStatus: { operand: "equals", value: "" },
    exceptions: { operand: "contains", value: [] },
    lastEventText: { operand: "contains", value: [] },
    commodity: { operand: "contains", value: [] },
    statusCode: { operand: "contains", value: [] },
  };

  function reset() {
    resetFilters();
    reportingGroupDestinations.value = [];
    selectedReportingGroups.value = [];
    selectedDestinations.value = [];
    maxAge.value = "2";
    count.value = 0;
    assets.value = [];
    sortColumn.value = "daysSinceLastSighting";
    sortDirection.value = "DESC";
  }

  const tableType = computed(() => {
    if (userStore.company?.waybillType === "both") {
      return "both";
    }
    return userStore.company?.waybillType === "R" ? "railcar" : "imdl";
  });

  async function getAssets() {
    const notifier = useNotificationStore();
    notifier.setLoading("Loading report");
    const request = useFetch();
    const response = await request.post(pipelineURL.value, {
      body: {
        destination: selectedDestinations.value.join(","),
        group_ids: selectedReportingGroups.value,
        max_age: maxAge.value,
      },
    });
    notifier.setLoading();

    if (!response.ok) {
      notifier.setToast("danger", "Unable to load report");
      return undefined;
    }

    const data = await response.json();
    const formattedAssets = data.data.map((asset: PipelineAssetResponse) => makePipelineAsset(asset));
    assets.value = formattedAssets;
    const trackers = useTrackerStore();
    const rsData = {
      groupNames: reportingGroups.value
        .reduce((acc: string[], curr) => {
          if (selectedReportingGroups.value.includes(curr.id)) {
            acc.push(curr.name);
          }
          return acc;
        }, [])
        .join(","),
      groupIds: selectedReportingGroups.value.join(","),
      destinationId: selectedDestinations.value.join(","),
      maxAge: maxAge.value,
      table: tableType.value,
      success: response.ok,
      resultsCount: data.record_count,
    };
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_PIPELINE_GENERATE, rsData);
  }

  async function getDestinations() {
    const notifier = useNotificationStore();
    notifier.setLoading("Loading destinations");
    const request = useFetch();
    const response = await request.post(destinationsURL.value, {
      body: {
        group_ids: selectedReportingGroups.value,
      },
    });
    notifier.setLoading();
    if (!response.ok) {
      notifier.setToast("danger", "Unable to load destinations at this time.");
      return undefined;
    }

    const data = await response.json();
    reportingGroupDestinations.value = data.data.map((dest: { id: string; name: string }) => ({
      id: dest.id,
      name: dest.name,
      searchTag: toAlphanumeric(dest.name),
    }));
  }

  function clearFilters() {
    resetFilters();
    sortColumn.value = ["teleETADate", "railETADate"];
    sortDirection.value = "ASC";
  }

  function prepareCsv() {
    const trackers = useTrackerStore();
    const groupNames = reportingGroups.value
      .reduce((acc: string[], curr) => {
        if (selectedReportingGroups.value.includes(curr.id)) {
          acc.push(curr.name);
        }
        return acc;
      }, [])
      .join(",");

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_PIPELINE_DOWNLOAD_CSV, {
      groupIds: selectedReportingGroups.value,
      groupNames,
      destinationId: selectedDestinations.value.join(","),
      maxAge: maxAge.value,
      table: tableType.value,
    });

    const headers =
      tableType.value === "railcar"
        ? [
            "Asset ID",
            "L/E",
            "Reporting Group(s)",
            "Commodity",
            "Current Location",
            "Last Event",
            "Last Event Date",
            "Destination",
            "Arrival Date",
            "Telegraph ETA",
            "Railroad ETA",
            "Current Carrier",
            "Origin",
            "Dwell",
            "Last Note",
          ]
        : [
            "Asset ID",
            "L/E",
            "Reporting Group(s)",
            "BOL #",
            "Last Event",
            "Last Event Date",
            "Current Location",
            "Origin",
            "Destination",
            "Arrival Date",
            "Telegraph ETA",
            "Railroad ETA",
            "Current Carrier",
            "Notified",
            "Pickup #",
            "Train ID",
            "Dwell",
            "Last Note",
          ];

    const list = sorted.value.length ? filtered : assets;
    const rows = [];
    for (const asset of list.value as PipelineAsset[]) {
      const data = [];
      const dateFormatter = (date: string) => dayjs(date).format("MM/DD/YYYY HH:mm");
      const groupNames = [];
      for (const groupId of asset.reportingGroups) {
        const name = reportingGroups.value.find((group) => group.id === groupId)?.name;
        if (name) {
          groupNames.push(name);
        }
      }

      const arrivalDate = asset.statusCode > 50 && asset.lastSightingDate ? dateFormatter(asset.lastSightingDate) : "";

      const teleETA = asset.statusCode < 50 && asset.teleETA?.date ? dateFormatter(asset.teleETA.date) : "";

      const railETA = asset.statusCode < 50 && asset.railETA?.date ? dateFormatter(asset.railETA.date) : "";

      data.push(asset.equipmentId);
      data.push(asset.loadEmptyStatus);
      data.push(`"${groupNames.join(",")}"`);

      if (tableType.value === "railcar") {
        data.push(`"${asset.commodity}"`);
        data.push(`"${asset.currentLocation}"`);
        data.push(`${asset.lastEventText}`);
        data.push(`${asset.lastSightingDate ? dateFormatter(asset.lastSightingDate) : ""}`);
        data.push(`"${asset.destinationLocation}"`);
        data.push(arrivalDate);
        data.push(teleETA);
        data.push(railETA);
        data.push(asset.currentCarrier);
        data.push(`"${asset.originLocation}"`);
        data.push(asset.daysSinceLastSighting);
        data.push(`"${asset.note ? asset.note.note : ""}"`);
        rows.push(data);
        continue;
      }

      data.push(asset.billOfLadingNumber ? asset.billOfLadingNumber : "");
      data.push(asset.lastEventText);
      data.push(asset.lastSightingDate ? dateFormatter(asset.lastSightingDate) : "");
      data.push(`"${asset.currentLocation}"`);
      data.push(`"${asset.originLocation}"`);
      data.push(`"${asset.destinationLocation}"`);
      data.push(arrivalDate);
      data.push(teleETA);
      data.push(railETA);
      data.push(asset.currentCarrier);
      data.push(asset.lastNotifyDate ? dateFormatter(asset.lastNotifyDate) : "");
      data.push(asset.imdlPickupNumber ? asset.imdlPickupNumber : "");
      data.push(asset.trainId ? asset.trainId : "");
      data.push(`${asset.daysSinceLastSighting}`);
      data.push(asset.note ? `"${asset.note?.note}"` : "");
      rows.push(data);
    }

    const csv = createCsv(headers, rows);
    downloadCsv(`pipeline-report-${dayjs().format("MM-DD-YYYY")}.csv`, csv);
  }

  return {
    reset,
    reportingGroups,
    reportingGroupDestinations,
    selectedReportingGroups,
    selectedDestinations,
    tableType,
    today,
    maxAge,
    count,
    assets,
    filtered,
    filters,
    clearFilters,
    sorted,
    hasFilters,
    sortColumn,
    sortDirection,
    getAssets,
    getDestinations,
    prepareCsv,
  };
});
