import dayjs from "dayjs";
import { defineStore } from "pinia";
import ReportingGroup from "@/models/reportingGroup";
import NetworkMapReport from "@/models/networkMapReport";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { useUserStore } from "./user";
import { useFetch } from "@/composables/fetch";
import useNotificationStore from "./notifications";
import useTrackerStore from "./trackers";
import type { AnalyticsNetworkMapGenerateEvent } from "@/types/rudderstack";

interface State {
  groupList: ReportingGroup[];
  selectedGroupId: string;
  report: NetworkMapReport | null;
  fromDate: Date;
  toDate: Date;
  loading: boolean;
}

const useNetworkMapStore = defineStore("network", {
  state: (): State => ({
    groupList: [],
    selectedGroupId: "",
    report: null,
    fromDate: dayjs().day(1).subtract(4, "week").toDate(),
    toDate: dayjs().day(1).toDate(),
    loading: false,
  }),
  getters: {
    selectedGroup: (state: State) => state.groupList.find((group) => group.id === state.selectedGroupId),
  },
  actions: {
    async getGroups() {
      const user = useUserStore();
      const notifier = useNotificationStore();
      const request = useFetch();
      const response = await request.get(`/reports/${user.companyId}/groups`);
      if (!response.ok) {
        if (response.status === 404) {
          notifier.setToast("info", "No groups found.");
          return undefined;
        }
        notifier.setToast("danger", "Unable to load groups at this time.");
        return undefined;
      }
      const data: {
        data: {
          deleted: boolean;
          id: string;
          name: string;
        }[];
        success: boolean;
      } = await response.json();
      const groupList = data.data.map((group) => new ReportingGroup(group));
      this.groupList = groupList;
    },
    async selectGroup(groupId: string) {
      this.selectedGroupId = groupId;
      await this.generateReport();
    },
    async selectDateRange(value: Date[]) {
      if (value[0] && value[1]) {
        this.fromDate = value[0];
        this.toDate = value[1];
        await this.generateReport();
      }
    },
    async generateReport() {
      const user = useUserStore();
      const notifier = useNotificationStore();
      const trackerStore = useTrackerStore();
      const startDate = dayjs(this.fromDate).format("YYYY-MM-DD");
      const endDate = dayjs(this.toDate).format("YYYY-MM-DD");

      const rsData: AnalyticsNetworkMapGenerateEvent = {
        group_id: this.selectedGroup?.id,
        group_label: this.selectedGroup?.name,
        date_start: this.fromDate.toISOString(),
        date_end: this.toDate.toISOString(),
        success: false,
      };
      notifier.setLoading();

      const request = useFetch();
      const response = await request.get(
        `/reports/${user.companyId}/network/${this.selectedGroupId}?fromDate=${startDate}&toDate=${endDate}`,
      );
      notifier.setLoading();

      if (!response.ok) {
        trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_NETWORK_MAP_GENERATE, rsData);
        if (response.status === 404) {
          notifier.setToast("info", "No data found for the requested group/date range.");
          return undefined;
        }
        notifier.setToast("danger", "Unable to load report at this time.");
        return undefined;
      }

      const data: {
        data: {
          id: string;
          olng: number;
          olat: number;
          dlng: number;
          dlat: number;
        }[];
        record_count: number;
      } = await response.json();

      rsData.success = true;
      rsData.results_count = data.record_count;
      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_NETWORK_MAP_GENERATE, rsData);

      if (data.record_count === 0) {
        notifier.setToast("info", "No trips recorded in the given date range.");
        return undefined;
      }
      this.report = new NetworkMapReport(data);
    },
  },
});

export default useNetworkMapStore;
