<script setup lang="ts">
import { useRoute } from "vue-router";
const route = useRoute();
</script>

<template>
  <nav v-if="route?.meta?.breadcrumbs" data-test="page-breadcrumbs" class="flex items-center gap-2">
    <template v-for="breadcrumb in route.meta?.breadcrumbs" :key="breadcrumb.label">
      <template v-if="!breadcrumb.on || breadcrumb.on?.includes(route.name?.toString() ?? '')">
        <router-link
          v-if="breadcrumb.to && breadcrumb.to !== route.name"
          class="whitespace-nowrap text-sm font-bold text-blue-500/75"
          :to="{ name: breadcrumb.to }"
        >
          {{ breadcrumb.label }}
        </router-link>
        <span v-else class="whitespace-nowrap text-sm font-bold" :class="[!breadcrumb.to ? 'text-gray-500/90' : '']">
          {{ breadcrumb.label }}
        </span>
        <span class="text-sm text-gray-500/75 last:hidden">
          <i class="fa-solid fa-angle-right"></i>
        </span>
      </template>
    </template>
  </nav>
</template>
