import type {
  BAPICustomer,
  BAPICustomerListItem,
  InternalToolsCustomer,
  InternalToolsCustomerListItem,
} from "@/bapi-client/types/internal_tools";
import { toAlphanumeric } from "@/utils/text";

export function internalToolsCustomerFactory(data: BAPICustomer): InternalToolsCustomer {
  return {
    id: data.id,
    crmUrl: data.crm_url,
    equipmentIds: data.equipment_ids,
    name: data.name,
    businessName: data.business_name,
    zipCode: data.zip_code ?? "",
    domain: data.domain,
    logo: data.logo,
    type: data.type,
    typeId: data.type_id,
    waybillType: data.waybill_type,
    waybillTypeSafe: data.waybill_type_safe,
    minimumWaybillDate: data.minimum_waybill_date,
    integrations: data.integrations,
    parties: data.parties,
    reportingGroups: data.reporting_groups,
    fleetIds: data.fleet_ids,
    scacs: data.scacs,
    status: data.status,
    users: data.users.map((user) => ({
      id: user.id,
      status: user.status,
      roleId: user.role_id,
      roleName: user.role_name,
      fullName: user.full_name,
    })),
  };
}

export function internalToolsCustomerListFactory(data: BAPICustomerListItem): InternalToolsCustomerListItem {
  return {
    id: data.id,
    crmUrl: data.crm_url,
    name: data.name,
    businessName: data.business_name,
    zipCode: data.zip_code ?? "",
    domain: data.domain,
    logo: data.logo,
    type: data.type,
    typeId: data.type_id,
    waybillType: data.waybill_type,
    waybillTypeSafe: data.waybill_type_safe,
    minimumWaybillDate: data.minimum_waybill_date,
    integrations: data.integrations,
    status: data.status,
    searchTag: toAlphanumeric(`${data.name}${data.business_name}`),
  };
}
