import { toAlphanumeric } from "@/utils/text";

interface LocationModel {
  id: string;
  name: string;
  searchTag: string;
}

export default class Location implements LocationModel {
  constructor(
    responseObject: any,
    public id: string = responseObject.id,
    public name: string = responseObject.name,
    public searchTag: string = toAlphanumeric(responseObject.name),
  ) {}
}
