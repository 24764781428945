<script setup lang="ts">
import { computed } from "vue";
import { useFormKitContextById } from "@formkit/vue";
import { FilterPanel, FilterBox } from "../filters";
import { BulkSearch } from "@/components/common";
import StatusFilter from "./StatusFilter.vue";
import PriorityFilter from "./PriorityFilter.vue";
import { useBulkSearch } from "@/composables/BulkSearch";
import useTrackerStore from "@/stores/trackers";
import { useCustomerSupportStore } from "@/stores/customerSupport";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import type { TicketFilterForm } from "@/types/customerSupport";

const trackers = useTrackerStore();
const csStore = useCustomerSupportStore();
const emit = defineEmits<{ apply: [value?: TicketFilterForm]; reset: [] }>();
const {
  updatePreviousSearchTermCount,
  resetBulkSearch,
  searchTerms,
  currentSearchTermsCount,
  previousSearchTermsCount,
} = useBulkSearch();

const tagOptions = [
  { label: "Bad Order", value: "Bad Order" },
  { label: "Congestion Delay/Delay", value: "Congestion Delay/Delay" },
  { label: "Derailment", value: "Derailment" },
  { label: "Dirty Car/Pressure Wash", value: "Dirty Car/Pressure Wash" },
  { label: "Diversion", value: "Diversion" },
  { label: "Fuel", value: "Fuel" },
  { label: "Impact", value: "Impact" },
  { label: "Out of Route", value: "Out of Route" },
  { label: "Railcar Maintenance", value: "Railcar Maintenance" },
  { label: "Railroad Ticket", value: "Railroad Ticket" },
  { label: "Refrigeration Maintenance", value: "Refrigeration Maintenance" },
  { label: "Shop", value: "Shop" },
  { label: "Other", value: "Other" },
];

const formContext = useFormKitContextById<TicketFilterForm>("customer-support-filters");
const formValue = computed(() => formContext.value?.value);

const filterChips = computed(() => {
  if (!formValue.value) return [];

  const priorities = [
    formValue.value?.priority.medium,
    formValue.value?.priority.low,
    formValue.value?.priority.high,
  ].filter((p) => !!p);

  return [
    {
      label: "Asset Search",
      value: `${formValue.value.asset_ids ? formValue.value.asset_ids.split("\n").length : 0}`,
      disabled: !formValue.value.asset_ids?.length,
    },
    {
      label: "Status",
      value: formValue.value.state.length,
      disabled: !formValue.value.state.length || formValue.value.state.length === csStore.filterDefaults.state.length,
    },
    {
      label: "Priority",
      value: priorities.length === 3 ? "All" : priorities.length,
      disabled: priorities.length === 3,
    },
    {
      label: "My Tickets",
      value: formValue.value.my_tickets ? "Selected" : undefined,
      disabled: false,
    },
    {
      label: "Tags",
      value: formValue.value.tags.length === 1 ? formValue.value.tags[0] : formValue.value.tags.length,
      disabled: false,
    },
    {
      label: "Keyword(s)",
      value: formValue.value.keyword_search,
      disabled: false,
    },
  ];
});

function resetFilters() {
  if (formContext.value) {
    formContext.value.node.reset();
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.CUSTOMER_SUPPORT_TICKETS_RESET_FILTER, {});
    resetBulkSearch();
    emit("apply");
  }
}

function applyFilters() {
  if (!formValue.value) return undefined;
  trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.CUSTOMER_SUPPORT_TICKETS_FILTER, {
    filters: formValue.value,
  });
  csStore.filterForm = {
    ...formValue.value,
    asset_ids: searchTerms.value,
  };
  emit("apply");
}

function searchAssets() {
  if (formValue.value) {
    updatePreviousSearchTermCount();
    csStore.filterForm = {
      ...formValue.value,
      asset_ids: searchTerms.value,
    };
  }
}

function resetAssetSearch() {
  resetBulkSearch();
  if (formValue.value) {
    csStore.filterForm = {
      ...formValue.value,
      asset_ids: "",
    };
  }
}
</script>

<template>
  <FilterPanel
    v-model="csStore.filterForm"
    class="my-4"
    name="customer-support"
    :values="csStore.filterDefaults"
    :chips="filterChips"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <FilterBox class="col-span-full grid items-center lg:col-span-2 2xl:col-span-3">
      <BulkSearch
        id="cs-bulk-search"
        v-model="searchTerms"
        name="asset_ids"
        singular="Asset"
        plural="Assets"
        :disabled="false"
        :typed-entries-count="currentSearchTermsCount"
        :searched-for-entries-count="previousSearchTermsCount"
        placeholder="Enter one asset ID per line"
        @search="searchAssets"
        @clear="resetAssetSearch"
      />
    </FilterBox>
    <FilterBox class="col-span-full grid items-center justify-self-stretch pb-8 lg:col-span-2 2xl:col-span-3">
      <FormKit
        type="dropdown"
        data-testid="tags-input"
        name="tags"
        label="Tag Type"
        placeholder="- -"
        multiple
        :classes="{
          outer: 'min-w-56',
          truncationCount: 'bg-gray-400 rounded w-6',
          label: 'text-gray-500 text-xs',
        }"
        :options="tagOptions"
      />
    </FilterBox>
    <FilterBox
      class="col-span-full grid auto-cols-max items-center gap-1.5 lg:grid-flow-col xl:col-start-5 xl:grid-flow-row 2xl:col-start-7 2xl:col-end-[-1] 2xl:grid-flow-col"
    >
      <FormKit
        type="text"
        name="keyword_search"
        placeholder="Search by Keyword"
        prefix-icon="search"
        :classes="{ prefixIcon: 'text-gray-500', outer: 'w-64 !grow-0' }"
      />
      <StatusFilter />
    </FilterBox>

    <FilterBox class="col-span-full">
      <PriorityFilter />
    </FilterBox>
  </FilterPanel>
</template>
