<script setup lang="ts">
import { LayoutBox } from "../layout";
import useNotificationStore from "@/stores/notifications";
const store = useNotificationStore();
</script>

<template>
  <TransitionGroup
    tag="div"
    enter-active-class="ease-in transition duration-100"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-out transition duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <LayoutBox
      v-for="toast in store.toastList"
      :key="toast.id"
      class="shadow-md"
      :class="[toast.noTimeout === true ? 'flex' : '']"
    >
      <span v-if="toast.noTimeout === true" class="ml-2 mt-2 w-4 text-lg" @click="store.clearToast(toast.id)">
        <i class="fa-solid fa-circle-x"></i>
      </span>
      <div class="flex items-center justify-between p-6">
        <span
          class="w-12 text-3xl"
          :class="[
            toast.status === 'danger' ? 'text-red-500' : '',
            toast.status === 'success' ? 'text-green-atomic' : '',
            toast.status === 'info' ? 'text-blue-sky' : '',
          ]"
        >
          <i class="fa-light fa-2x" :class="'fa-' + toast.icon"></i>
        </span>
        <span class="ml-4 whitespace-normal text-lg">{{ toast.message }}</span>
      </div>
    </LayoutBox>
  </TransitionGroup>
</template>
