import { RouteRecordRaw } from "vue-router";
import CustomerSupportDashboard from "@/pages/customerSupport/CustomerSupportDashboard.vue";
import TicketDetails from "@/components/customerSupport/TicketDetails.vue";
import TicketForm from "@/components/customerSupport/TicketForm.vue";

const customerSupportRoutes: RouteRecordRaw = {
  name: "customerSupport",
  path: "/customer-support/:customerId/tickets",
  component: CustomerSupportDashboard,
  props: true,
  meta: { authenticated: true, title: "Telegraph - Customer Support" },
  children: [
    {
      name: "newTicket",
      path: "new",
      component: TicketForm,
      meta: { authenticated: true },
      props: true,
    },
    {
      name: "ticket",
      path: ":ticketId",
      component: TicketDetails,
      meta: { authenticated: true },
      props: true,
    },
    {
      name: "editTicket",
      path: ":ticketId/edit",
      component: TicketForm,
      meta: { authenticated: true },
      props: true,
    },
  ],
};

export default customerSupportRoutes;
