<script setup lang="ts">
import { LayoutBox } from "@/components/layout";
import { BreadcrumbLink, BreadcrumbLinks, TgButton } from "@/components/common";
import useTrackerStore from "@/stores/trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";

defineProps<{ customerId: string }>();

const trackers = useTrackerStore();
</script>

<template>
  <LayoutBox as="header" class="flex w-full p-6">
    <div>
      <BreadcrumbLinks>
        <BreadcrumbLink>Customer Support</BreadcrumbLink>
        <BreadcrumbLink active :to="{ name: 'customerSupport', params: { customerId } }">
          Customer Support Dashboard
        </BreadcrumbLink>
      </BreadcrumbLinks>
      <h1 class="flex items-center gap-1.5 align-middle text-3xl font-bold">
        <span class="text-xl"><i class="fa-regular fa-headset"></i></span>
        Customer Support Dashboard
      </h1>
    </div>
    <TgButton
      class="ml-auto"
      color="success"
      leading-icon="circle-plus"
      :to="{ name: 'newTicket', params: { customerId } }"
      @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.CUSTOMER_SUPPORT_TICKET_START_CREATE, {})"
    >
      New Ticket
    </TgButton>
  </LayoutBox>
</template>
