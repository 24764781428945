import "@vuepic/vue-datepicker/dist/main.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/solid.min.css";
import "./assets/css/light.min.css";
import "./assets/css/thin.min.css";
import "./assets/css/duotone.min.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { createPinia } from "pinia";
import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { LDPlugin } from "launchdarkly-vue-client-sdk";
import router from "./router";
import App from "./App.vue";
import tooltipPlugin from "./plugins/tooltip";
import { piniaPlugin } from "./plugins/piniaRouter";
import datePlugin from "./plugins/date";
import timeAgoPlugin from "./plugins/timeAgo";
import statusPlugin from "./plugins/status";
import { plugin as formKitPlugin, defaultConfig } from "@formkit/vue";
import "./style/app.css";
import formKitConfig from "../formkit.config";
import EditEdiFormField from "./components/waybilling/EditEdiFormField.vue";

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPlugin(router));

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracePropagationTargets: ["dashboard.telegraph.io", "preview.telegraph.io", /^\//],
    // Set sample rates to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    logErrors: true,
  });
}

app.component("EditEdiFormField", EditEdiFormField);

app.use(pinia);
app.use(router);
app.use(tooltipPlugin);
app.use(datePlugin);
app.use(timeAgoPlugin);
app.use(statusPlugin);
app.use(formKitPlugin, defaultConfig(formKitConfig));
app.use(LDPlugin, { deferInitialization: true });
app.mount("#app");
