<script setup lang="ts">
import { onMounted } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { TgButton } from "@/components/common";
import useNotificationStore from "@/stores/notifications";
import ErrorPage from "./ErrorPage.vue";
import { useRouter } from "vue-router";

const notifier = useNotificationStore();
const router = useRouter();

function refresh(): void {
  return location.reload();
}

onMounted(() => window.history.pushState({}, document.title, window.location.pathname));

onBeforeRouteUpdate(() => {
  notifier.status = "ready";
});
</script>
<template>
  <ErrorPage>
    <template #title>Sorry about that!</template>
    <template #message>
      We were unable to process your request. Please refresh the page to try again. Please
      <a href="mailto:support@telegraph.io" target="_blank" class="underline">contact us</a> if you continue to see this
      message.
    </template>
    <template #controls>
      <TgButton class="m-4" :is-rounded="true" color="primary" @click="refresh">Refresh</TgButton>
      <a class="text-lg text-blue" @click.prevent="router.push({ path: '/' })">Nevermind, go to Dashboard</a>
    </template>
  </ErrorPage>
</template>
