export type InputOptionsData = {
  label: string;
  fieldId: string;
};

export const designList = [
  "general_shipment_information_BX.transportation_method_type_code_02",
  "general_shipment_information_BX.shipment_method_of_payment_code_03",
  "general_shipment_information_BX.shipment_qualifier_07",
  "general_shipment_information_BX.section_seven_code_08",
  "rail_shipment_information_BNX.shipment_weight_code_01",
  "rail_shipment_information_BNX.billing_code_03",
  "extended_reference_information_N9.reference_identification_qualifier_01",
  "extended_reference_information_N9.reference_identification_02",
  "extended_reference_information_N9.date_04",
  "transaction_set_line_number_LX_loop.description_marks_and_numbers_L5.lading_description_02",
  "transaction_set_line_number_LX_loop.description_marks_and_numbers_L5.commodity_code_03",
  "transaction_set_line_number_LX_loop.description_marks_and_numbers_L5.commodity_code_qualifier_04",
  "transaction_set_line_number_LX_loop.description_marks_and_numbers_L5.packaging_code_05",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.line_item_quantity_and_weight_L0.lading_line_item_number_01",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.line_item_quantity_and_weight_L0.weight_04",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.line_item_quantity_and_weight_L0.weight_qualifier_05",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.line_item_quantity_and_weight_L0.lading_quantity_08",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.line_item_quantity_and_weight_L0.packaging_form_code_09",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.price_authority_identification_PI_loop.price_authority_identification_PI.reference_identification_qualifier_01",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.price_authority_identification_PI_loop.price_authority_identification_PI.reference_identification_02",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.price_authority_identification_PI_loop.price_authority_identification_PI.tariff_agency_code_05",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.price_authority_identification_PI_loop.price_authority_identification_PI.issuing_carrier_identifier_06",
  "equipment_details_N7_loop.equipment_details_N7.equipment_initial_01",
  "equipment_details_N7_loop.equipment_details_N7.equipment_number_02",
  "equipment_details_N7_loop.equipment_details_N7.weight_03",
  "equipment_details_N7_loop.equipment_details_N7.weight_qualifier_04",
  "equipment_details_N7_loop.equipment_details_N7.equipment_description_code_11",
  "equipment_details_N7_loop.seal_numbers_M7.seal_number_01",
  "equipment_details_N7_loop.seal_numbers_M7.seal_number_02",
  "equipment_details_N7_loop.seal_numbers_M7.seal_number_03",
  "equipment_details_N7_loop.seal_numbers_M7.seal_number_04",
  "destination_station_D9.city_name_02",
  "destination_station_D9.state_or_province_code_03",
  "destination_station_D9.country_code_04",
  "origin_station_F9.city_name_02",
  "origin_station_F9.state_or_province_code_03",
  "origin_station_F9.country_code_04",
  "route_information_R2.standard_carrier_alpha_code_01",
  "route_information_R2.routing_sequence_code_02",
  "route_information_R2.city_name_03",
  "party_identification_N1_loop.party_identification_N1.identification_code_04",
  "party_identification_N1_loop.party_identification_N1.entity_identifier_code_01",
  "party_identification_N1_loop.party_identification_N1.name_02",
  "party_identification_N1_loop.party_location_N3.address_information_01",
  "party_identification_N1_loop.party_location_N3.address_information_02",
  "party_identification_N1_loop.geographic_location_N4.city_name_01",
  "party_identification_N1_loop.geographic_location_N4.state_or_province_code_02",
  "party_identification_N1_loop.geographic_location_N4.postal_code_03",
  "party_identification_N1_loop.geographic_location_N4.country_code_04",
  "protective_service_instructions_PS.protective_service_rule_code_01",
  "protective_service_instructions_PS.protective_service_code_02",
  "protective_service_instructions_PS.temperature_04",
  "protective_service_instructions_PS.unit_or_basis_for_measurement_code_03",
  "empty_car_disposition_pended_destination_consignee_E1_loop.empty_car_disposition_pended_destination_consignee_E1.name_01",
  "empty_car_disposition_pended_destination_consignee_E1_loop.empty_car_disposition_pended_destination_city_E4.city_name_01",
  "empty_car_disposition_pended_destination_consignee_E1_loop.empty_car_disposition_pended_destination_city_E4.state_or_province_code_02",
  "empty_car_disposition_pended_destination_consignee_E1_loop.empty_car_disposition_pended_destination_city_E4.country_code_04",
  "empty_car_disposition_pended_destination_consignee_E1_loop.price_authority_identification_PI.reference_identification_qualifier_01",
  "empty_car_disposition_pended_destination_consignee_E1_loop.price_authority_identification_PI.reference_identification_02",
  "empty_car_disposition_pended_destination_consignee_E1_loop.price_authority_identification_PI.tariff_agency_code_05",
  "empty_car_disposition_pended_destination_consignee_E1_loop.price_authority_identification_PI.issuing_carrier_identifier_06",
];
