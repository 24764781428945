import dayjs from "dayjs";

export function sortByStringPropertyInNestedObject(array: any[], objectPropertyPair: string, direction: string): any[] {
  if (!objectPropertyPair.includes(".")) return array;
  const [parentObject, propertyToSort] = objectPropertyPair.split(".");
  const sorted = [...array]
    .filter((item) => !!item[parentObject][propertyToSort])
    .sort((a, b) =>
      new Intl.Collator("en").compare(
        a[parentObject][propertyToSort].toString(),
        b[parentObject][propertyToSort].toString(),
      ),
    );
  direction === "ASC" ? sorted : sorted.reverse();
  // put records with no value for sorted object property at the end of every sorted array
  return [...sorted, ...[...array].filter((a) => !a[parentObject][propertyToSort])];
}

export function sortByStringProperty(array: any[], key: string, direction: string): any[] {
  const sorted = [...array]
    .filter((a) => !!a[key])
    .sort((a, b) => new Intl.Collator("en").compare(a[key].toString(), b[key].toString()));
  direction === "ASC" ? sorted : sorted.reverse();
  // put records with no value for sorted key at the end of every sorted array
  return [...sorted, ...[...array].filter((a) => !a[key])];
}

export function sortByDateProperty(array: any[], key: string, direction: string): any[] {
  const sorted = [...array]
    .filter((a) => !!a[key])
    .sort((a, b) => {
      if (direction === "DESC") {
        return dayjs(a[key].toString()).isBefore(dayjs(b[key].toString())) ? 1 : -1;
      }
      return dayjs(b[key].toString()).isBefore(dayjs(a[key].toString())) ? 1 : -1;
    });
  // put records with no value for sorted key at the end of every sorted array
  return [...sorted, ...[...array].filter((a) => !a[key])];
}

export function sortByNumberProperty(array: any[], key: string, direction: string): any[] {
  const sorted = [...array].filter((a) => !!a[key]).sort((a: any, b: any) => a[key] - b[key]);
  direction === "ASC" ? sorted : sorted.reverse();
  // put records with no value for sorted key at the end of every sorted array
  return [...sorted, ...[...array].filter((a) => !a[key])];
}
