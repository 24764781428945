<script setup lang="ts">
import { ref } from "vue";
import { Popover, PopoverPanel, PopoverButton, TransitionRoot } from "@headlessui/vue";
import { useFloating, autoPlacement, Placement } from "@floating-ui/vue";

interface Props {
  allowedPlacements?: Placement[];
}

const props = withDefaults(defineProps<Props>(), {
  allowedPlacements: () => [
    "bottom",
    "bottom-end",
    "bottom-start",
    "top",
    "top-end",
    "top-start",
    "left",
    "right",
    "right-end",
    "right-start",
    "left-end",
    "left-start",
  ],
});

const reference = ref(null);
const floating = ref(null);
const { floatingStyles } = useFloating(reference, floating, {
  strategy: "absolute",
  middleware: [
    autoPlacement({
      alignment: "start",
      autoAlignment: true,
      allowedPlacements: props.allowedPlacements,
      padding: {
        right: 64,
        bottom: 64,
      },
    }),
  ],
});
</script>

<template>
  <Popover v-slot="{ open }" as="div" class="relative">
    <PopoverButton v-bind="$attrs" ref="reference">
      <slot name="popoverButton" />
    </PopoverButton>
    <TransitionRoot
      :show="open"
      as="div"
      class="relative"
      enter="transition transition  duration-100"
      enter-from="translate-y-1 opacity-0"
      enter-to="translate-y-0 opacity-100 z-50"
      leave="transition transition duration-150"
      leave-from="translate-y-0 opacity-100"
      leave-to="translate-y-1 opacity-0"
    >
      <div class="relative">
        <div ref="floating" class="absolute z-50 mt-1" :style="floatingStyles">
          <PopoverPanel v-slot="{ close }">
            <slot :close="close" />
          </PopoverPanel>
        </div>
      </div>
    </TransitionRoot>
  </Popover>
</template>
