import dayjs from "dayjs";
import { BAPIAssetHistoryResponse, AssetHistory } from "../../types/assets/assetHistory";

export function makeAssetHistory(data: BAPIAssetHistoryResponse): AssetHistory {
  return {
    totalRecordCount: data.total_record_count,
    numberOfPages: data.number_of_pages,
    currentPage: data.current_page,
    currentPageSize: data.current_page_size,
    pageSize: data.page_size,
    data: data.data.map((waybill) => ({
      waybillId: waybill.waybill_id,
      waybillNumber: waybill.waybill_number,
      loadEmptyStatus: waybill.load_empty_status as "L" | "E",
      originLocation: waybill.origin_location,
      destinationLocation: waybill.destination_location,
      waybillDate: dayjs(waybill.waybill_date).format("MM/DD/YYYY"),
      reportingGroups: waybill.reporting_groups,
    })),
    minWaybillDate: data.min_waybill_date,
    maxWaybillDate: data.max_waybill_date,
    reportGroups: data.report_groups,
  };
}
