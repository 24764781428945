import { RouteRecordRaw } from "vue-router";

const ReportBuilderPage = () => import("@/pages/report-builder/ReportBuilderPage.vue");
const ReportBuilderDashboard = () => import("@/pages/report-builder/ReportBuilderDashboard.vue");
const ReportList = () => import("@/pages/report-builder/ReportList.vue");
const ReportView = () => import("@/pages/report-builder/ReportView.vue");
// const ReportEditor = () => import("@/pages/report-builder/ReportEditor.vue");
const ReportCreate = () => import("@/components/reportBuilder/reports/ReportCreate.vue");
const TemplateList = () => import("@/pages/report-builder/TemplateList.vue");
const TemplateEditor = () => import("@/pages/report-builder/TemplateEditor.vue");
const TemplatePreview = () => import("@/pages/report-builder/TemplatePreview.vue");

export const reportBuilderRoutes: RouteRecordRaw = {
  name: "reportBuilder",
  path: "/reports/:companyId/report-builder",
  component: ReportBuilderPage,
  props: true,
  meta: {
    authenticated: true,
  },
  children: [
    {
      name: "reportBuilderDashboard",
      path: "",
      component: ReportBuilderDashboard,
      props: true,
      meta: { title: "Telegraph - Report Builder" },
      redirect: { name: "reportBuilderReports" },
      children: [
        // These get rendered inside of the AppTabs inside the dashboard router-view
        {
          name: "reportBuilderReports",
          path: "reports",
          component: ReportList,
          props: true,
          meta: { title: "Telegraph - Reports" },
        },
        {
          name: "reportBuilderTemplates",
          path: "templates",
          component: TemplateList,
          props: true,
          meta: { title: "Telegraph - Templates" },
        },
      ],
    },
    // These are rendered in the root router-view on /report-builder
    {
      path: "templates",
      children: [
        {
          name: "reportBuilderTemplateNew",
          path: "new",
          component: TemplateEditor,
          meta: { title: "Telegraph - New Template" },
          props: true,
        },
        {
          name: "reportBuilderTemplateEdit",
          path: ":templateId/edit",
          component: TemplateEditor,
          props: true,
        },
        {
          name: "reportBuilderTemplateCopy",
          path: ":templateId/copy",
          component: TemplateEditor,
          meta: { title: "Telegraph - New Template" },
          props: true,
        },
        {
          name: "reportBuilderTemplatePreview",
          path: ":templateId",
          component: TemplatePreview,
          props: true,
        },
        {
          name: "reportBuilderReportNew",
          path: ":templateId/report/new",
          // component: ReportEditor,
          component: ReportCreate,
          props: true,
          meta: { title: "Telegraph - New Report" },
        },
      ],
    },
    {
      path: "reports",
      children: [
        {
          name: "reportBuilderReportEdit",
          path: ":reportId:/edit",
          component: ReportCreate,
          props: true,
        },
        {
          name: "reportBuilderReportCopy",
          path: ":reportId/copy",
          component: ReportCreate,
          props: true,
          meta: { title: "Telegraph - New Report" },
        },
        {
          name: "reportBuilderReportView",
          path: ":reportId",
          component: ReportView,
          props: true,
        },
      ],
    },
  ],
};
