<script setup lang="ts">
import { ref, computed } from "vue";
import { useBapi } from "@/bapi-client";
import { AssetWaybillResponse } from "@/bapi-client/types/assets/assetHistory";
import { watch } from "vue";
import PopoverTag from "./PopoverTag.vue";

interface Props {
  customerId: string;
  labelClasses: string;
  selectedAsset?: string;
}

interface ShipmentOption {
  label: string;
  value: string;
}

const props = defineProps<Props>();

// Track the user's selected shipment (waybillId)
const shipment = ref<string>("");
const selectedShipment = computed({
  get() {
    return shipment.value;
  },
  set(value: string) {
    shipment.value = value;
    const groups = waybillGroups.value?.get(value) ?? [];
    hiddenRgs.value = groups.join();
  },
});
const hiddenRgs = ref<string>("");
// Keep a map of every returned shipment's reporting groups
// waybillId: [rgID1, rgID2]
// Maps waybill_id -> reporting_group_ids
const waybillGroups = ref<Map<string, string[]>>();

// Options available to the dropdown. Populated on open.
const shipmentOptionList = ref<ShipmentOption[]>([]);

// All the reporting groups returned from BAPI in the asset history.
// Maps reporting_group_id -> reporting_group_name
const reportingGroups = ref<Map<string, string>>();

const selectedShipmentReportingGroups = computed<string[]>(() => {
  const out = [];
  const groups = waybillGroups.value?.get(shipment.value) ?? [];
  for (const group of groups) {
    const dg = reportingGroups.value?.get(group);
    if (dg) out.push(dg);
  }
  return out;
});

async function getShipmentsForAsset() {
  if (!props.selectedAsset) return [];
  const response = await useBapi("getAssetHistory", props.customerId, props.selectedAsset, {
    pageSize: 10,
  });

  if (!response.success) {
    return [];
  }

  waybillGroups.value = new Map();
  for (const waybill of response.data.data) {
    waybillGroups.value.set(waybill.waybillId, waybill.reportingGroups);
  }

  reportingGroups.value = new Map(Object.entries(response.data.reportGroups));
  return response.data.data;
}

function optionFormatter(waybill: AssetWaybillResponse) {
  return { label: `${waybill.waybillDate} - #${waybill.waybillNumber}`, value: waybill.waybillId };
}

async function shipmentsLoader(): Promise<ShipmentOption[]> {
  const shipments = await getShipmentsForAsset();
  shipmentOptionList.value = shipments.map(optionFormatter);
  return shipmentOptionList.value;
}

async function optionLoader(value: string, cached?: ShipmentOption) {
  if (!props.selectedAsset) return { label: "No shipments found", value: "" };
  if (cached) {
    return cached;
  }

  const result = await getShipmentsForAsset();
  const found = result.find((shipment) => shipment.waybillId === value);
  return found ? optionFormatter(found) : { label: "No shipment found.", value: "" };
}

watch(
  () => props.selectedAsset,
  (next, prev) => {
    if (next !== prev) {
      shipment.value = "";
      shipmentOptionList.value = [];
    }
  },
);
</script>

<template>
  <div class="flex grow flex-wrap items-center gap-1.5">
    <FormKit
      v-model="selectedShipment"
      type="dropdown"
      label="Shipment"
      name="waybill_id"
      :classes="{
        label: labelClasses + ' !w-auto',
        outer: 'content-center',
        wrapper: 'flex gap-2',
        selection: 'inline whitespace-nowrap',
        inner: 'grow',
      }"
      :disabled="!selectedAsset"
      :options="shipmentsLoader"
      :option-loader="optionLoader"
      loader-icon="loader"
      always-load-on-open
      empty-message="No shipments found."
      placeholder="- -"
    >
      <template #loadMore></template>
    </FormKit>
    <div class="flex shrink grow items-center justify-between gap-2 text-sm font-semibold">
      {{ selectedShipmentReportingGroups[0] }}
      <PopoverTag
        v-if="selectedShipmentReportingGroups.length > 1"
        name="groups"
        :label="`+ ${selectedShipmentReportingGroups.length - 1}`"
      >
        <ul class="divide-y">
          <template v-for="(group, idx) in selectedShipmentReportingGroups" :key="group">
            <li v-if="idx > 0" class="px-3 py-1.5 text-sm font-semibold">{{ group }}</li>
          </template>
        </ul>
      </PopoverTag>
      <FormKit v-model="hiddenRgs" type="hidden" name="reporting_groups" />
    </div>
  </div>
</template>
