import statusCodes from "@/models/statusCodes";
import { combinedStatuses } from "@/models/statuses";

export interface FleetCycleLegModel {
  status: typeof statusCodes;
  statusLabel: typeof combinedStatuses;
  cycleStartDate: string;
  cycleDuration: number;
}

export default class FleetCycleLeg implements FleetCycleLegModel {
  constructor(
    data: { [key: string]: any },
    public status: typeof statusCodes = data.status,
    public statusLabel: typeof combinedStatuses = data.status_label,
    public cycleStartDate: string = data.start_date,
    public cycleDuration: number = data.duration_days,
  ) {}
}
