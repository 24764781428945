<script setup lang="ts">
import { computed } from "vue";

import AppPopover from "@/components/app/AppPopover.vue";
import OutlineButton from "@/components/common/OutlineButton.vue";
import TgButton from "@/components/common/TgButton.vue";
import TgInput from "@/components/common/TgInput.vue";

const props = defineProps<{
  entity: "draft" | "pattern" | "waybill";
  isLoading: boolean;
  disabled: boolean;
}>();

defineEmits<{ (e: "save"): void }>();

const title = computed(() => {
  switch (props.entity) {
    case "draft":
      return "Draft Name";
    case "pattern":
      return "Pattern Name";
    case "waybill":
      return "Waybill Name";
    default:
      return "Name";
  }
});

const model = defineModel<string>();
</script>

<template>
  <AppPopover :allowed-placements="['right-end']" :disabled="disabled" class="w-full">
    <template #popoverButton>
      <slot />
    </template>
    <template #default="{ close }">
      <div class="ml-2.5 border border-gray-400 bg-white p-3 shadow">
        <div
          class="absolute bottom-3.5 left-[3px] border-y-8 border-r-8 border-y-transparent border-r-gray-400 after:absolute after:-right-2 after:-top-[7px] after:border-y-[7px] after:border-r-[7px] after:border-y-transparent after:border-r-white"
        />
        <p class="mb-2 font-semibold">{{ title }}</p>
        <TgInput v-model="model" name="patternName" class="mb-2 w-96" />
        <div class="flex justify-between">
          <OutlineButton is-condensed color="danger" @click="close">Cancel</OutlineButton>
          <TgButton is-condensed :is-loading="isLoading" @click="$emit('save')">Save</TgButton>
        </div>
      </div>
    </template>
  </AppPopover>
</template>
