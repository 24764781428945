<script setup lang="ts">
import useNotificationStore from "../stores/notifications";
import AppLoader from "./layout/AppLoader.vue";
import AppToast from "./app/AppToast.vue";

const store = useNotificationStore();
</script>

<template>
  <div class="fixed bottom-5 right-7 z-[999] flex w-full max-w-screen-sm flex-col items-end justify-end gap-2">
    <AppToast />
    <AppLoader v-if="store.status === 'loading'" :message="store.loaderMessage" />
  </div>
</template>
