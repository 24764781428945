export function createCsv(headers: string[], rows: any[][]): string {
  const fileHeaders = `${headers.join(",")}\r\n`;
  const fileRows = rows.reduce((str, arr) => {
    str += `${arr.join(",")}\r\n`;
    return str;
  }, "");
  return `data:text/csv;charset=utf-8,${encodeURIComponent(fileHeaders + fileRows)}`;
}

export function downloadCsv(name: string, file: string) {
  const link = window.document.createElement("a");
  link.href = file;
  link.download = name;
  window.document.body.append(link);
  link.click();
  window.document.body.removeChild(link);
  return true;
}
