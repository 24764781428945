<script setup lang="ts"></script>

<template>
  <FormKit
    type="togglebuttons"
    name="state"
    :options="['Unassigned', 'In Progress', 'Closed']"
    multiple
    enforced
    :value="['Unassigned', 'In Progress']"
    :classes="{
      outer: '',
    }"
  />
</template>
