import { ref, watch, computed } from "vue";
import { useUserStore } from "@/stores/user";
import useNotificationStore from "@/stores/notifications";
import { useFetch } from "@/composables/fetch";
import ReportingGroup from "@/models/reportingGroup";

export function useReportingGroups() {
  const reportingGroups = ref<ReportingGroup[]>([]);
  const userStore = useUserStore();
  const notifier = useNotificationStore();
  const request = useFetch();
  const URL = computed(() => `/reports/${userStore.companyId}/groups`);

  watch(
    URL,
    async () => {
      if (!userStore.companyId) {
        return undefined;
      }
      notifier.setLoading("Loading reporting groups");
      const response = await request.get(URL.value);
      notifier.setLoading();

      if (!response.ok) {
        notifier.setToast("danger", "Unable to load reporting groups.");
        console.error("Request for reporting groups failed with ", response.status);
        return undefined;
      }

      const data = await response.json();
      reportingGroups.value = data.data.map((group: unknown) => new ReportingGroup(group));
    },
    { immediate: true },
  );

  return { reportingGroups };
}
