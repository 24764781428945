import { toRef, computed, ref, Ref } from "vue";

const DIRECTIONS = ["ASC", "DESC"] as const;
type Direction = (typeof DIRECTIONS)[number];

export function useSorted<T extends Record<string, any>>(
  list: T[] | Ref<T[]> | (() => T[]),
  startColumn = "",
  startDirection: Direction = "ASC",
) {
  const items = toRef(list);
  const sortDirection = ref<Direction>(startDirection);
  const sortColumn = ref<string | string[]>(startColumn);

  const sorted = computed(() => {
    if (!sortColumn.value) return items.value;
    const newList = [...items.value];
    newList.sort((a, b) => {
      if (Array.isArray(sortColumn.value)) {
        // Compare our preferred key first, then the fallback key.
        const aValue = a[sortColumn.value[0]] || a[sortColumn.value[1]] || null;
        const bValue = b[sortColumn.value[0]] || b[sortColumn.value[1]] || null;
        if (aValue === null && bValue === null) return 1;
        if (aValue !== null) {
          if (bValue === null) return -1;
          return sortDirection.value === "ASC"
            ? ("" + aValue).localeCompare("" + bValue)
            : ("" + bValue).localeCompare("" + aValue);
        }
        if (aValue === null) {
          if (bValue !== null) return 1;
          return 0;
        }
        return 0;
      }

      if (sortColumn.value !== "" && sortColumn.value in a && sortColumn.value in b) {
        if (typeof a[sortColumn.value] === "number" || typeof b[sortColumn.value] === "number") {
          if (isNaN(Number(a[sortColumn.value])) || isNaN(Number(b[sortColumn.value]))) {
            console.warn("Error in sorted: Tried to perform numeric comparison on non-numeric item ", sortColumn.value);
            return 0;
          }

          return sortDirection.value === "ASC"
            ? Number(a[sortColumn.value]) - Number(b[sortColumn.value])
            : Number(b[sortColumn.value]) - Number(a[sortColumn.value]);
        }

        return sortDirection.value === "ASC"
          ? ("" + a[sortColumn.value]).localeCompare("" + b[sortColumn.value])
          : ("" + b[sortColumn.value]).localeCompare("" + a[sortColumn.value]);
      }

      console.warn("Key ", sortColumn.value, " not present in one or more items.");
      return 0;
    });

    return newList;
  });

  return { sorted, sortDirection, sortColumn };
}
