<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import ErrorPage from "./ErrorPage.vue";
export default defineComponent({
  name: "App403Error",
  components: { ErrorPage },
  setup() {
    const router = useRouter();

    function goBack(): void {
      router.go(-1);
    }

    function login(): void {
      router.push({ name: "login" });
    }

    return { goBack, login };
  },
});
</script>
<template>
  <ErrorPage>
    <template #title>Not authorized</template>
    <template #message>
      Your account does not have access to this page. If you think this is a mistake, please try logging in again or
      <a href="mailto:support@telegraph.io">reach out to us at support@telegraph.io!</a>
    </template>
    <template #controls>
      <button class="button is-primary is-rounded" @click="login">Click here to log back in</button>
      <p class="has-text-info is-4">
        <a href="#" @click.prevent="goBack">Nevermind, take me back.</a>
      </p>
    </template>
  </ErrorPage>
</template>
