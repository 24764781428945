import dayjs from "dayjs";
import { Option } from "@/types";
import pricingStatuses from "@/models/pricingStatuses";
import { ProfilePicture, BAPIProfilePicture } from "@/bapi-client/types/users";
import { AttachmentResponse } from "@/bapi-client/types/common/attachment";

export interface QuoteNote {
  id: string;
  quoteId: string;
  note: string;
  timestamp: string;
  userId?: string;
  userName: string;
  userInitials: string;
  isInternal: boolean;
  companyBusinessName?: string;
  attachments: AttachmentResponse[];
  profilePicture: ProfilePicture;
}

export interface ActivityRecord {
  id: string;
  userEmail: string;
  userName: string | null;
  timestamp: string;
  activity: number;
}

interface ActivityRecordResponse {
  id: string;
  user_email: string;
  user_name: string | null;
  activity_timestamp: string;
  activity_code: number;
}

export type QuoteNoteResponse = {
  notes: {
    id: string;
    quote_id: string;
    note: string;
    timestamp: string;
    user_name: string;
    user_initials: string;
    user_id: string;
    customer_id: string;
    company_business_name: string;
    company_name: string;
    is_internal: boolean;
    is_shipper_note: boolean;
    note_author: string;
    attachments?: AttachmentResponse[];
    profile_picture: BAPIProfilePicture;
  }[];
  success: boolean;
};

export interface Shipper {
  id: string;
  name: string;
  customerId: string;
  description: null | string;
}

export interface ShipperResponse {
  id: string;
  name: string;
  customer_id: string;
  description: null | string;
}

export interface StccItem {
  commodity_code: string;
  associated_stcc: string;
  commodity: string;
  is_hazmat: boolean;
}

export interface RailroadCostItem {
  railroad: string;
  origin: string;
  destination: string;
  lineHaul: number;
  fsc: number;
}

export interface OtherCostItem {
  costType: string;
  charge: number;
}

export interface QuoteData {
  shipper_name: string;
  volume: number;
  volume_type: string;
  miles: number;
  rule_11: boolean;
  private_car: boolean;
  system: boolean;
  origin_city: string;
  origin_state: string;
  destination_city: string;
  destination_state: string;
  equipment_type: string;
  equipment_length: string;
  route?: string;
  rate_authority?: string;
  stccs: StccItem[] | [];
  stcc: Option;
  railroad_costs?: RailroadCostItem[];
  other_costs?: OtherCostItem[];
}

export interface QuoteResponse {
  id?: string;
  email: string;
  customer_business_name?: string;
  price?: number;
  fsc_cost?: number;
  effective_date: string;
  expiration_date: string;
  created_at?: string;
  last_modified?: string;
  status?: number;
  rejected_once?: boolean;
  customer_id?: string;
  customer_name?: string;
  data: QuoteData;
  deleted?: boolean;
  quote_url: string;
  short_link?: string;
  other_costs: OtherCostItem[];
  has_notes: boolean;
  // for shipper side
  notes?: { [key: string]: any }[];
  shipper: ShipperResponse;
}

export interface Quote {
  id?: string;
  email: string;
  customerBusinessName?: string;
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  price?: number;
  fscCost?: number;
  effectiveDate: string;
  expirationDate: string;
  createdAt: string;
  lastModified: string;
  statusCode?: number;
  rejectedOnce?: boolean;
  customerId?: string;
  customerName?: string;
  shipper: Shipper;
  shipperName: string;
  deleted?: boolean;
  shortLink?: string;
  quoteUrl?: string;
  otherCosts: OtherCostItem[];
  hasNotes: boolean;
  data: QuoteData;
  statusText: string;
  commodity: string;
  notes?: Record<string, any>[];
}

export function makeQuote(data: QuoteResponse): Quote {
  return {
    id: data.id,
    email: data.email,
    customerBusinessName: data.customer_business_name,
    price: data.price,
    fscCost: data.fsc_cost,
    effectiveDate: dayjs(data.effective_date, "YYYY-MM-DD").toISOString(),
    expirationDate: dayjs(data.expiration_date, "YYYY-MM-DD").toISOString(),
    createdAt: data.created_at ? dayjs(data.created_at).toISOString() : "",
    lastModified: dayjs(data.last_modified).toISOString(),
    statusCode: data.status,
    rejectedOnce: data.rejected_once ?? false,
    customerId: data.customer_id ?? "",
    customerName: data.customer_name ?? "",
    deleted: data.deleted ?? false,
    shortLink: data.short_link ?? "",
    otherCosts: data.other_costs,
    hasNotes: data.has_notes,
    data: data.data,
    statusText: pricingStatuses[data.status ?? 49],
    commodity: `${data.data.stccs[0]?.associated_stcc} - ${data.data.stccs[0]?.commodity}`,
    originCity: data.data.origin_city,
    originState: data.data.origin_state,
    destinationState: data.data.destination_state,
    destinationCity: data.data.destination_city,
    quoteUrl: data.quote_url,
    shipper: {
      id: data.shipper?.id,
      name: data.shipper?.name,
      description: data.shipper?.description,
      customerId: data.shipper?.customer_id,
    },
    shipperName: data.shipper?.name,
  };
}

export function makeActivityRecord(data: ActivityRecordResponse): ActivityRecord {
  return {
    id: data.id,
    userEmail: data.user_email,
    userName: data.user_name,
    timestamp: data.activity_timestamp,
    activity: data.activity_code,
  };
}

export interface QuoteForm {
  shipperId: string;
  email: string;
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  stcc: string;
  rule11: boolean;
  privateCar: boolean;
  system: boolean;
  equipmentType: QuoteEquipment;
  equipmentLength: string;
  miles: number;
  volume: number;
  volumeType: PricingUnitType;
  effectiveDate: Date;
  expirationDate: Date;
}

export const equipmentTypes = [
  "Autorack",
  "Boxcar",
  "Centerbeam",
  "Containers",
  "Covered Hopper",
  "Flatcar",
  "Gondola",
  "High Cubed Boxcar",
  "Insulated Boxcar",
  "Refrigerated Car",
  "Tank",
  "Trailers",
  "Other",
] as const;
export type QuoteEquipment = (typeof equipmentTypes)[number];

export const pricingUnitTypes = ["Per Car", "Ton", "Truck Load", "lbs"] as const;
export type PricingUnitType = (typeof pricingUnitTypes)[number];

export const equipmentLengthTypes = [
  "Any",
  "50'",
  "60'",
  "64'",
  "68'",
  "72'",
  "74'",
  "76'",
  "50'-60'",
  "50'-64'",
  "60'-68'",
] as const;
export type equipmentLengthType = (typeof equipmentLengthTypes)[number];
