export interface API_ETA {
  code: string;
  date: string | null;
  delayed: boolean;
  source_scac: string;
  updated_days_ago: number;
  updated_utc: string;
}

export type ETAResponse = API_ETA | null;

export interface ETA {
  code: string;
  date: string | null;
  delayed: boolean;
  sourceScac: string;
  updatedDaysAgo: number;
  updatedUTC: string;
}

export function makeETA(response: ETAResponse): ETA | null {
  if (!response) {
    return null;
  }

  return {
    code: response.code,
    date: response.date,
    delayed: response.delayed,
    sourceScac: response.source_scac,
    updatedDaysAgo: response.updated_days_ago,
    updatedUTC: response.updated_utc,
  };
}
