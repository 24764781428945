import { HandlerResponse } from "../../types/handlerResponse";
import { BAPI_COMMANDS } from "../../types/commands";
import { fetchHandler } from "@/bapi-client/fetch";
import {
  customerSupportTicketFactory,
  customerSupportTicketNotesFactory,
} from "@/bapi-client/factories/customer-support";
import {
  BAPICreateTicketParams,
  BAPIUpdateTicketParams,
  BAPICreateTicketNoteParams,
  DeleteCustomerSupportTicketParams,
  BAPICustomerSupportTicket,
} from "@/bapi-client/types/customer-support";
import { Result } from "@/bapi-client/types";

export async function createTicket(form: BAPICreateTicketParams): Promise<Result<BAPICustomerSupportTicket>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET,
    "POST",
    `/support/${form.customer_id}`,
    {
      body: form,
    },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: parsed.data.data,
  };
}

export async function deleteTicket({
  customerId,
  ticketId,
}: DeleteCustomerSupportTicketParams): HandlerResponse[typeof BAPI_COMMANDS.CUSTOMER_SUPPORT_DELETE_TICKET] {
  const response = await fetchHandler(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_DELETE_TICKET,
    "DELETE",
    `/support/${customerId}/${ticketId}`,
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return { success: response.success, data: customerSupportTicketFactory(parsed.data.data) };
}

export async function updateTicket(
  form: BAPIUpdateTicketParams & { customer_id: string; ticket_id: string },
): Promise<Result<BAPICustomerSupportTicket>> {
  const { customer_id, ticket_id, ...body } = form;

  const response = await fetchHandler(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_UPDATE_TICKET,
    "PATCH",
    `/support/${form.customer_id}/${form.ticket_id}`,
    {
      body,
    },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return { success: response.success, data: parsed.data.data };
}

export async function getTicket(customerId: string, ticketId: string): Promise<Result<BAPICustomerSupportTicket>> {
  const path = `/support/${customerId}/${ticketId}`;

  const response = await fetchHandler(BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET, "GET", path);
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.data,
  };
}

type TicketList = {
  tickets: BAPICustomerSupportTicket[];
  page: number;
  pageSize: number;
  reportingGroups: Record<string, string>;
  totalRecordCount: number;
};
export async function getTickets(
  customerId: string,
  form?: Record<string, string>,
  sort_by?: string,
  sort_by_direction?: string,
  page?: number,
  page_size?: number,
): Promise<Result<TicketList>> {
  let path = `/support/${customerId}`;
  const filters = new URLSearchParams();
  if (sort_by) filters.append("sort_by", sort_by);
  if (sort_by_direction) filters.append("sort_by_direction", sort_by_direction);
  if (page) filters.append("page", page.toString());
  if (page_size) filters.append("page_size", page_size.toString());
  for (const filter in form) {
    if (form[filter]) filters.append(filter, form[filter]);
  }
  if (filters.toString().length > 0) {
    path += `/?${filters.toString()}`;
  }

  const response = await fetchHandler(BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKETS, "GET", path);
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  const tickets = parsed.data.data;

  const responseObject = {
    tickets,
    page: parsed.data.page,
    pageSize: parsed.data.page_size,
    reportingGroups: parsed.data.report_groups,
    totalRecordCount: parsed.data.total_record_count,
  };

  return {
    success: true,
    data: responseObject,
  };
}

export async function getTicketNotes(
  customerId: string,
  ticketId: string,
): HandlerResponse[typeof BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET_NOTES] {
  const path = `/support/${customerId}/${ticketId}/notes`;

  const response = await fetchHandler(BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET_NOTES, "GET", path);
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: customerSupportTicketNotesFactory(parsed.data.data),
  };
}

export async function createTicketNote(
  form: BAPICreateTicketNoteParams,
): HandlerResponse[typeof BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET_NOTE] {
  const response = await fetchHandler(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET_NOTE,
    "POST",
    `/support/${form.customer_id}/${form.ticket_id}/notes`,
    {
      body: form,
    },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: customerSupportTicketNotesFactory(parsed.data.data),
  };
}

export async function getTicketNoteSignedUploadURL(
  customerId: string,
): HandlerResponse[typeof BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET_NOTE_SIGNED_UPLOAD_URL] {
  const path = `/support/${customerId}/notes/upload`;

  const response = await fetchHandler(BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET_NOTE_SIGNED_UPLOAD_URL, "GET", path);
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: {
      uploadUrl: parsed.data.upload_url,
      attachmentId: parsed.data.attachment_id,
    },
  };
}
