import { fetchHandler } from "@/bapi-client/fetch";
import { Result } from "@/bapi-client/types";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { ReportListItem, ReportBuilderReport } from "@/bapi-client/types/report-builder";

export async function getReportBuilderReportList(companyId: string): Promise<Result<ReportListItem[]>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_LIST,
    "GET",
    `/reports/${companyId}/report`,
  );

  if (!fetchResult.success) {
    return fetchResult;
  }

  const parsed = await fetchResult.data.json();
  return parsed.success ? { success: true, data: parsed.data } : parsed;
}

export async function getReportBuilderReport(
  companyId: string,
  reportId: string,
): Promise<Result<ReportBuilderReport>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_DETAILS,
    "GET",
    `/reports/${companyId}/report/${reportId}/get`,
  );
  if (!fetchResult.success) return fetchResult;
  const parsed = await fetchResult.data.json();
  return parsed.success ? { success: true, data: parsed.data } : parsed;
}

export async function getReportDownload(
  companyId: string,
  reportId: string,
  filetype: "csv" | "pdf" | "xlsx",
): Promise<Result<File>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_DOWNLOAD_REPORT,
    "GET",
    `/reports/${companyId}/report/${reportId}/${filetype}`,
  );
  if (!fetchResult.success) return fetchResult;
  const response = fetchResult.data.raw();
  const blob = await response.blob().catch((e) => {
    return { success: false as const, error: new Error(e) };
  });
  if (!(blob instanceof Blob)) return blob;
  const filename = response.headers.get("Content-Disposition")
    ? `${response.headers.get("Content-Disposition")?.split("filename=")[1]?.replaceAll('"', "") ?? "downloaded-file"}`
    : "downloaded-file";

  return { success: true, data: new File([blob], filename, { type: blob.type }) };
}

export async function deleteReport(companyId: string, reportId: string): Promise<Result<void>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_DELETE_REPORT,
    "DELETE",
    `/reports/${companyId}/report/${reportId}`,
  );
  if (!fetchResult.success) return fetchResult;
  return { success: true, data: undefined };
}
