const quoteStatuses: Record<number, string> = {
  0: "New Request",
  1: "In Progress",
  2: "Submitted",
  3: "Rejected",
  4: "Expired",
  50: "Active",
};

export default quoteStatuses;
