import { toAlphanumeric } from "@/utils/text";
import { AssetModel, AssetModelResponse, makeAsset } from "./asset";

export interface VisibilityAssetResponse extends AssetModelResponse {
  equipment_id: string;
  waybill_id: string;
  load_empty_status: string;
  shipper_name: string;
  consignee_name: string;
  parties: string;
  reporting_scac: string;
  start_date: string | null;
  dest_start_date: string | null;
  last_sighting_event_code_text: string;
  exceptions: string[];
  metadata: null;
  reporting_groups: string[];
  end_date: string;
  eta_delayed: boolean;
  display_eta: boolean;
  equipment_private_system: string;
  equipment_seal_number: string;
  reporting_group_names: string[];
  shipment_number?: string;
  gross_weight?: string;
  net_weight?: string;
  quantity_value?: string;
  quantity_uom?: string;
  cube_count?: string;
  dry_load_indicator?: boolean;
  set_point_bol_number?: string;
  requested_delivery_date?: string;
  requested_loaded_date?: string;
  last_free_day?: string;
  unload_verification_date?: string;
  reefer_off_date?: string;
  primary_sku?: string;
  delivery_note?: string;
  product_description?: string;
  product_number?: string;
  route_code?: string;
  quad_load_number?: string;
}

export interface VisibilityAsset extends AssetModel {
  shipperName: string;
  consigneeName: string;
  parties: string;
  reportingScac: string;
  startDate: string | null;
  destStartDate: string | null;
  endDate: string | null;
  etaDelayed: boolean;
  lastSightingEventCodeText: string;
  metadata: null;
  reportingGroups: string[];
  searchTag: string;
  displayEta: boolean;
  equipmentPrivateSystem: string;
  equipmentSealNumber: string;
  reportingGroupNames: string[];
  shipmentNumber?: string;
  grossWeight?: string;
  netWeight?: string;
  quantityValue?: string;
  quantityUom?: string;
  cubeCount?: string;
  dryLoadIndicator?: boolean;
  setPointBolNumber?: string;
  requestedDeliveryDate?: string;
  requestedLoadedDate?: string;
  lastFreeDay?: string;
  unloadVerificationDate?: string;
  reeferOffDate?: string;
  primarySku?: string;
  deliveryNote?: string;
  productDescription?: string;
  productNumber?: string;
  routeCode?: string;
  quadLoadNumber?: string;
}

export function makeVisibilityAsset(data: VisibilityAssetResponse): VisibilityAsset {
  return {
    ...makeAsset<VisibilityAssetResponse>(data),
    shipperName: data.shipper_name,
    consigneeName: data.consignee_name,
    parties: data.parties,
    reportingGroups: data.reporting_groups,
    reportingScac: data.reporting_scac,
    startDate: data.start_date,
    destStartDate: data.dest_start_date,
    endDate: data.end_date,
    etaDelayed: data.eta_delayed,
    lastSightingEventCodeText: data.last_sighting_event_code_text,
    metadata: data.metadata,
    displayEta: data.display_eta,
    equipmentPrivateSystem: data.equipment_private_system,
    equipmentSealNumber: data.equipment_seal_number,
    reportingGroupNames: data.reporting_group_names,
    shipmentNumber: data.shipment_number,
    grossWeight: data.gross_weight,
    netWeight: data.net_weight,
    quantityValue: data.quantity_value,
    quantityUom: data.quantity_uom,
    cubeCount: data.cube_count,
    dryLoadIndicator: data.dry_load_indicator,
    setPointBolNumber: data.set_point_bol_number,
    requestedLoadedDate: data.requested_loaded_date,
    requestedDeliveryDate: data.requested_delivery_date,
    lastFreeDay: data.last_free_day,
    unloadVerificationDate: data.unload_verification_date,
    reeferOffDate: data.reefer_off_date,
    primarySku: data.primary_sku,
    deliveryNote: data.delivery_note,
    productDescription: data.product_description,
    productNumber: data.product_number,
    routeCode: data.route_code,
    quadLoadNumber: data.quad_load_number,
    get searchTag() {
      return toAlphanumeric(
        `${this.equipmentId} ${this.shipperName}${this.parties}${this.originLocation}${this.destinationLocation}${this.commodityDescription}${this.reportingScac}${this.billOfLadingNumber}${this.sealNumber}${this.status}`,
      );
    },
  };
}
