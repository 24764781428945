import { App, Plugin } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(relativeTime);

const plugin: Plugin = {
  install: (app: App) => {
    app.config.globalProperties.$timeAgo = (date: Date | string, localTime?: boolean) => {
      const parsed = localTime ? dayjs(date).utc(true).local() : dayjs(date);

      if (!parsed.isValid()) {
        return "";
      }

      return parsed.fromNow();
    };
  },
};

export default plugin;
