interface NetworkMapReportModel {
  data: NetworkMapDataPoint[];
}

export interface NetworkMapDataPoint {
  from: {
    coordinates: number[];
  };
  to: {
    coordinates: number[];
  };
}

export default class NetworkMapReport implements NetworkMapReportModel {
  constructor(
    response: any,
    public data: NetworkMapDataPoint[] = response.data.map((point: any) => ({
      from: {
        name: point.id,
        coordinates: [Number(point.olng), Number(point.olat)],
      },
      to: {
        name: point.id,
        coordinates: [Number(point.dlng), Number(point.dlat)],
      },
    })) || [],
  ) {}
}
