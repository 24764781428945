import FleetCycleLeg, { FleetCycleLegModel } from "./fleetCycleLeg";
import * as transportTypeDict from "@/models/transportTypes";
import { combinedStatuses } from "@/models/statuses";

export interface FleetCompleteCycleModel extends FleetCycleLegModel {
  id: string;
  transportType: typeof transportTypeDict;
  loadedEmpty: string;
  origin: string;
  destination: string;
  incompleteData: boolean;
  cycleLegs: FleetCycleLeg[];
}

export default class FleetCompleteCycle implements FleetCompleteCycleModel {
  constructor(
    data: { [key: string]: any },
    public id: string = data.id,
    public status = data.status,
    public statusLabel: typeof combinedStatuses = data.status_label,
    public cycleStartDate: string = data.start_date,
    public cycleDuration: number = data.total_cycle_duration,
    public transportType: typeof transportTypeDict = data.transport_type,
    public loadedEmpty: string = data.load_empty_status,
    public origin: string = data.origin_name,
    public destination: string = data.destination_name,
    public incompleteData: boolean = data.incomplete_data,
    public cycleLegs: FleetCycleLeg[] = data.cycle_legs.map((leg: any) => new FleetCycleLeg(leg)),
  ) {}
}
