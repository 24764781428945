<script setup lang="ts">
import { computed } from "vue";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import { TenderStatusItem } from "@telegraphio/papi-client";
import ListItemCommonData from "@/components/waybilling/ListItemCommonData.vue";
import AppPopover from "@/components/app/AppPopover.vue";
import LayoutBox from "@/components/layout/LayoutBox.vue";
import DotMenuButton from "@/components/common/buttons/DotMenuButton.vue";

const props = defineProps<{ tenderRequest: TenderStatusItem }>();

const trackers = useTrackerStore();

const rsData = {
  status: props.tenderRequest.state,
  pattern_id: props.tenderRequest.patternId,
  tender_request_id: props.tenderRequest.requestId,
};

const isRejected = computed(() => {
  const { state = "" } = props.tenderRequest;
  return state.includes("reject") || state.includes("error");
});
</script>

<template>
  <tr>
    <ListItemCommonData>{{ tenderRequest.state }}</ListItemCommonData>
    <ListItemCommonData>{{ tenderRequest.bolNumber }}</ListItemCommonData>
    <ListItemCommonData>{{ tenderRequest.controlNumber }}</ListItemCommonData>
    <ListItemCommonData>
      {{ tenderRequest.submittedDate ? $date("MMMM DD, YYYY", tenderRequest.submittedDate) : "" }}
    </ListItemCommonData>
    <ListItemCommonData>{{ tenderRequest.equipmentId }}</ListItemCommonData>
    <ListItemCommonData>{{ tenderRequest.shipmentNumber }}</ListItemCommonData>
    <ListItemCommonData>{{ tenderRequest.origin }}</ListItemCommonData>
    <ListItemCommonData>{{ tenderRequest.destination }}</ListItemCommonData>
    <ListItemCommonData v-if="tenderRequest.requestId" class="border-gray w-10 border pl-4">
      <AppPopover>
        <template #popoverButton>
          <DotMenuButton class="m-auto" :is-large="false" />
        </template>
        <template #default>
          <LayoutBox class="mr-2 flex w-44 flex-col items-center">
            <router-link
              v-if="isRejected"
              class="w-full"
              :to="{ name: 'replayTenderRequest', params: { requestId: tenderRequest.requestId } }"
            >
              <button
                class="w-full border-b py-2 text-center font-semibold text-black hover:bg-gray-200"
                @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_LIST_EDIT, rsData)"
              >
                Edit
              </button>
            </router-link>
            <router-link
              v-else
              class="w-full"
              :to="{ name: 'viewTenderRequest', params: { requestId: tenderRequest.requestId } }"
            >
              <button
                class="w-full border-b py-2 text-center font-semibold text-black hover:bg-gray-200"
                @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_LIST_VIEW, rsData)"
              >
                View
              </button>
            </router-link>
          </LayoutBox>
        </template>
      </AppPopover>
    </ListItemCommonData>
  </tr>
</template>
