import { App, Plugin } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const plugin: Plugin = {
  install: (app: App) => {
    app.config.globalProperties.$date = (format: string, date: Date | string, localTime?: boolean) => {
      const parsed = localTime ? dayjs(date).utc(true).local() : dayjs(date);

      if (!parsed.isValid()) {
        return "";
      }

      return parsed.format(format);
    };
  },
};

export default plugin;
