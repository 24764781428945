import { markRaw } from "vue";
import { PiniaPluginContext } from "pinia";
import type { Router } from "vue-router";

declare module "pinia" {
  interface PiniaCustomProperties {
    $router: Router;
  }
}

export function piniaPlugin(router: Router) {
  return (context: PiniaPluginContext) => {
    const { store } = context;
    store.$router = markRaw(router);
    if (import.meta.env.DEV) {
      store._customProperties.add("$router");
    }
  };
}
