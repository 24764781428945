import { ref } from "vue";

export function useIntersection() {
  const observer = ref<IntersectionObserver>();
  const scrollParent = ref<Element | null>(null);

  function watchChildren() {
    if (scrollParent.value !== null) {
      for (const child of Array.from(scrollParent.value.children)) {
        observer.value?.observe(child);
      }
    }
  }

  function unobserve(child: Element) {
    observer.value?.unobserve(child);
  }

  function observe(child: Element) {
    observer.value?.observe(child);
  }

  return { observer, observe, scrollParent, watchChildren, unobserve };
}
