<script setup lang="ts">
import { computed } from "vue";

interface Props {
  label?: string;
  placeholder?: string;
  name: string;
  id?: string;
  type?: string;
  disabled?: boolean;
  errorText?: string;
  helpText?: string;
  min?: number;
  max?: number;
  isValidated?: boolean;
  modelValue?: string | number;
  leadingIcon?: string;
  trailingIcon?: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{ (event: "update:model-value", value: string | number): void }>();

const model = computed({
  get() {
    return props.modelValue ?? "";
  },
  set(value: string | number) {
    emit("update:model-value", value);
  },
});
</script>

<template>
  <div>
    <label v-if="label" :for="id ?? name + '-input'" class="block text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </label>
    <div :class="[label ? 'mt-2' : '']" class="relative rounded-md shadow-sm">
      <div
        v-if="leadingIcon"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4 text-gray-400"
      >
        <i :class="'fa-solid fa-' + leadingIcon" />
      </div>
      <input
        :id="id ?? name + '-input'"
        v-model="model"
        :min="min"
        :max="max"
        :type="type ?? 'string'"
        :name="name"
        :disabled="disabled"
        :class="[
          isValidated ? 'ring-green-atomic-500' : 'ring-gray-300',
          errorText ? ' motion-safe:animate-shake motion-safe:animate-duration-150 ring-red-600' : 'ring-gray-300',
          leadingIcon ? 'pl-10' : 'pl-2',
          trailingIcon ? 'pr-10' : 'pr-2',
        ]"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 transition-colors placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
        :placeholder="placeholder ?? ''"
      />
      <div
        v-if="trailingIcon"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4 text-gray-400"
      >
        <i :class="'fa-solid fa-' + trailingIcon" />
      </div>
    </div>
    <p
      v-if="helpText || errorText"
      :class="[errorText ? 'text-red-600' : 'text-gray-500']"
      class="motion-safe:animate-shake motion-safe:animate-duration-150 mt-2 text-sm"
    >
      {{ errorText || helpText }}
    </p>
  </div>
</template>
