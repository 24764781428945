import { RouteLocationRaw } from "vue-router";

const buttonColor = [
  "primary",
  "primary-dark",
  "primary-light",
  "info",
  "success",
  "success-light",
  "danger",
  "white",
  "black",
  "gray",
] as const;

export type ButtonColor = (typeof buttonColor)[number];

export interface ButtonProps {
  color?: ButtonColor;
  isSmall?: boolean;
  isRounded?: boolean;
  isLoading?: boolean;
  leadingIcon?: string;
  trailingIcon?: string;
  to?: Partial<RouteLocationRaw>;
  disabled?: boolean;
  isCondensed?: boolean;
}

export function colorClasses(color: ButtonColor, hasBorder = true) {
  switch (color) {
    case "white":
      return "bg-white text-gray-900 ring-1 ring-gray-300 hover:bg-gray-200";
    case "info":
      return "bg-blue-sky text-blue hover:bg-blue-sky-600 hover:text-blue-600";
    case "primary-dark":
      return "bg-blue-royal text-white-ice hover:bg-blue-royal-600";
    case "success":
      return "bg-green-teal text-white hover:bg-green-teal-600";
    case "success-light":
      return "bg-green-mint text-green-teal hover:bg-green-mint-600";
    case "danger":
      return hasBorder
        ? "bg-red-500 text-white-ice hover:bg-red-600"
        : "ring-1 ring-transparent hover:ring-red-500 text-red-500 rounded-md active:shadow-inner active:ring-red-600 active:text-red-600";
    case "gray":
      return "bg-gray-300 hover:bg-gray-400 active:bg-gray-400";
    default:
      return "bg-blue text-white hover:bg-blue-600";
  }
}
