<script setup lang="ts">
import { ref } from "vue";
import { BAPICustomerSupportTicket } from "@/bapi-client/types/customer-support";
import AssigneeField from "./AssigneeField.vue";
import { useUserStore } from "@/stores/user";
import { useBapi } from "@/bapi-client";
import useNotificationStore from "@/stores/notifications";
import useTrackerStore from "@/stores/trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";

type Props = {
  ticket: BAPICustomerSupportTicket;
};

const props = defineProps<Props>();
const trackers = useTrackerStore();

const emit = defineEmits<{
  assigned: [];
}>();

const userStore = useUserStore();
const trigger = ref<HTMLButtonElement | null>(null);
const popover = ref<HTMLDivElement | null>(null);
function onToggle(e: ToggleEvent) {
  const body = document.querySelector("body");
  if (body) {
    if (e.newState === "open") {
      body.style.overflow = "hidden";
      if (trigger.value && popover.value) {
        const { right: triggerRight, top: triggerTop } = trigger.value.getBoundingClientRect();
        const { width: popoverWidth, height: popoverHeight } = popover.value.getBoundingClientRect();
        popover.value.style.top = `${triggerTop - popoverHeight - 4}px`;
        popover.value.style.left = `${triggerRight - popoverWidth}px`;
      }
      return;
    }
    body.style.overflow = "auto";
  }
}

async function assignTicket(userId?: string) {
  const assignee = userId ?? userStore.user?.userId;
  if (!assignee) return undefined;
  const rudderStackEvent =
    assignee === userStore.user?.userId
      ? RUDDERSTACK_EVENTS.CUSTOMER_SUPPORT_TICKET_ASSIGN_SELF
      : RUDDERSTACK_EVENTS.CUSTOMER_SUPPORT_TICKET_ASSIGN_OTHER;
  const result = await useBapi("updateTicket", {
    ticket_id: `${props.ticket.id}`,
    customer_id: props.ticket.customer_id,
    assignees: [assignee],
    state: "In Progress",
  });
  if (!result.success) {
    const notifier = useNotificationStore();
    notifier.setToast("danger", "Unable to reassign ticket.");
    trackers.logRudderstackEvent(rudderStackEvent, { success: false, assignee });
    return undefined;
  }
  const notifier = useNotificationStore();
  notifier.setToast("success", "Ticket assigned!");
  popover.value?.hidePopover();
  trackers.logRudderstackEvent(rudderStackEvent, { success: true, assignee });
  emit("assigned");
}
</script>

<template>
  <div class="relative">
    <button
      ref="trigger"
      :popovertarget="`${ticket.id}-details-assign`"
      type="button"
      :class="{
        'bg-blue-500 text-white hover:bg-blue-500 focus-visible:outline-blue-600': !ticket.assignees.length,
        'hover:bg-orang-500/35 focus-visible:outline-orang-500 bg-transparent text-orange-500 ring-1 ring-orange-500':
          ticket.assignees.length,
      }"
      class="h-8 rounded-md px-3 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.CUSTOMER_SUPPORT_TICKET_START_ASSIGN, {})"
    >
      {{ ticket.assignees.length ? "Re-assign Ticket" : "Assign Ticket" }}
    </button>
    <div :id="`${ticket.id}-details-assign`" ref="popover" class="absolute inset-[unset]" popover @toggle="onToggle">
      <AssigneeField :customer-id="ticket.customer_id" @assign="assignTicket" @self-assign="assignTicket" />
    </div>
  </div>
</template>
