import { ref, computed, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";
import { defineStore } from "pinia";
import dayjs from "dayjs";
import useNotificationStore from "./notifications";
import useTrackerStore from "./trackers";
import { useUserStore } from "./user";
import { useFetch } from "@/composables/fetch";
import { NoteAttachment, Option } from "@/types";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import {
  ActivityRecord,
  OtherCostItem,
  Quote,
  QuoteForm,
  QuoteNote,
  QuoteNoteResponse,
  RailroadCostItem,
  makeActivityRecord,
  makeQuote,
} from "@/models/pricing/quotes";

import FileTypes from "@/models/fileTypes";
import { useSorted } from "@/composables/sorted";
import { userProfilePictureFactory } from "@/bapi-client/factories/users";
import type { PricingQuoteGeneralEvent, PricingQuoteCreateInternalNoteEvent } from "@/types/rudderstack";
import { useBapi } from "@/bapi-client";
import { STCC } from "@/types/stccs";
import { sortByStringProperty } from "@/utils/sorters";

interface Filters {
  customer: string;
  origin: string;
  destination: string;
  monthYear: { month: number; year: number } | null;
  statusCode: number | null;
}

const usePricingStore = defineStore("pricing", () => {
  const userStore = useUserStore();
  const notifier = useNotificationStore();
  const trackers = useTrackerStore();

  const allQuotes = ref<Quote[]>([]);
  const allStccCodes = ref<STCC[]>([]);
  const stccOptions = ref<Option[]>([]);
  const stccQuery = ref("");
  const quoteSearch = ref("");
  const quoteHistory = ref<Quote[]>([]);
  const quoteActivity = ref<ActivityRecord[]>([]);
  const selectedQuote = ref<Quote>();
  const selectedQuoteNotes = ref<QuoteNote[]>([]);
  const filters = ref<Filters>({
    customer: "",
    origin: "",
    destination: "",
    monthYear: null,
    statusCode: null,
  });

  const hasFilters = computed(() => Object.values(filters.value).some((val) => val !== null && val !== ""));

  const filteredQuotes = computed(() => {
    // always start with date filter
    const activeFilters = Object.entries(filters.value).filter((entry) => entry[1] !== null && entry[1] !== "");
    if (!activeFilters.length && quoteSearch.value.length < 2) {
      return allQuotes.value;
    }

    const out = [];
    for (const quote of allQuotes.value) {
      const passes = [];

      if (filters.value.monthYear !== null) {
        const created = dayjs(quote.createdAt);
        if (filters.value.monthYear.year === created.year() && filters.value.monthYear.month === created.month()) {
          passes.push(1);
        }
      }

      if (filters.value.customer && filters.value.customer.toUpperCase() === quote.shipper.name.toUpperCase()) {
        passes.push(1);
      }

      if (filters.value.origin && filters.value.origin === `${quote.originCity}, ${quote.originState}`) {
        passes.push(1);
      }

      if (
        filters.value.destination &&
        filters.value.destination === `${quote.destinationCity}, ${quote.destinationState}`
      ) {
        passes.push(1);
      }

      if (filters.value.statusCode !== null && quote.statusCode === filters.value.statusCode) {
        passes.push(1);
      }

      if (passes.length === activeFilters.length) {
        out.push(quote);
      }
    }

    return quoteSearch.value.length > 1
      ? out.filter((quote) => quote.shipper.name.toUpperCase().includes(quoteSearch.value.toUpperCase()))
      : out;
  });

  const debouncedSearchTracker = useDebounceFn(() => {
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTES_SEARCH, {
      search_term: quoteSearch.value,
      results_count: filteredQuotes.value.length,
    });
  }, 400);

  watch(quoteSearch, () => {
    if (quoteSearch.value.length < 2) return undefined;
    debouncedSearchTracker();
  });

  watch(
    filters,
    (newFilters) => {
      const changed = Object.entries(newFilters).filter((entry) => !!entry[1]);
      if (!changed.length) return undefined;
      let trackerFilters = "";
      for (const entry of changed) {
        if (entry[0] === "monthYear" && newFilters.monthYear !== null) {
          trackerFilters += `date,${newFilters.monthYear.month}-${newFilters.monthYear.year}|`;
          continue;
        }
        trackerFilters += `${entry[0]},${entry[1]}|`;
      }
      if (trackerFilters.length > 0) {
        trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTES_FILTER, {
          search_term: quoteSearch.value,
          filter: trackerFilters,
        });
      }
    },
    { deep: true },
  );

  const { sorted, sortColumn, sortDirection } = useSorted(filteredQuotes, "createdAt", "DESC");

  function resetFilters() {
    filters.value = {
      customer: "",
      origin: "",
      destination: "",
      monthYear: null,
      statusCode: filters.value.statusCode !== null ? filters.value.statusCode : null,
    };
  }
  const railroadCostItems = ref<RailroadCostItem[]>([
    {
      railroad: "",
      origin: "",
      destination: "",
      lineHaul: 0,
      fsc: 0,
    },
  ]);

  const otherCostItems = ref<OtherCostItem[]>([
    {
      costType: "",
      charge: 0,
    },
  ]);

  const railroadCostsTotal = computed(() => {
    return railroadCostItems.value.reduce((total, cost) => {
      total += cost.lineHaul || 0;
      return total;
    }, 0);
  });

  const fscTotal = computed(() => {
    return railroadCostItems.value.reduce((total, cost) => {
      total += cost.fsc || 0;
      return total;
    }, 0);
  });

  const otherCostsTotal = computed(() => {
    if (!otherCostItems.value) return 0;
    return otherCostItems.value.reduce((acc, curr) => {
      acc += Number(curr.charge);
      return acc;
    }, 0);
  });

  const validRailroadCostItems = computed(() => railroadCostItems.value.filter((item) => item.lineHaul > 0));
  const validOtherCostItems = computed(() => otherCostItems.value.filter((item) => item.charge > 0));

  function addEmptyRailroadCost() {
    railroadCostItems.value.push({
      railroad: "",
      origin: "",
      destination: "",
      lineHaul: 0,
      fsc: 0,
    });
  }

  function addEmptyOtherCostsRow() {
    return otherCostItems.value.push({
      costType: "",
      charge: 0,
    });
  }

  function removeRailroadCostItem(idx: number) {
    if (idx === undefined || idx === null) {
      console.warn("Tried to remove non-existent cost item at index ", idx);
      return undefined;
    }
    railroadCostItems.value.splice(idx, 1);
  }

  function removeOtherCostRow(idx: number) {
    if (idx === undefined || idx === null) {
      console.warn("Tried to remove non-existent cost item at index ", idx);
      return undefined;
    }
    otherCostItems.value.splice(idx, 1);
  }

  function resetRailroadCosts() {
    railroadCostItems.value = [
      {
        railroad: "",
        origin: "",
        destination: "",
        lineHaul: 0,
        fsc: 0,
      },
    ];
  }

  function resetOtherCosts() {
    otherCostItems.value = [
      {
        costType: "",
        charge: 0,
      },
    ];
  }

  async function getAllQuotes() {
    const userStore = useUserStore();
    const request = useFetch();
    const response = await request.get(`/pricing/${userStore.companyId}/quotes`);
    if (!response.ok) {
      notifier.setToast("danger", "Unable to load quotes", 0);
      console.error(response.status);
      return undefined;
    }
    const data = await response.json();
    const quotes = [];
    for (const quote of data.quotes) {
      if (quote.deleted === false) {
        quotes.push(makeQuote(quote));
      }
    }
    allQuotes.value = quotes;
    return 1;
  }

  async function getQuoteHistory(companyId: string, quoteId: string) {
    notifier.setLoading();
    const request = useFetch();
    const response = await request.get(`/pricing/${companyId}/quotes/${quoteId}/history`);
    if (!response.ok) {
      notifier.setLoading();
      console.error(response.status);
      notifier.setToast("info", "Could not load Activity Feed at this time.");
      return undefined;
    }
    const data = await response.json();
    notifier.setLoading();
    quoteHistory.value = data.quote_history;
    return;
  }

  async function getAllStccCodes() {
    const response = await useBapi("getStccs", { searchTerm: stccQuery.value, context: "pricing" });
    if (!response.success) {
      console.error(response.error);
      return response;
    }
    allStccCodes.value = response.data;
    let stccList = [];
    for (const code of response.data) {
      stccList.push({
        label: code.associatedSTCC + " - " + code.commodity,
        value: code.commodityCode,
        searchTag: code.commodity,
      });
    }
    stccList = sortByStringProperty(stccList, "searchTag", "ASC");
    stccOptions.value = stccList;
  }

  async function createQuote(companyId: string, formData: QuoteForm): Promise<number> {
    const rsData: PricingQuoteGeneralEvent = {
      shipper_id: formData.shipperId,
      success: false,
    };
    const payload: { [key: string]: any } = {
      email: formData.email,
      effective_date: dayjs(formData.effectiveDate).format("YYYY-MM-DD"),
      expiration_date: dayjs(formData.expirationDate).format("YYYY-MM-DD"),
      shipper: { id: formData.shipperId },
      data: {
        volume: formData.volume,
        stcc_ids: [formData.stcc],
        volume_type: formData.volumeType,
        miles: formData.miles,
        origin_city: formData.originCity,
        origin_state: formData.originState,
        destination_city: formData.destinationCity,
        destination_state: formData.destinationState,
        equipment_type: formData.equipmentType,
        equipment_length: formData.equipmentLength,
        rule_11: formData.rule11,
        private_car: formData.privateCar,
        system: formData.system,
      },
    };
    const request = useFetch();
    const response = await request.post(`/pricing/${companyId}/quotes`, {
      body: payload,
    });
    if (!response.ok) {
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_CREATE_QUOTE, rsData);
      console.error(response.status);
      return 0;
    }
    const data = await response.json();
    selectedQuote.value = makeQuote(data.quote);
    await getAllQuotes();
    await getActivity();
    rsData.success = true;
    rsData.quote_id = data.quote.id;
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_CREATE_QUOTE, rsData);
    return data.quote.id;
  }

  async function updateQuoteDetails(companyId: string, formData: QuoteForm) {
    if (!selectedQuote.value) return 0;
    const rsData = {
      shipper_id: formData.shipperId,
      quote_id: selectedQuote.value.id,
      success: false,
    };
    notifier.setLoading();
    const payload: { [key: string]: any } = {
      id: selectedQuote.value.id,
      status: selectedQuote.value.statusCode,
      shipper: { id: formData.shipperId },
      email: formData.email,
      effective_date: dayjs(formData.effectiveDate).format("YYYY-MM-DD"),
      expiration_date: dayjs(formData.expirationDate).format("YYYY-MM-DD"),
      data: {
        ...selectedQuote.value.data,
        volume: formData.volume,
        volume_type: formData.volumeType,
        miles: formData.miles,
        stcc_ids: [formData.stcc],
        origin_city: formData.originCity,
        origin_state: formData.originState,
        destination_city: formData.destinationCity,
        destination_state: formData.destinationState,
        equipment_type: formData.equipmentType,
        equipment_length: formData.equipmentLength,
        rule_11: formData.rule11,
        private_car: formData.privateCar,
        system: formData.system,
      },
    };
    const request = useFetch();
    const response = await request.put(`/pricing/${companyId}/quotes/${selectedQuote.value.id}`, {
      body: payload,
    });
    if (!response.ok) {
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_EDIT_DETAILS, rsData);
      notifier.setLoading();
      notifier.setToast("danger", "Unable to update quote.");
      console.error(response.status);
      return 0;
    }
    const data = await response.json();
    rsData.success = true;
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_EDIT_DETAILS, rsData);
    selectedQuote.value = makeQuote(data.quote);
    notifier.setLoading();
    notifier.setToast("success", "Quote saved");
    await getAllQuotes();
    return data.quote.id;
  }

  async function saveInProgressQuote() {
    if (!selectedQuote.value) return;
    const payload: { [key: string]: any } = {
      ...selectedQuote.value,
      status: 1,
      data: {
        //do need to destructure this for some reason...
        ...selectedQuote.value.data,
        railroad_costs: validRailroadCostItems.value,
        other_costs: validOtherCostItems.value,
      },
    };
    const rsData = {
      quote_id: selectedQuote.value.id,
      shipper_name: selectedQuote.value.shipper.name,
      success: false,
    };
    const request = useFetch();
    const response = await request.put(`/pricing/${userStore.companyId}/quotes/${selectedQuote.value.id}`, {
      body: payload,
    });
    if (!response.ok) {
      rsData.success = false;
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_SAVE_QUOTE, rsData);
      console.error(response.status);
      return undefined;
    }
    const data = await response.json();
    selectedQuote.value = makeQuote(data.quote);
    if (!selectedQuote.value) return;
    await getAllQuotes();
    await getActivity();
    rsData.success = true;
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_SAVE_QUOTE, rsData);
  }

  async function sendQuoteToShipper(total: number, partnerQuoteNote: string, skipEmail: boolean) {
    if (!selectedQuote.value) return 0;
    const payload: { [key: string]: any } = {
      ...selectedQuote.value,
      status: 2,
      price: total,
      skip_email: skipEmail,
      data: {
        ...selectedQuote.value.data,
        railroad_costs: validRailroadCostItems.value,
        other_costs: validOtherCostItems.value,
      },
    };
    const rsData: PricingQuoteGeneralEvent = {
      shipper_name: selectedQuote.value.shipper.name,
      success: false,
    };
    const request = useFetch();
    const response = await request.put(`/pricing/${userStore.companyId}/quotes/${selectedQuote.value.id}`, {
      body: payload,
    });

    if (!response.ok) {
      rsData.success = false;
      selectedQuote.value.id ? (rsData.quote_id = selectedQuote.value.id) : undefined;
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_SEND_QUOTE, rsData);
      notifier.setToast("danger", "Could not send quote");
      console.error(response.status);
      return undefined;
    }
    if (partnerQuoteNote.length) {
      const noteResponse = await saveNote({ text: partnerQuoteNote }, false);
      if (!noteResponse) {
        notifier.setToast("danger", "Quote note not saved");
      }
      await getNotes();
    }
    const data = await response.json();
    if (!selectedQuote.value) return;
    selectedQuote.value = makeQuote(data.quote);
    await getActivity();

    await getAllQuotes();
    rsData.success = true;
    rsData.quote_id = data.quote.id;
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_SEND_QUOTE, rsData);
    notifier.setToast("success", "Quote submitted");
  }

  async function deleteQuote(quoteId: string) {
    const notifier = useNotificationStore();
    notifier.setLoading();
    const request = useFetch();
    const response = await request.del(`/pricing/${userStore.companyId}/quotes/${quoteId}`);
    notifier.setLoading();
    if (!response.ok) {
      notifier.setToast("danger", "Unable to delete quote at this time.");
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_DELETE_QUOTE, {
        success: false,
        quote_id: quoteId,
      });
      console.error(response.status);
      return undefined;
    }
    notifier.setToast("success", "Quote deleted.");
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_DELETE_QUOTE, {
      success: true,
      quote_id: quoteId,
    });
    await getAllQuotes();
  }

  async function copyQuote(quoteId: string): Promise<undefined | string> {
    const request = useFetch();
    const response = await request.post(`/pricing/${userStore.companyId}/quotes/${quoteId}`);
    if (!response.ok) {
      notifier.setLoading();
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_COPY, {
        success: false,
        quote_id: quoteId,
      });
      notifier.setToast("danger", "Could not copy this report");
      console.error(response.status);
      return undefined;
    }
    const data = await response.json();
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_COPY, {
      success: true,
      quote_id: quoteId,
    });
    allQuotes.value.push(makeQuote(data.quote));
    // await getAllQuotes();
    return data.quote.id;
  }

  async function saveNote(note: { text?: string; attachments?: FileList }, isInternal: boolean) {
    const postBody: { note?: string; attachments?: NoteAttachment[]; is_internal: boolean } = {
      is_internal: isInternal,
    };
    const rsData: PricingQuoteCreateInternalNoteEvent = {
      quote_id: selectedQuote.value?.id,
      has_text: false,
      has_attachments: false,
    };
    const request = useFetch();

    if (note.text) {
      postBody.note = note.text;
      rsData.has_text = true;
    }

    if (note.attachments?.length) {
      rsData.has_attachments = true;
      rsData.attachment_count = note.attachments.length;
      postBody.attachments = [];
      // gets a signed S3 URL for each file to upload
      for (let index = 0; index < note.attachments.length; index++) {
        const response = await request.get(`/attachments/${userStore.companyId}/upload`);

        if (!response.ok) {
          console.error("Failed to load upload URL, received ", response.status);
          notifier.setToast("info", "Could not save note.");
          rsData.success = false;
          trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_CREATE_INTERNAL_NOTE, rsData);
          return;
        }
        // attempt to upload files to S3
        const data = await response.json();
        const file = note.attachments[index];
        console.log(file);
        if (file !== null) {
          const uploadResponse = await request.put(data.upload_url, {
            body: file,
            headers: { "Content-Type": file.type },
          });

          if (!uploadResponse.ok) {
            console.error("Failed to upload file, received ", uploadResponse.status);
            notifier.setToast("info", "Could not save note.");
            rsData.success = false;
            trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_CREATE_INTERNAL_NOTE, rsData);
            return;
          }
          // successful upload, update note with data for our database
          postBody.attachments?.push({
            attachment_id: data.attachment_id,
            filename: file.name,
            filesize: file.size,
            alt_text: "uploaded attachment",
            mime_type: file.type,
          });
        }
      }
    }

    const response = await request.post(`/pricing/${userStore.companyId}/quotes/${selectedQuote.value?.id}/notes`, {
      body: postBody,
    });
    if (!response.ok) {
      if (isInternal) {
        trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_CREATE_INTERNAL_NOTE, {
          success: false,
          quote_id: selectedQuote.value?.id,
        });
      }
      return undefined;
    }
    await getNotes();
    if (isInternal) {
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_CREATE_INTERNAL_NOTE, {
        success: true,
        quote_id: selectedQuote.value?.id,
      });
    }
    return response.ok;
  }

  async function deleteNote(noteId: string) {
    const request = useFetch();
    const response = await request.del(
      `/pricing/${userStore.companyId}/quotes/${selectedQuote.value?.id}/notes/${noteId}`,
    );
    if (!response.ok) {
      console.error(response.status);
      return undefined;
    }
    await getNotes();
    return true;
  }

  async function getNotes() {
    const request = useFetch();
    //?is_active=true to get deleted notes
    const response = await request.get(`/pricing/${userStore.companyId}/quotes/${selectedQuote.value?.id}/notes`);
    if (!response.ok) {
      const notifier = useNotificationStore();
      notifier.setToast("danger", "Unable to load Notes at this time.");
      console.error(response.status);
      return undefined;
    }
    const data: QuoteNoteResponse = await response.json();
    const formattedNotes: QuoteNote[] = data.notes.map((note): QuoteNote => {
      if (note.is_shipper_note !== true) {
        return {
          id: note.id,
          quoteId: note.quote_id,
          note: note.note,
          userName: note.user_name,
          userInitials: note.user_initials,
          timestamp: note.timestamp,
          userId: note.user_id,
          isInternal: note.is_internal,
          companyBusinessName: note.company_business_name,
          attachments: note.attachments ?? [],
          profilePicture: userProfilePictureFactory(note.profile_picture),
        };
      }
      return {
        id: note.id,
        quoteId: note.quote_id,
        note: note.note,
        timestamp: note.timestamp,
        userName: selectedQuote.value?.shipper.name || "",
        userInitials: selectedQuote.value?.shipper.name.charAt(0).toUpperCase() || "",
        isInternal: note.is_internal,
        attachments: note.attachments ?? [],
        profilePicture: userProfilePictureFactory(note.profile_picture),
      };
    });
    selectedQuoteNotes.value = formattedNotes;
  }

  async function getActivity() {
    const request = useFetch();
    const response = await request.get(`/pricing/${userStore.companyId}/quotes/${selectedQuote.value?.id}/feed`);
    if (!response.ok) {
      notifier.setToast("danger", "Unable to load Activity Feed at this time.");
      console.error(response.status);
      return undefined;
    }
    const data = await response.json();
    const activities = [];
    for (const activity of data.activity_feed) {
      activities.push(makeActivityRecord(activity));
    }
    quoteActivity.value = activities;
  }

  async function getPricingHistoryReport(type: FileTypes = "csv") {
    notifier.setLoading("Downloading " + type.toUpperCase());
    const companyId = userStore.companyId;
    const request = useFetch();
    const response = await request.get(`/pricing/${companyId}/quotes/${type === "csv" ? "csv" : "xlsx"}`);
    notifier.setLoading();
    if (!response.ok) {
      notifier.setToast("danger", "Could not download report");
      console.error(`Unable to download ${type}:`, response.status || response.message);
      return undefined;
    }
    const data = await response.blob();
    const disposition = response.headers.get("Content-Disposition");
    let fileName = `pricing-history.${type}`;
    if (disposition !== null) {
      const name = disposition.split("=")[1];
      fileName = `${name.replaceAll('"', "")}`;
    }
    const file = new File([data], fileName, { type: data.type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return {
    allQuotes,
    allStccCodes,
    railroadCostItems,
    railroadCostsTotal,
    fscTotal,
    addEmptyRailroadCost,
    removeRailroadCostItem,
    resetRailroadCosts,
    resetOtherCosts,
    createQuote,
    getAllQuotes,
    getAllStccCodes,
    getQuoteHistory,
    getPricingHistoryReport,
    stccQuery,
    stccOptions,
    selectedQuote,
    updateQuoteDetails,
    sendQuoteToShipper,
    saveInProgressQuote,
    quoteHistory,
    otherCostsTotal,
    otherCostItems,
    addEmptyOtherCostsRow,
    removeOtherCostRow,
    deleteQuote,
    copyQuote,
    saveNote,
    deleteNote,
    getNotes,
    getActivity,
    quoteActivity,
    selectedQuoteNotes,
    hasFilters,
    filters,
    filteredQuotes,
    quoteSearch,
    resetFilters,
    sorted,
    sortColumn,
    sortDirection,
  };
});

export default usePricingStore;
