<script setup lang="ts">
import { watch, ref, onMounted } from "vue";
import { useDebounceFn } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { onBeforeRouteUpdate } from "vue-router";
import CustomerSupportHeader from "@/components/customerSupport/CustomerSupportHeader.vue";
import CustomerSupportFilters from "@/components/customerSupport/CustomerSupportFilters.vue";
import TicketTable from "@/components/customerSupport/TicketTable.vue";
import useTrackerStore from "@/stores/trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { useCustomerSupportStore } from "@/stores/customerSupport";

const props = defineProps<{ customerId: string }>();
const trackers = useTrackerStore();
const csStore = useCustomerSupportStore();

const reportingGroups = ref<Record<string, string>>({});
const totalTickets = ref<number>(0);

const pageNumber = useRouteQuery("page_number", "1", { transform: Number });
const pageSize = useRouteQuery("page_size", "25", { transform: Number });
const sortColumn = useRouteQuery("sort_by", "created_ts");
const sortDirection = useRouteQuery("sort_by_direction", "ASC");

async function getDashboardTickets() {
  const response = await csStore.getTickets(
    props.customerId,
    csStore.filterSelections,
    sortColumn.value,
    sortDirection.value,
    pageNumber.value,
    pageSize.value,
  );
  if (response) {
    csStore.tickets = response.tickets;
    reportingGroups.value = response.reportingGroups;
    totalTickets.value = response.totalRecordCount || 0;
  }
  return response;
}

const debouncedFetch = useDebounceFn(async () => {
  await getDashboardTickets();
}, 600);

async function refreshTickets() {
  trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.CUSTOMER_SUPPORT_TICKETS_REFRESH, {});
  await getDashboardTickets();
}

watch([pageNumber, pageSize, sortColumn, sortDirection], () => {
  debouncedFetch();
});

onMounted(async () => {
  await getDashboardTickets();
});

onBeforeRouteUpdate((next, prev) => {
  if (next.name === "customerSupport" && prev.name !== "customerSupport") {
    getDashboardTickets();
  }
});
</script>

<template>
  <section class="p-6 pb-0">
    <CustomerSupportHeader :customer-id="customerId" />
    <CustomerSupportFilters @apply="getDashboardTickets" />
    <TicketTable
      :customer-id="customerId"
      :tickets="csStore.tickets"
      :reporting-groups="reportingGroups"
      :total-tickets="totalTickets"
      class="my-6 rounded-md border"
      @refresh="refreshTickets"
    />
    <router-view />
  </section>
</template>
