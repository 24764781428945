import * as Sentry from "@sentry/vue";
import { ref } from "vue";
import { computed } from "vue";

const OPEN_URLS = ["/shippers", "/user/login", "/user/forgot", "/user/reset"] as const;
const ALLOWED_METHODS = ["GET", "POST", "PUT", "DELETE", "PATCH"] as const;
type AllowedMethod = (typeof ALLOWED_METHODS)[number];

interface RequestOptions {
  headers?: Record<string, string>;
  body?: any | FormData;
}

function checkIfProtectedRoute(path: string): boolean {
  if (path.startsWith("http")) return false;
  return !OPEN_URLS.some((url) => path.startsWith(url));
}

function getFullUrl(path: string) {
  const baseUrl = import.meta.env.VITE_API_URL || "https://prod-b-api.telegraph.io";
  return path.startsWith("http") ? path : baseUrl + path;
}

function makeHeaders(path: string, headers?: Record<string, string>) {
  const headerList = new Headers();
  const isProtected = checkIfProtectedRoute(path);
  // Default headers
  // headerList.append("Cache-Control", "no-store");
  headerList.append("Content-Type", "application/json");

  if (isProtected) {
    headerList.append("Authorization", `Bearer ${localStorage.getItem("authToken")}`);
  }

  // Set and/or override defaults with provided headers.
  if (headers) {
    for (const header in headers) {
      headerList.set(header, headers[header]);
    }
  }

  return headerList;
}

// Keep a keyed, closure-proof map of active abort controllers that isn't blown away every time
// useFetch is called.
const controllers = ref<Map<string, AbortController>>(new Map());

export function useFetch(abortToken?: string) {
  if (abortToken && !controllers.value.has(abortToken)) {
    const ct = new AbortController();
    ct.signal.onabort = () => console.warn(`${abortToken} request aborted.`);
    controllers.value.set(abortToken, ct);
  }

  const aborted = computed(() => {
    if (!abortToken) {
      return false;
    }
    return controllers.value.has(abortToken) && controllers.value.get(abortToken)?.signal.aborted;
  });

  function makeRequest(method: AllowedMethod, path: string, options?: RequestOptions): Request {
    if (abortToken && controllers.value.has(abortToken)) {
      if (controllers.value.get(abortToken)?.signal.aborted) {
        const ct = new AbortController();
        ct.signal.onabort = () => console.warn(`${abortToken} request aborted.`);
        controllers.value.set(abortToken, ct);
      }
    }

    const url = getFullUrl(path);
    const headers = makeHeaders(path, options?.headers);
    const requestInit: RequestInit = {
      headers,
      method,
      // cache: "no-store",
    };

    if (abortToken && controllers.value.has(abortToken)) {
      requestInit.signal = controllers.value.get(abortToken)?.signal;
    }

    if (options?.body) {
      const isJSON = headers.get("content-type")?.includes("application/json");
      requestInit.body = isJSON ? JSON.stringify(options.body) : options.body;
    }

    return new Request(url, requestInit);
  }

  function abort(reason = "Request cancelled while in-flight") {
    if (!abortToken) {
      console.warn("Unable to abort request. useFetch was called without a unique abortToken.");
      return undefined;
    }

    if (!controllers.value.has(abortToken)) {
      console.warn("Attempted to call non-existent AbortController with token ", abortToken);
      return undefined;
    }

    controllers.value.get(abortToken)?.abort(reason);
  }

  async function goFetch(req: Request) {
    return await fetch(req).catch((error) => {
      const E = typeof error === "string" ? new Error(error) : error;
      Sentry.captureException(E, {
        extra: {
          method: req.method,
          url: req.url,
        },
      });
      return E;
    });
  }

  async function get(path: string, options?: RequestOptions) {
    const request = makeRequest("GET", path, options);
    return await goFetch(request);
  }

  async function post(path: string, options?: RequestOptions) {
    const request = makeRequest("POST", path, options);
    return await goFetch(request);
  }

  async function put(path: string, options?: RequestOptions) {
    const request = makeRequest("PUT", path, options);
    return await goFetch(request);
  }

  async function del(path: string, options?: RequestOptions) {
    const request = makeRequest("DELETE", path, options);
    return await goFetch(request);
  }

  async function patch(path: string, options: RequestOptions) {
    const request = makeRequest("PATCH", path, options);
    return await goFetch(request);
  }

  return { aborted, abort, get, put, post, del, patch };
}
