import type { STCC } from "@/types/stccs";

type BAPIStcc = {
  commodity_code: string;
  associated_stcc: string;
  commodity: string;
  is_hazmat: boolean;
};

export function stccFactory(data: BAPIStcc): STCC {
  return {
    commodityCode: data.commodity_code,
    associatedSTCC: data.associated_stcc,
    commodity: data.commodity,
    isHazmat: data.is_hazmat,
  };
}
