import { RouteRecordRaw } from "vue-router";

const LoginPage = () => import("@/pages/LoginPage.vue");
const LoginPageLogin = () => import("@/components/login/LoginPageLogin.vue");
const LoginPageForgotPassword = () => import("@/components/login/LoginPageForgotPassword.vue");
const LoginPageResetPassword = () => import("@/components/login/LoginPageResetPassword.vue");
const LoginPageUnauthorized = () => import("@/components/login/LoginPageUnauthorized.vue");

export const loginRoutes: RouteRecordRaw = {
  name: "loginPage",
  path: "/login",
  component: LoginPage,
  meta: {
    authenticated: false,
  },
  children: [
    {
      name: "login",
      path: "",
      component: LoginPageLogin,
      meta: {
        title: "Telegraph - Login",
      },
      props: true,
    },
    {
      name: "notEnabled",
      path: "disabled",
      component: LoginPageUnauthorized,
      meta: {
        title: "Telegraph - Unauthorized",
      },
    },
    {
      name: "forgotPassword",
      path: "forgot",
      component: LoginPageForgotPassword,
      meta: {
        title: "Telegraph - Forgot Password",
      },
      props: true,
    },
    {
      name: "resetPassword",
      path: "reset",
      component: LoginPageResetPassword,
      meta: {
        title: "Telegraph - Reset Password",
      },
      props: true,
      beforeEnter: (to) => {
        if (!to.query.token) {
          return { name: "login" };
        }
      },
    },
  ],
};
