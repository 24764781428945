<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { TgButton } from "../common";
import useTrackerStore from "@/stores/trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";

interface Props {
  isSaving: boolean;
  isSaved: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isSaved: false,
});

const emits = defineEmits<{ (event: "submitNote", value: { text?: string; attachments?: FileList }): void }>();

const fileInput = ref<HTMLInputElement | null>(null);
const note = ref("");
const noteAttachments = ref<FileList | null>(null);
const attachmentUploadDropContainer = ref<HTMLDivElement | null>(null);

const previewData = computed(() => {
  if (!noteAttachments.value) return [];
  const data = [];
  for (let index = 0; index < noteAttachments.value.length; index++) {
    const attachment = noteAttachments.value[index];
    if (attachment.type.includes("image/") || attachment.type.includes("video/")) {
      data.push({
        id: attachment.lastModified,
        fileName: attachment.name,
        fileIndex: index,
        fileType: attachment.type,
        previewURL: URL.createObjectURL(attachment),
      });
      continue;
    }
    data.push({
      id: attachment.lastModified,
      fileIndex: index,
      fileType: attachment.type,
      fileName: attachment.name,
    });
  }
  return data;
});

const isValidNote = computed(() => (note.value.length || noteAttachments.value?.length ? true : false));
const isMobileBrowser = computed(() => {
  if (/Android|iPhone/i.test(navigator.userAgent)) return true;
  return false;
});

function addAttachmentsToNote(event: Event) {
  if (!event.target) return undefined;
  const target = event.target as HTMLInputElement;
  if (!target.files) return undefined;
  let files: File[];
  noteAttachments.value ? (files = Array.from(noteAttachments.value)) : (files = []);
  const selectedFiles = Array.from(target.files);
  files = files.concat(selectedFiles);

  target.value = "";

  noteAttachments.value = files as unknown as FileList;
}

function deleteAttachmentFromNote(idx: number) {
  if (!noteAttachments.value) return undefined;
  const trackers = useTrackerStore();
  //@ts-expect-error: es2024 JS feature not typed using ESNext
  noteAttachments.value = noteAttachments.value.toSpliced(idx, 1);
  trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.NOTES_ATTACHMENT_DELETE_ONE, {});
}

function emitNote() {
  let payload: { [key: string]: string | FileList } = {};
  if (note.value) {
    payload.text = note.value;
  }
  if (noteAttachments.value?.length) {
    payload.attachments = noteAttachments.value;
  }
  emits("submitNote", payload);
}

function resetFileInput() {
  const trackers = useTrackerStore();
  if (fileInput.value) {
    fileInput.value.value = "";
  }
  noteAttachments.value = null;
  trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.NOTES_ATTACHMENT_DELETE_ALL, {
    attachment_count: fileInput.value?.files?.length,
  });
}

watch(
  () => props.isSaved,
  () => {
    if (props.isSaved === true) {
      // calling this separately so that tracking code isn't called unecessarily.
      if (fileInput.value) {
        fileInput.value.value = "";
      }
      noteAttachments.value = null;
      note.value = "";
    }
  },
);

function handleDrop(ev: DragEvent) {
  if (!ev.dataTransfer) return undefined;
  let files: File[];
  noteAttachments.value ? (files = Array.from(noteAttachments.value)) : (files = []);
  // Get the selected files from the input element
  const selectedFiles = Array.from(ev.dataTransfer.files);

  // Add the selected files to the existing files array
  files = files.concat(selectedFiles);

  noteAttachments.value = files as unknown as FileList;
}

function applyBorderStyle(yes: boolean) {
  if (yes) {
    attachmentUploadDropContainer.value?.setAttribute("data-active", "true");
    return;
  }
  attachmentUploadDropContainer.value?.removeAttribute("data-active");
}
</script>

<template>
  <div data-testid="note-writer-component" class="flex w-full items-start">
    <div class="flex-1">
      <form action="#" class="relative">
        <div
          ref="attachmentUploadDropContainer"
          class="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-blue-600 data-[active]:ring-2 data-[active]:ring-blue-600"
          @drop.prevent="handleDrop"
          @dragover.prevent
          @dragenter="applyBorderStyle(true)"
          @dragexit="applyBorderStyle(false)"
        >
          <label for="user-generated-note" class="sr-only">Add your note</label>
          <!-- The padding applied here is not uniform b/c at least in FF the text in the input is not directly against the padding border on the top-->
          <textarea
            id="user-generated-note"
            v-model="note"
            data-drop-active=""
            rows="3"
            name="user-generated-note-note"
            class="block w-full resize-none bg-transparent px-3 pt-2 text-gray-900 outline-none placeholder:text-gray-400 focus:ring-0 focus-visible:!border-0 sm:text-sm sm:leading-6"
            placeholder="Add a note or add files to attach by dragging/dropping or using the “attach” icon below"
          />
          <div class="flex max-h-72 flex-wrap items-start gap-4 overflow-y-auto p-2">
            <div v-for="(attachment, index) in previewData" :key="index" class="w-full">
              <div
                v-if="attachment.fileType.includes('image/') && !attachment.fileType.includes('image/heic')"
                class="flex"
                @click="deleteAttachmentFromNote(index)"
              >
                <img
                  :id="`uploaded-image-${index}`"
                  class="hover:cursor w-32 transition-opacity hover:opacity-60"
                  :src="attachment.previewURL"
                />
                <span class="z-50 -ml-4 -mt-1 cursor-pointer text-red-600 hover:text-red-900"
                  ><i class="fa fa-solid fa-circle-x rounded-lg bg-white"></i
                ></span>
              </div>
              <div
                v-else-if="attachment.fileType.includes('video/')"
                class="flex"
                @click="deleteAttachmentFromNote(index)"
              >
                <video
                  :id="`uploaded-video-${index}`"
                  class="w-32 transition-opacity hover:cursor-pointer hover:opacity-60"
                  :src="attachment.previewURL"
                />
                <span class="z-50 -ml-4 -mt-1 cursor-pointer text-red-600 hover:text-red-900"
                  ><i class="fa fa-solid fa-circle-x rounded-lg bg-white"></i
                ></span>
              </div>
              <div v-else class="flex" @click="deleteAttachmentFromNote(index)">
                <p :title="attachment.fileName" class="w-72 transition-opacity hover:cursor-pointer hover:opacity-60">
                  <span><i class="fa-solid fa-file fa-md px-2"></i></span>
                  {{ attachment.fileName }}
                </p>
                <span class="z-50 -ml-4 -mt-1 cursor-pointer text-red-600 hover:text-red-900"
                  ><i class="fa fa-solid fa-circle-x rounded-lg bg-white"></i
                ></span>
              </div>
            </div>
          </div>

          <div class="m-3 flex gap-3">
            <div class="mr-2">
              <label for="fileInput">
                <i
                  class="fa-solid fa-paperclip text-gray-400 transition-colors hover:cursor-pointer hover:text-gray-600"
                  aria-hidden="true"
                />
                <span class="sr-only">Attach a file</span>
              </label>
              <!-- all these classes are so that the input is in the DOM but not visible, can't display:none b/c iOS at least doesn't treat the input correctly -->
              <input
                id="fileInput"
                ref="fileInput"
                class="absolute z-[-1] h-1 w-1 overflow-hidden opacity-0"
                name="fileInput"
                type="file"
                accept="*/*"
                multiple
                @change="addAttachmentsToNote"
              />
            </div>
            <div v-if="isMobileBrowser">
              <label for="fileInputCapture">
                <i class="fa-solid fa-camera text-gray-400" aria-hidden="true" />
                <span class="sr-only">Take a photo</span>
              </label>
              <input
                id="fileInputCapture"
                name="fileInputCapture"
                class="absolute z-[-1] h-1 w-1 overflow-hidden opacity-0"
                type="file"
                accept="image/*"
                capture="environment"
                @change="addAttachmentsToNote"
              />
            </div>
            <span
              v-if="noteAttachments?.length"
              title="Delete all attachments"
              class="hover:cursor-pointer"
              @click.prevent="resetFileInput"
              ><i class="fa-solid fa-trash-can text-red-400 transition-colors hover:text-red-600"
            /></span>
          </div>
        </div>
      </form>
      <div class="mt-4 flex flex-row-reverse">
        <TgButton color="primary" :disabled="!isValidNote || isSaving" @click.prevent="emitNote">
          <span v-if="isSaving">
            <i class="fa-solid fa-spinner-third animate-spin" />
          </span>
          <span v-else>
            <i class="fa-solid fa-paper-plane-top" />
          </span>
        </TgButton>
      </div>
    </div>
  </div>
</template>
