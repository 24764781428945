import { ref, computed } from "vue";

export function useBulkSearch() {
  const searchTerms = ref("");
  const previousSearchTermsCount = ref(0);
  const currentSearchTermsCount = computed(() => {
    return searchTerms.value.split("\n").filter((entry: string) => entry.trim() !== "").length;
  });

  function updatePreviousSearchTermCount() {
    previousSearchTermsCount.value = currentSearchTermsCount.value;
  }

  function resetBulkSearch() {
    searchTerms.value = "";
    previousSearchTermsCount.value = 0;
    searchTerms.value = "";
  }
  return {
    searchTerms,
    previousSearchTermsCount,
    currentSearchTermsCount,
    updatePreviousSearchTermCount,
    resetBulkSearch,
  };
}
