import { useRouteQuery } from "@vueuse/router";
import { reactive } from "vue";

export function usePaged(pageNumber = 1, pageSize = 25, sortDirection = "DESC", sortColumn?: string) {
  const page = reactive({
    current: useRouteQuery("page", pageNumber, { transform: Number }),
    size: useRouteQuery("page_size", pageSize),
    total: 0,
  });

  const total = reactive({
    pages: 0,
    records: 0,
  });

  const sort = reactive({
    column: sortColumn,
    direction: sortDirection,
  });

  return { page, total, sort };
}
