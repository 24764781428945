import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { TicketFilterForm } from "@/types/customerSupport";
import { useUserStore } from "@/stores/user";
import { useCSTickets } from "@/composables/customerSupport";

export const useCustomerSupportStore = defineStore("customerSupport", () => {
  const userStore = useUserStore();

  const filterDefaults: TicketFilterForm = {
    state: ["Unassigned", "In Progress"],
    priority: {
      low: true,
      medium: true,
      high: true,
    },
    my_tickets: false,
    asset_ids: "",
    tags: [],
    keyword_search: "",
  };

  const filterForm = ref<TicketFilterForm>({ ...filterDefaults });

  const filterSelections = computed(() => {
    const filters = filterForm.value;

    const selectedPriorities = [];
    for (const priority in filters.priority) {
      if (filters.priority[priority as keyof typeof filters.priority]) {
        selectedPriorities.push(priority);
      }
    }

    const selections: Record<string, string> = {
      state: filters.state.join(","),
      priority: selectedPriorities.join(","),
    };

    if (filters.my_tickets) {
      selections.assignees = userStore.user?.userId ?? "";
    }

    if (filters.tags.length > 0) {
      selections.tags = filters.tags.join(",");
    }

    if (filters.keyword_search.length > 0) {
      selections.keyword_search = filters.keyword_search;
    }

    if (filters.asset_ids.length > 0) {
      selections.equipment_ids = filters.asset_ids.split("\n").join(",").trim();
    }

    return selections;
  });

  const { tickets, getTickets } = useCSTickets();

  return {
    filterDefaults,
    filterForm,
    filterSelections,
    tickets,
    getTickets,
  };
});
