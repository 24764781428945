import { defineStore } from "pinia";
import useTrackerStore from "./trackers";
import { computed, ref } from "vue";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";

interface TableauView {
  url: string;
  report_type: string;
}

export const useAnalyticsStore = defineStore("analytics", () => {
  const views = ref<TableauView[]>([]);
  const token = ref("");
  const selectedView = ref<TableauView | undefined>();

  const isValidReport = computed(() => {
    return selectedView.value !== undefined && token.value !== "";
  });

  function setView(url: string) {
    const view = views.value.find((v) => v.url === url);
    const trackers = useTrackerStore();

    if (!view) {
      console.warn("Invalid URL provided for advanced analytics view.");
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_ADVANCED_ANALYTICS_GENERATE_404, {
        success: false,
        report_url: url,
      });
      selectedView.value = undefined;
      return;
    }
    selectedView.value = view;
  }

  return {
    views,
    selectedView,
    setView,
    isValidReport,
    token,
  };
});
