<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import { usePapiClient } from "@/composables/papiClient";
import { TgButton, OutlineButton } from "@/components/common";
import TgBadge from "@/components//common/TgBadge.vue";
import LayoutBox from "@/components/layout/LayoutBox.vue";
import useNotificationStore from "@/stores/notifications";
import { useUserStore } from "@/stores/user";
import { TenderAcknowledgement, TenderAdvice } from "@telegraphio/papi-client";
import { snakeToUpperCase } from "@/utils/text";

const props = defineProps<{
  companyId: string;
  requestId: string;
}>();

const route = useRoute();
const router = useRouter();
const { papiApi } = usePapiClient();
const notifier = useNotificationStore();
const { user } = useUserStore();
const trackers = useTrackerStore();

const documentContent = ref("");
const acknowledgement = ref<TenderAcknowledgement | undefined>(undefined);
const advice = ref<TenderAdvice | undefined>(undefined);
const status = ref("");
const patternId = ref<string | undefined>(undefined);

const rsData = {
  status: status.value,
  pattern_id: patternId.value,
  tender_request_id: props.requestId,
};

const isLoading = ref(false);

const isViewOnly = computed(() => route.name === "viewTenderRequest");
const isAccepted = computed(() => status.value.includes("accept"));
const isRejected = computed(() => status.value.includes("reject") || status.value.includes("error"));

const statusColor = computed(() => {
  if (isAccepted.value) return "text-green-atomic-500";
  if (isRejected.value) return "text-red-500";
  return "";
});

async function handleSubmit() {
  isLoading.value = true;

  try {
    await papiApi.bookingReplay({
      replayRequest: {
        data: documentContent.value,
        step: "STEP_FILE_DROP",
        search: {
          column: "request_id",
          searchValue: props.requestId,
        },
      },
    });

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_EDIT_RESUBMIT, rsData);

    notifier.setToast("success", "Replay request submitted");
    router.push({ name: "waybillsList" });
  } catch (error) {
    console.error(error);
    notifier.setToast("danger", "Failed to submit replay request");
  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  const requestBody = {
    customerId: props.companyId,
    requestId: props.requestId,
  };

  try {
    const [bookingRequest, { data = "" }] = await Promise.all([
      papiApi.bookingGetBookingRequestStatus(requestBody),
      papiApi.bookingGetLatestTender(requestBody),
    ]);

    advice.value = bookingRequest.advice;
    acknowledgement.value = bookingRequest.acknowledgement;
    status.value = bookingRequest.state ?? "";
    patternId.value = bookingRequest.patternId;
    documentContent.value = data.trim();
  } catch (error) {
    console.error(error);
    notifier.setToast("danger", "Error fetching tender request");
  }
});
</script>

<template>
  <div class="flex flex-col">
    <p class="mb-6 text-lg font-semibold">
      Status: <span :class="statusColor">{{ snakeToUpperCase(status) }}</span>
    </p>
    <TgBadge
      v-if="acknowledgement && acknowledgement.message"
      :status="isRejected ? 'failure' : 'success'"
      class="mb-3"
    >
      <div class="text-base">
        <p><span class="font-bold">Code:</span> {{ acknowledgement.code }}</p>
        <p><span class="font-bold">Message:</span> {{ acknowledgement.message }}</p>
      </div>
    </TgBadge>
    <TgBadge v-if="advice && advice.message" :status="isRejected ? 'failure' : 'success'" class="mb-3">
      <div class="text-base">
        <p v-if="isRejected" class="font-bold">Tender Request Rejected</p>
        <p><span class="font-bold">Code:</span> {{ advice.code }}</p>
        <p><span class="font-bold">Message:</span> {{ advice.message }}</p>
      </div>
    </TgBadge>
    <div v-if="isViewOnly" class="flex h-full flex-col">
      <LayoutBox class="mb-6 h-full p-3">
        <pre>{{ documentContent }}</pre>
      </LayoutBox>
      <router-link v-if="isViewOnly" :to="{ name: 'waybillsList' }" class="mb-3">
        <TgButton
          @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_VIEW_DONE, rsData)"
        >
          Done
        </TgButton>
      </router-link>
    </div>
    <div v-else class="flex h-full flex-col">
      <FormKit
        v-model="documentContent"
        type="textarea"
        :classes="{ outer: 'mb-6', wrapper: 'h-full', inner: 'bg-white !rounded-md h-full', input: 'h-full' }"
      />
      <div class="flex">
        <router-link :to="{ name: 'waybillsList' }">
          <OutlineButton
            class="mr-3"
            color="danger"
            @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_EDIT_CANCEL, rsData)"
          >
            Cancel
          </OutlineButton>
        </router-link>
        <TgButton :is-loading="isLoading" :disabled="!user?.isAdmin || !documentContent" @click="handleSubmit">
          Submit
        </TgButton>
      </div>
    </div>
  </div>
</template>
