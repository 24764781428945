import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { v4 as uuid } from "uuid";
import App500Error from "../components/errors/App500Error.vue";
import App401Error from "../components/errors/App401Error.vue";
import App403Error from "../components/errors/App403Error.vue";
import { PlatformAlert } from "@/types";
import { useLDFlag } from "launchdarkly-vue-client-sdk";
import dayjs from "dayjs";
import useTrackerStore from "./trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";

type notificationType = "info" | "success" | "danger" | "loading";

type Toast = {
  status: notificationType;
  icon: string;
  message: string;
  id: string;
  noTimeout: boolean;
};

const useNotificationStore = defineStore("notifications", () => {
  const trackers = useTrackerStore();
  const status = ref<"ready" | "loading" | "fault">("ready");
  const lastResponseCode = ref(0);
  const toastList = ref<Toast[]>([]);
  const loaderMessage = ref("");
  const showUpdateBanner = ref(false);
  const activeVersion = ref(import.meta.env.VITE_APP_VERSION);
  const platformAlertFlag = useLDFlag<PlatformAlert | undefined>("platform-alert");
  const storedPlatformAlertMetadata = ref<string | null>(localStorage.getItem("platformAlertInfo"));
  const platformAlertLinkClicked = ref(false);
  const isPlatformAlertVisible = computed(() => {
    if (!platformAlertFlag.value) {
      localStorage.removeItem("platformAlertInfo");
      return false;
    }
    if (platformAlertFlag.value.active === true) {
      // the value exists in local storage but it's empty or malformed in some way, or null even (doesn't exist)
      if (storedPlatformAlertMetadata.value === "" || !storedPlatformAlertMetadata.value?.includes("oldVersion")) {
        trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_EVENT_PLATFORM_ALERT_VIEW_NEW, {
          title: platformAlertFlag.value?.title ?? "",
          version: platformAlertFlag.value?.version ?? 0,
        });
        // set local storage value w/out the dismissedAt key to track the first view later on
        localStorage.setItem("platformAlertInfo", JSON.stringify({ oldVersion: platformAlertFlag.value.version }));
        return true;
      }
      if (!storedPlatformAlertMetadata.value.includes("dismissedAt")) return true;

      if (platformAlertLinkClicked.value === true) {
        trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_EVENT_PLATFORM_ALERT_OPEN, {
          title: platformAlertFlag.value?.title ?? "",
          version: platformAlertFlag.value?.version ?? 0,
        });
        return true;
      }
      const savedAlertInfo: { dismissedAt?: string; oldVersion: number; updateVersionViewed?: number } = JSON.parse(
        storedPlatformAlertMetadata.value,
      );

      if (platformAlertFlag.value.version !== savedAlertInfo.oldVersion) {
        // this condiditonal ensures that tracking is only fired for the first instance that the alert is updated
        if (platformAlertFlag.value.version !== savedAlertInfo.updateVersionViewed) {
          trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_EVENT_PLATFORM_ALERT_VIEW_UPDATE, {
            title: platformAlertFlag.value?.title ?? "",
            version: platformAlertFlag.value?.version ?? 0,
          });
          localStorage.setItem(
            "platformAlertInfo",
            JSON.stringify({
              dismissedAt: savedAlertInfo.dismissedAt,
              oldVersion: savedAlertInfo.oldVersion,
              updateVersionViewed: platformAlertFlag.value.version,
            }),
          );
        }
        return true;
      }
    }
    return false;
  });

  const statusPage = computed(() => {
    if (status.value !== "fault") return null;
    switch (lastResponseCode.value) {
      case 401:
        return App401Error;
      case 403:
        return App403Error;
      case 500:
        return App500Error;
      default:
        return App500Error;
    }
  });

  function dismissPlatformAlert() {
    const alertInfo = JSON.stringify({
      dismissedAt: dayjs().toISOString(),
      oldVersion: platformAlertFlag.value?.version,
      updateVersionViewed: platformAlertFlag.value?.version,
    });
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_EVENT_PLATFORM_ALERT_DISMISS, {
      title: platformAlertFlag.value?.title ?? "",
      dismissed_at: dayjs().toISOString(),
      version: platformAlertFlag.value?.version ?? 0,
    });
    localStorage.setItem("platformAlertInfo", alertInfo);
    platformAlertLinkClicked.value = false;
    storedPlatformAlertMetadata.value = alertInfo;
  }

  async function getLatestAppVersion(): Promise<string> {
    const response = await fetch("/version.txt", { cache: "no-store" }).catch((error) => {
      console.error(error);
      return;
    });
    if (!response?.ok) {
      console.error("HTTP Error: ", response?.status);
      return "";
    }
    return await response.text();
  }

  async function checkForNewerVersion(): Promise<void> {
    const currentVersion = await getLatestAppVersion();
    if (activeVersion.value < currentVersion && currentVersion.length) {
      showUpdateBanner.value = true;
      return;
    }
  }

  function setLoading(msg: string = "Loading...") {
    if (status.value === "fault") return;
    if (status.value === "loading") {
      status.value = "ready";
      loaderMessage.value = msg;
      return;
    }
    loaderMessage.value = msg;
    status.value = "loading";
  }

  function setToast(status: notificationType, message = "Something went wrong.", timeout = 5000) {
    const iconMap: { [key: string]: string } = {
      info: "circle-info",
      success: "circle-check",
      error: "circle-exclamation",
    };

    const id = uuid();
    toastList.value.push({
      id,
      status,
      icon: iconMap[status] || "circle-info",
      message,
      noTimeout: timeout === 0 ? true : false,
    });

    if (timeout) {
      setTimeout(() => clearToast(id), timeout);
    }

    return id;
  }
  function clearToast(id: string) {
    const idx = toastList.value.findIndex((t) => t.id === id);
    if (idx >= 0) {
      const newList = [...toastList.value];
      newList.splice(idx, 1);
      toastList.value = newList;
    }
  }

  return {
    status,
    lastResponseCode,
    toastList,
    loaderMessage,
    showUpdateBanner,
    activeVersion,
    statusPage,
    getLatestAppVersion,
    checkForNewerVersion,
    setLoading,
    setToast,
    clearToast,
    platformAlertFlag,
    isPlatformAlertVisible,
    storedPlatformAlertMetadata,
    dismissPlatformAlert,
    platformAlertLinkClicked,
  };
});

export default useNotificationStore;
