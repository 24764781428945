<script setup lang="ts">
import { computed } from "vue";

interface Props {
  icon: string; // solid,house,3x
  size?: string;
}

const props = defineProps<Props>();

const iconClass = computed(() => {
  return props.icon
    .split(",")
    .map((sub: string) => "fa-" + sub)
    .join(" ");
});

const sizeClass = computed(() => {
  if (!props.size || props.size === "base") {
    return "text-base";
  }

  return {
    sm: "text-sm",
    lg: "text-lg",
    xl: "text-xl",
  }[props.size];
});
</script>

<template>
  <span :class="[sizeClass]" class="inline-flex items-center justify-center first:mr-4 last:ml-4 only:mx-0">
    <i :class="[iconClass]" />
  </span>
</template>
