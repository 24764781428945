export function formatEDILabel(label?: string): string {
  if (!label) {
    return "";
  }

  // strip off loop suffix if it exists
  if (label.endsWith("_loop")) {
    label = label.slice(0, -5);
  }

  const segments = label.split("_");
  // Pop off segment code suffix
  segments.pop();

  // Capitalize each word and join with spaces
  return segments.map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(" ");
}
