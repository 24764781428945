import { WaybillNote } from "@/bapi-client/types/waybills";
import { AssetModel, AssetModelResponse, makeAsset } from "./asset";

export interface PipelineAssetResponse extends AssetModelResponse {
  train_id: string;
  display_eta: boolean;
  last_notify_date: string;
  current_carrier: string;
  reporting_groups: string[];
  location: {
    id: string;
    display_name: string;
    station: string;
    splc: string;
    state: string;
    scac: string;
    time_zone: string;
    latitude: number;
    longitude: number;
  };
  note: WaybillNote | null;
}

export interface PipelineAsset extends AssetModel {
  trainId: string;
  displayEta: boolean;
  lastNotifyDate: string;
  lastSightingDate: string;
  currentCarrier: string;
  location: {
    id: string;
    displayName: string;
    station: string;
    splc: string;
    state: string;
    timeZone: string;
    scac: string;
    latitude: number;
    longitude: number;
  };
  note?: WaybillNote;
  reportingGroups: string[];
}

export function makePipelineAsset(data: PipelineAssetResponse): PipelineAsset {
  const asset: PipelineAsset = {
    ...makeAsset<PipelineAssetResponse>(data),
    trainId: data.train_id,
    displayEta: data.display_eta,
    currentCarrier: data.current_carrier,
    lastNotifyDate: data.last_notify_date,
    location: {
      id: data.location.id,
      displayName: data.location.display_name,
      station: data.location.station,
      state: data.location.state,
      splc: data.location.splc,
      scac: data.location.scac,
      timeZone: data.location.time_zone,
      latitude: data.location.latitude,
      longitude: data.location.longitude,
    },
    reportingGroups: data.reporting_groups,
    exceptions: data.exceptions,
  };

  if (data.note !== null) {
    asset.note = data.note;
  }

  return asset;
}
