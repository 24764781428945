export class BAPIError extends Error {
  public readonly status?: number;

  constructor(msg: string, status?: number, cause?: Error) {
    super(msg, { cause });

    if (status) {
      this.status = status;
    }
  }
}
