interface ForecastEquipmentModel {
  assetId: string;
  waybillId: number;
  currentLocation: string;
}

export default class ForecastReportEquipment implements ForecastEquipmentModel {
  constructor(
    equipment: any,
    public assetId: string = equipment.equipment_label,
    public currentLocation: string = equipment.current_location,
    public waybillId: number = equipment.waybill_id,
  ) {}
}
