<script setup lang="ts">
import { ref } from "vue";
import { AvatarSize, UserAvatar, InitialsAvatar, PlaceholderAvatar } from "./avatars";

type Props = {
  url: string;
  size?: AvatarSize;
  initials?: string;
  anon?: boolean;
};

withDefaults(defineProps<Props>(), { size: "3", url: "", firstName: "", lastName: "", initials: "" });
const showFallback = ref(false);
</script>

<template>
  <div class="m-0.5">
    <PlaceholderAvatar v-if="anon" :size="size" />
    <UserAvatar v-if="!anon && url && !showFallback" :size="size" :url="url" @error="showFallback = true" />
    <template v-if="showFallback || (!anon && !url)">
      <PlaceholderAvatar v-if="!initials" :size="size" />
      <InitialsAvatar v-else :size="size" :initials="initials" />
    </template>
  </div>
</template>
