<script setup lang="ts">
import { ref, watch, onMounted } from "vue";

interface Props {
  isActive?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{ (event: "dismiss"): void }>();

const dialog = ref<HTMLDialogElement | null>(null);

function openModal() {
  dialog.value?.showModal();
}

function closeModal() {
  dialog.value?.close();
  emit("dismiss");
}

watch(
  () => props.isActive,
  (next) => {
    next === true ? openModal() : closeModal();
  },
);

onMounted(() => {
  if (props.isActive) {
    openModal();
  }
  dialog.value?.addEventListener("click", () => {
    if (props.isActive === undefined) {
      return dialog.value?.close();
    }
    emit("dismiss");
  });
  dialog.value?.addEventListener("close", () => {
    emit("dismiss");
  });
});
</script>

<template>
  <div>
    <slot name="trigger" :open="openModal" />
    <dialog
      ref="dialog"
      class="h-screen max-h-screen w-screen flex-col items-center overflow-auto bg-transparent pt-48 backdrop:bg-gray-900/80 open:flex open:animate-fadein"
    >
      <div class="fixed right-0 top-0 hidden pr-4 pt-4 sm:block">
        <button
          data-testid="modal-close"
          class="focus:ring-indigo-500 flex h-10 w-10 flex-col items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
          aria-label="close"
          @click.stop="closeModal"
        >
          <span class="sr-only">Close</span>
          <i class="fa-solid fa-xmark" aria-hidden="true" />
        </button>
      </div>
      <form method="dialog" data-testid="modal-content" @click.stop>
        <slot :close="closeModal" />
      </form>
    </dialog>
  </div>
</template>
