<script setup lang="ts">
import { useRouteQuery } from "@vueuse/router";
// import dayjs from "dayjs";
import { TgTable, TgPagination } from "@/components/common";
import type { TableColumn } from "@/types";
import { BAPICustomerSupportTicket } from "@/bapi-client/types/customer-support";
import TicketTableRow from "./TicketTableRow.vue";
import { computed } from "vue";

type Props = {
  customerId: string;
  tickets: BAPICustomerSupportTicket[];
  reportingGroups: Record<string, string>;
  totalTickets: number;
};

const props = defineProps<Props>();
defineEmits(["refresh"]);

const sortColumn = useRouteQuery<string>("sort_by", "created_ts");
const sortDirection = useRouteQuery<"ASC" | "DESC">("sort_by_direction", "ASC");
const pageSize = useRouteQuery("page_size", "25", { transform: Number });
const pageNumber = useRouteQuery("page_number", "1", { transform: Number });
const totalPages = computed(() => (props.totalTickets === 0 ? 0 : Math.ceil(props.totalTickets / pageSize.value)));

const columns: TableColumn[] = [
  { label: "Priority", value: "priority" },
  { label: "Ticket", value: "id" },
  { label: "Ticket Name", value: "name" },
  { label: "Reporting Group", value: "reporting_groups" },
  { label: "Asset(s)", value: "asset_ids" },
  { label: "RR", value: "railroads" },
  { label: "L/E", value: "loaded_empty" },
  { label: "Tag(s)", value: "tags" },
  { label: "Created", value: "created_ts" },
  { label: "Last Action", value: "updated_ts" },
  { label: "Status", value: "state" },
  { label: "Assignee", value: "assignees" },
];

function setSort(column: string) {
  if (column === sortColumn.value) {
    sortColumn.value = column;
    sortDirection.value = sortDirection.value === "ASC" ? "DESC" : "ASC";
    return sortDirection.value;
  }
  sortColumn.value = column;
  sortDirection.value = "ASC";
}
</script>

<template>
  <section class="rounded-md border">
    <TgPagination
      v-model:page-size="pageSize"
      v-model:current-page="pageNumber"
      :total="totalTickets"
      :last-page="totalPages"
      with-totals
      with-refresh
      with-page-size
      class="rounded-tl-md rounded-tr-md bg-white py-6 pl-10 pr-6"
      @refresh-table="$emit('refresh')"
    />
    <TgTable
      class="overflow-x-auto"
      :columns="columns"
      :sort-column="sortColumn"
      :sort-direction="sortDirection"
      @sort="setSort($event as string)"
    >
      <TicketTableRow
        v-for="ticket in tickets"
        :key="ticket.id"
        :ticket="ticket"
        :customer-id="customerId"
        :reporting-groups="ticket.reporting_groups && ticket.reporting_groups.map((group) => reportingGroups[group])"
      />
    </TgTable>
  </section>
</template>
