<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import useNotificationStore from "@/stores/notifications";
import ErrorPage from "./ErrorPage.vue";
export default defineComponent({
  name: "App401Error",
  components: { ErrorPage },
  setup() {
    const router = useRouter();

    function goBack(): void {
      const notifier = useNotificationStore();
      notifier.status = "ready";
      router.push({ name: "login" });
    }

    onMounted(() => {
      setTimeout(() => {
        const notifier = useNotificationStore();
        notifier.status = "ready";
        router.push({ name: "login" });
      }, 10000);
    });

    return { goBack };
  },
});
</script>
<template>
  <ErrorPage>
    <template #title>Login required</template>
    <template #message>
      You need to log in to access this page. If you think you've reached this page in error, let us know at
      <a href="mailto:support@telegraph.io" class="underline"> support@telegraph.io</a>!
    </template>
    <template #controls>
      <button class="button is-primary is-rounded" @click="goBack">Click here to log in</button>
    </template>
  </ErrorPage>
</template>
