import { ref, computed } from "vue";
import { APICustomerUser, CustomerUser, makeCustomerUser } from "@/models/internal/users";
import { defineStore } from "pinia";
import useNotificationStore from "@/stores/notifications";
import useTrackerStore from "../trackers";
import { useFetch } from "@/composables/fetch";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { APICustomer, Customer, makeCustomer } from "@/models/internal/customers";
import type { AdminUserEvent } from "@/types/rudderstack";

export const useAdminUserStore = defineStore("adminUsers", () => {
  const notifier = useNotificationStore();
  const trackers = useTrackerStore();
  const allUsers = ref<CustomerUser[]>([]);
  const selectedUserId = ref("");
  const userRoles = ref<{ name: string; id: string }[]>([]);
  const selectedUserRole = ref("");
  const allCustomers = ref<Customer[]>([]);
  const userForm = ref({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumberWork: "",
    phoneNumberMobile: "",
    profilePicture: {},
  });

  const selectedUser = computed(() => allUsers.value.find((user) => user.userId === selectedUserId.value));

  function updateLocalUsers(newUser: CustomerUser) {
    const newUsers = [...allUsers.value];
    const currentUserIndex = allUsers.value.findIndex((user) => user.userId === newUser.userId);

    if (currentUserIndex >= 0) {
      newUsers.splice(currentUserIndex, 1, newUser);
    }

    if (currentUserIndex < 0) {
      newUsers.push(newUser);
    }

    allUsers.value = newUsers;
  }

  async function getAllCustomers() {
    notifier.setLoading("Loading customers");
    const request = useFetch();
    const response = await request.get("/admin/customers");
    notifier.setLoading();

    if (!response.ok) {
      console.error("Unable to load customers.");
      notifier.setToast("danger", "Unable to load customers.");
      return undefined;
    }

    const data: { success: boolean; customers: APICustomer[] } = await response.json();
    allCustomers.value = data.customers.map(makeCustomer);
  }

  async function getUserRoles() {
    if (userRoles.value.length) {
      return undefined;
    }
    notifier.setLoading("Loading user roles");
    const request = useFetch();
    const response = await request.get("/admin/users/roles");
    notifier.setLoading();

    if (!response.ok) {
      notifier.setToast("danger", "Unable to load user roles.");
      return undefined;
    }

    const data: {
      success: boolean;
      roles: { id: number; name: string; description: string }[];
    } = await response.json();
    const roles = data.roles.map((role) => ({
      id: "" + role.id,
      name: role.name,
    }));
    userRoles.value = roles;
  }

  async function getAllUsers() {
    notifier.setLoading("Loading users");
    const request = useFetch();
    const response = await request.get("/admin/users");
    notifier.setLoading();

    if (!response.ok) {
      notifier.setToast("danger", "Unable to load users.");
      return undefined;
    }

    const data: { users: APICustomerUser[]; success: boolean } = await response.json();
    const users = data.users.map((user) => makeCustomerUser(user));
    allUsers.value = users;
  }

  async function getUser(userId?: string) {
    if (!selectedUserId.value || !userId) {
      return undefined;
    }

    notifier.setLoading("Loading user");
    const request = useFetch();
    const response = userId
      ? await request.get("/admin/users/" + userId)
      : await request.get("/admin/users/" + selectedUserId.value);
    notifier.setLoading();

    if (!response.ok) {
      console.error("Unable to load selected user: ", response.status);
      notifier.setToast("danger", "Unable to load selected user.");
      return undefined;
    }

    const data: APICustomerUser = await response.json();

    // If we are loading a user we already have in state, use the latest version
    const user = makeCustomerUser(data);
    updateLocalUsers(user);
    if (userId) {
      return user;
    }
  }

  async function saveUser() {
    notifier.setLoading("Saving user");
    const rsEvent = selectedUserId.value ? RUDDERSTACK_EVENTS.ADMIN_USER_EDIT : RUDDERSTACK_EVENTS.ADMIN_USER_CREATE;
    const rsData: AdminUserEvent = {
      first_name: userForm.value.firstName,
      last_name: userForm.value.lastName,
      email: userForm.value.email,
      success: false,
    };

    const request = useFetch();
    const method = selectedUserId.value ? "PUT" : "POST";
    const URL = selectedUserId.value ? `/admin/users/${selectedUserId.value}` : "/admin/users";
    const body = {
      first_name: userForm.value.firstName,
      last_name: userForm.value.lastName,
      email: userForm.value.email,
      phone_number_work: userForm.value.phoneNumberWork,
      phone_number_mobile: userForm.value.phoneNumberMobile,
      profile_picture_attachment: userForm.value.profilePicture,
    };

    const response = method === "PUT" ? await request.put(URL, { body }) : await request.post(URL, { body });
    notifier.setLoading();

    if (!response.ok) {
      if (response.status === 409) {
        notifier.setToast("danger", "A user with this email address already exists.");
      }

      if (response.status === 400) {
        notifier.setToast("danger", "Required fields missing or invalid.");
      }

      rsData.success = false;
      trackers.logRudderstackEvent(rsEvent, rsData);
      return undefined;
    }

    rsData.success = true;
    trackers.logRudderstackEvent(rsEvent, rsData);
    notifier.setToast("success", "User saved!");
    const data: APICustomerUser = await response.json();
    const newUser = makeCustomerUser(data);
    updateLocalUsers(newUser);
  }

  async function addCustomerAssociation(customerId: string, roleId: string) {
    if (!selectedUser.value) {
      return undefined;
    }

    const rsData = {
      customer_user_id: selectedUserId.value,
      email: selectedUser.value.email,
      role_id: selectedUserRole.value,
    };

    notifier.setLoading("Saving user");
    const request = useFetch();
    const response = await request.put(`/admin/users/${selectedUser.value.userId}/customers/${customerId}`, {
      body: { role_id: roleId },
    });

    notifier.setLoading();

    if (!response.ok) {
      notifier.setToast("danger", "Unable to save customer association.");
      return Promise.reject(new Error("Unable to save user."));
    }

    const data: APICustomerUser = await response.json();
    const newUser = makeCustomerUser(data);
    updateLocalUsers(newUser);
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ADMIN_USER_CUSTOMER_ADD, rsData);
  }

  async function removeCustomerAssociation(customerId: string) {
    if (!selectedUser.value) {
      return undefined;
    }

    notifier.setLoading("Removing customer association");
    const rsData = {
      customer_user_id: selectedUser.value.userId,
      email: selectedUser.value.email,
    };

    const request = useFetch();
    const response = await request.del(`/admin/users/${selectedUser.value.userId}/customers/${customerId}`);

    notifier.setLoading();
    if (!response.ok) {
      notifier.setToast("danger", "Unable to remove customer association at this time.");
      return undefined;
    }

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ADMIN_USER_CUSTOMER_REMOVE, rsData);
    const data: APICustomerUser = await response.json();
    const newUser = makeCustomerUser(data);
    updateLocalUsers(newUser);
    notifier.setToast("success", "Removed!");
  }

  async function disableUser() {
    if (!selectedUser.value) {
      return undefined;
    }

    const rsData = {
      customer_user_id: selectedUser.value.userId,
      email: selectedUser.value.email,
      success: false,
    };

    notifier.setLoading("Disabling user");
    const request = useFetch();
    const response = await request.del(`/admin/users/${selectedUser.value.userId}`);
    notifier.setLoading();

    if (!response.ok) {
      rsData.success = false;
      notifier.setToast("danger", "Unable to disable user.");
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ADMIN_USER_DISABLE, rsData);
      return undefined;
    }

    const data: APICustomerUser = await response.json();
    const newUser = makeCustomerUser(data);
    updateLocalUsers(newUser);

    return newUser.userId;
  }

  async function activateUser() {
    if (!selectedUser.value) {
      return undefined;
    }

    notifier.setLoading("Activating user account.");
    const rsData: AdminUserEvent = {
      customer_user_id: selectedUser.value.userId,
      email: selectedUser.value.email,
      first_name: selectedUser.value.firstName,
      last_name: selectedUser.value.lastName,
      success: false,
    };

    const request = useFetch();
    const response = await request.put(`/admin/users/${selectedUser.value.userId}`, {
      body: {
        status: 1,
      },
    });

    notifier.setLoading();
    if (!response.ok) {
      notifier.setToast("danger", "Unable to activate user account.");
      rsData.success = false;
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ADMIN_USER_ACTIVATE, rsData);
      return undefined;
    }

    notifier.setToast("success", "User activated!");
    rsData.success = true;
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ADMIN_USER_CREATE, rsData);
    const data: APICustomerUser = await response.json();
    const newUser = makeCustomerUser(data);
    updateLocalUsers(newUser);
  }

  async function resetUserPassword() {
    if (!selectedUser.value) {
      return undefined;
    }

    const notifier = useNotificationStore();
    const trackerStore = useTrackerStore();
    const rsData: AdminUserEvent = {
      customer_user_id: selectedUserId.value,
      email: selectedUser.value.email,
    };

    const request = useFetch();
    const response = await request.post("/user/forgot", { body: { email: selectedUser.value.email } });

    if (!response.ok) {
      rsData.success = false;
      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ADMIN_USER_PASSWORD_RESET, rsData);
      notifier.setToast("danger", "Unable to reset password");
      return undefined;
    }

    const data: { success: boolean; reset_token: boolean } = await response.json();
    if (data.reset_token) {
      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ADMIN_USER_PASSWORD_RESET, rsData);
      notifier.setToast("success", "Password reset token generated. Email should be generated shortly.");
      return data.reset_token;
    }
  }

  return {
    allUsers,
    userRoles,
    userForm,
    updateLocalUsers,
    getAllUsers,
    allCustomers,
    getAllCustomers,
    selectedUser,
    selectedUserId,
    getUser,
    getUserRoles,
    saveUser,
    addCustomerAssociation,
    removeCustomerAssociation,
    disableUser,
    activateUser,
    resetUserPassword,
  };
});
