import { reactive, ref, computed } from "vue";
import { defineStore } from "pinia";
import dayjs from "dayjs";
import useNotificationStore from "./notifications";
import { useFetch } from "@/composables/fetch";
import { Quote, makeQuote } from "@/models/pricing/quotes";
import useTrackerStore from "./trackers";
import { RUDDERSTACK_EVENTS, RudderstackEvent } from "@/lib/rudderstack";
import type { PricingQuoteShipperCreateQuoteEvent } from "@/types/rudderstack";
import { STCC } from "@/types/stccs";
import { useBapi } from "@/bapi-client";
import { Option } from "@/types";
import { sortByStringProperty } from "@/utils/sorters";

interface ShipperForm {
  shipperName: string;
  email: string;
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  commodity: string;
  stcc?: string;
  stccQuery: string;
  volume: number;
  volumeType: string;
  startDate: Date;
  notes: string;
}

type FormErrors = Record<string, string | undefined>;

export const useShipperStore = defineStore("shipper", () => {
  const notifier = useNotificationStore();
  const trackers = useTrackerStore();

  const required = [
    "shipperName",
    "email",
    "originCity",
    "originState",
    "destinationState",
    "destinationCity",
    "volume",
  ];
  const stccCodes = ref<STCC[]>([]);
  const hasErrors = ref(false);
  const receivedQuote = ref<Quote>();
  const companyId = ref<string>("");
  const quoteId = ref<string>("");
  const token = ref<string>("");
  const quoteReviewNote = ref<string>("");

  const form: ShipperForm = reactive({
    shipperName: "",
    email: "",
    originCity: "",
    originState: "",
    destinationCity: "",
    destinationState: "",
    commodity: "",
    stccQuery: "",
    stcc: undefined,
    volume: 0,
    volumeType: "Per Car",
    startDate: new Date(),
    notes: "",
  });

  const errors: FormErrors = reactive({});

  const stccOptions = computed(() => {
    return stccCodes.value.map((stcc) => ({
      label: stcc.associatedSTCC + " - " + stcc.commodity,
      value: stcc.commodityCode,
    }));
  });

  const selectedStcc = computed({
    get() {
      return stccOptions.value.find((opt) => opt.value === form.stcc)?.value ?? "";
    },
    set(value: string) {
      form.stcc = value;
    },
  });

  function validate(): boolean {
    hasErrors.value = false;
    Object.keys(errors).forEach((key: string) => {
      delete errors[key];
    });

    required.forEach((field: string) => {
      if (!form[field as keyof typeof form]) {
        errors[field] = "This field cannot be blank.";
        hasErrors.value = true;
      }
    });

    if (!form.commodity && !form.stcc) {
      errors["commodity"] = "Please enter a commodity description or STCC.";
      hasErrors.value = true;
    }

    if (!dayjs(form.startDate).isValid()) {
      errors["startDate"] = "Please select a valid start date.";
      hasErrors.value = true;
    }

    return hasErrors.value;
  }

  async function getQuote() {
    notifier.setLoading();
    const request = useFetch();
    notifier.setLoading();
    const response = await request.get(`/shippers/quotes/${quoteId.value}?customer_id=${companyId.value}`, {
      headers: { "X-Auth-Token": token.value },
    });

    if (!response.ok) {
      notifier.setToast("danger", "We were unable to load the requested quote at this time.");
      console.error("Unable to GET shipper quote: ", response.status);
    }

    const data = await response.json();
    receivedQuote.value = makeQuote(data.quote);
  }

  async function getStccOptions({ search }: { search: string }) {
    if (!search || search.length < 3) {
      return [];
    }
    const response = await useBapi("getStccs", {
      context: "shipper",
      searchTerm: search,
      customerId: companyId.value,
    });

    if (response.success === false) {
      console.error(response.error);
      return [];
    }

    const out: Option[] = [];
    const resultLength = response.data.length;
    if (resultLength === 0) {
      return [];
    }
    for (let i = 0; i < resultLength; i++) {
      out.push({
        label: `[${response.data[i].commodityCode}] ${response.data[i].commodity}`,
        value: response.data[i].commodityCode,
        searchTag: response.data[i].commodity,
      });
    }

    return sortByStringProperty(out, "searchTag", "ASC");
  }

  async function submit(companyId: string) {
    // TODO: Rename this, because it returns true if there are errors.
    if (validate()) {
      return undefined;
    }
    const rsData: PricingQuoteShipperCreateQuoteEvent = {
      shipper_name: form.shipperName,
      to_customer_id: companyId,
      success: false,
    };

    const request = useFetch();
    const response = await request.post("/shippers/quotes?customer_id=" + companyId, {
      body: {
        email: form.email,
        data: {
          shipper_name: form.shipperName,
          origin_city: form.originCity,
          origin_state: form.originState,
          destination_city: form.destinationCity,
          destination_state: form.destinationState,
          commodity_description: form.commodity,
          stcc_ids: [form.stcc],
          start_date: dayjs(form.startDate).format("YYYY-MM-DD"),
          volume: form.volume,
          volume_type: form.volumeType,
        },
      },
    });

    if (!response.ok) {
      notifier.setToast("danger", "We were unable to process your request at this time.");
      console.error("Unable to POST shipper form: ", response.status);
      trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_SHIPPER_CREATE_QUOTE, rsData);
      return;
    }
    const noteResponse = await saveNote(form.notes, false);
    if (!noteResponse) {
      notifier.setToast("danger", "Request note not saved");
    }
    const data = await response.json();
    rsData.success = true;
    rsData.quote_id = data.quote.id;
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.PRICING_QUOTE_SHIPPER_CREATE_QUOTE, rsData);
    notifier.setToast("success", "Your request has been submitted!");
    return Promise.resolve(data);
  }

  async function submitQuoteResponse(status: number) {
    notifier.setLoading();
    const body = {
      data: { quote_review_note: quoteReviewNote.value, updated_by: "shipper" },
      status,
    };
    const rsData = {
      shipper_name: receivedQuote.value?.shipper.name,
      quote_id: receivedQuote.value?.id,
      success: false,
    };
    let rsEvent: RudderstackEvent = RUDDERSTACK_EVENTS.PRICING_QUOTE_SHIPPER_ACCEPT;
    if (status !== 50) {
      receivedQuote.value?.rejectedOnce === false
        ? (rsEvent = RUDDERSTACK_EVENTS.PRICING_QUOTE_SHIPPER_SEND_COUNTER)
        : (rsEvent = RUDDERSTACK_EVENTS.PRICING_QUOTE_SHIPPER_SEND_REJECTION);
    }
    const request = useFetch();
    const response = await request.post(`/shippers/quotes/${quoteId.value}?customer_id=${companyId.value}`, {
      headers: { "X-Auth-Token": token.value },
      body,
    });

    notifier.setLoading();

    if (!response.ok) {
      notifier.setToast("danger", "We were unable to send the response at this time.");
      console.error("Unable to POST shipper quote: ", response.status);
      trackers.logRudderstackEvent(rsEvent, rsData);
      return;
    }
    if (quoteReviewNote.value.length) {
      const noteResponse = await saveNote(quoteReviewNote.value, false);
      if (!noteResponse) {
        notifier.setToast("danger", "Request note not saved");
      }
    }
    notifier.setToast("success", "Response was sent successfully.");
    rsData.success = true;
    trackers.logRudderstackEvent(rsEvent, rsData);
  }

  async function saveNote(note: string, isInternal: false) {
    const request = useFetch();
    const response = await request.post(`/shippers/quotes/${quoteId.value}/notes?customer_id=${companyId.value}`, {
      headers: { "X-Auth-Token": token.value },
      body: { note, is_internal: isInternal },
    });
    if (!response.ok) {
      return undefined;
    }
    return response.ok;
  }

  return {
    form,
    errors,
    validate,
    submit,
    getStccOptions,
    stccCodes,
    stccOptions,
    selectedStcc,
    getQuote,
    receivedQuote,
    submitQuoteResponse,
    companyId,
    quoteId,
    token,
    quoteReviewNote,
  };
});
