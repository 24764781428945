<script setup lang="ts">
import { ref } from "vue";
import { useBapi } from "@/bapi-client";
import { Option } from "@/types";

type Props = {
  customerId: string;
  label: string;
  name?: string;
  value?: string;
};

const props = defineProps<Props>();
const options = ref<Option[]>([]);
async function findScacs({ search }: { search: string }): Promise<Option[]> {
  if (!search || search.length < 2) return options.value;
  const result = await useBapi("autocompleteScacs", props.customerId, search);
  if (!result.success) return options.value;
  const out: Option[] = [];
  for (const scac of result.data) {
    out.push({
      label: scac,
      value: scac,
    });
  }
  options.value = out;
  return options.value;
}
</script>

<template>
  <FormKit
    type="autocomplete"
    :name="name ?? 'scac'"
    :label="label"
    :options="findScacs"
    :close-on-select="true"
    :value="value"
    loader-icon="loader"
    empty-message="No matching SCAC found."
    placeholder="- -"
  />
</template>
