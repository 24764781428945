import { defineStore } from "pinia";
import Rudderstack from "@/lib/rudderstack";
import { useUserStore } from "@/stores/user";
import useNotificationStore from "./notifications";
import type { RudderstackEventData, RudderstackEventNames } from "@/types/rudderstack";

interface TrackerState {
  rudderstack?: Rudderstack | null;
}

const useTrackerStore = defineStore("trackers", {
  state: (): TrackerState => {
    return {
      rudderstack: null,
    };
  },
  actions: {
    async addIdentityTrackers(email?: string) {
      const userStore = useUserStore();
      const notifier = useNotificationStore();
      if (userStore.user) {
        if (email) {
          this.rudderstack?.identifyUser(
            userStore.user.userId,
            userStore.user.firstName,
            userStore.user.lastName,
            userStore.user.isAdmin,
            userStore.companyId,
            notifier.activeVersion,
            userStore.user.companies.find((company) => company.id === userStore.companyId)?.roleId ?? 0,
            email,
          );
          return;
        }
        this.rudderstack?.identifyUser(
          userStore.user.userId,
          userStore.user.firstName,
          userStore.user.lastName,
          userStore.user.isAdmin,
          userStore.companyId,
          notifier.activeVersion,
          userStore.user.companies.find((company) => company.id === userStore.companyId)?.roleId ?? 0,
        );
      }
    },

    logRudderstackEvent<T extends RudderstackEventNames>(type: T, data: RudderstackEventData[T]) {
      if (!this.rudderstack) return undefined;
      return this.rudderstack.logEvent(type, data);
    },
  },
});

export default useTrackerStore;
