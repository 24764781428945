<script setup lang="ts">
import { computed } from "vue";
import { ButtonIcon } from ".";
interface Props {
  isLarge: boolean;
}

const props = defineProps<Props>();

const size = computed(() => {
  return props.isLarge ? "lg" : "md";
});
</script>
<template>
  <button class="h-6 w-4 border-0 bg-transparent text-base text-blue hover:text-blue-900">
    <ButtonIcon :icon="`solid,ellipsis-vertical,${size}`" />
  </button>
</template>
