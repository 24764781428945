<script setup lang="ts">
import { ref } from "vue";
import { useIntersection } from "@/composables/intersection";
import { onMounted } from "vue";
import { onUnmounted } from "vue";

interface Props {
  threshold?: number;
  scrollDown: boolean;
  showDivider?: boolean;
}

// @TODO: Add a "pinned" prop to scroll list always to top/bottom
// when contents shift during flight.
const props = withDefaults(defineProps<Props>(), {
  threshold: 0.5,
  showDivider: true,
});

const emit = defineEmits<{ (event: "visible", value: string): void }>();
const { observer, scrollParent, watchChildren, unobserve, observe } = useIntersection();

const initialScroll = ref(false);
const prevLength = ref(0);

const mutObserver = ref<MutationObserver | undefined>();
function handler(entries: IntersectionObserverEntry[]) {
  for (const entry of entries) {
    if (entry.isIntersecting && entry.intersectionRatio >= props.threshold) {
      emit("visible", entry.target.id);
    }
  }
}
onMounted(() => {
  if (scrollParent.value !== null) {
    if (props.scrollDown) {
      scrollParent.value?.lastElementChild?.scrollIntoView({ block: "end" });
    }
    mutObserver.value = new MutationObserver((entries) => {
      const newLength = prevLength.value + entries.length;
      if (newLength > prevLength.value) {
        scrollParent.value?.lastElementChild?.scrollIntoView({ block: "end" });
      }
      prevLength.value = newLength;
      if (props.scrollDown && !initialScroll.value) {
        scrollParent.value?.lastElementChild?.scrollIntoView();
        initialScroll.value = true;
      }
      for (const entry of entries) {
        if (entry.addedNodes.length) {
          for (const addedNode of Array.from(entry.addedNodes)) {
            if (addedNode.nodeName === "LI" && addedNode.nodeType === 1) {
              unobserve(addedNode as Element);
              observe(addedNode as Element);
            }
          }
        }
      }
    });

    observer.value = new IntersectionObserver(handler, { root: scrollParent.value, threshold: props.threshold });
    watchChildren();
    mutObserver.value.observe(scrollParent.value, { childList: true });
  }
});

onUnmounted(() => mutObserver.value?.disconnect());
</script>

<template>
  <ul ref="scrollParent" class="h-full overflow-y-auto p-0" :class="{ 'divide-y': showDivider }">
    <slot />
  </ul>
</template>
