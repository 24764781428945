import { fetchHandler } from "@/bapi-client/fetch";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";

export async function autocompleteScac(customerId: string, search_term: string) {
  const URL = `/autocomplete/${customerId}/scac`;
  const response = await fetchHandler(BAPI_COMMANDS.AUTOCOMPLETE_SCACS, "POST", URL, {
    body: {
      search_term,
    },
  });
  if (!response.success) return response;
  const json = await response.data.json();
  if (!json.success) return json;
  return {
    success: true,
    data: json.data.data,
  };
}
