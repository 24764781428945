import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { AssetHistory, BAPIParamsAssetHistory } from "@/bapi-client/types/assets/assetHistory";
import { makeAssetHistory } from "@/bapi-client/factories/assets/assetHistory";
import { fetchHandler } from "@/bapi-client/fetch";
import type { Result } from "@/bapi-client/types";

export async function getAssetHistory(
  customerId: string,
  assetId: string,
  params: {
    destination?: string;
    loadEmptyStatus?: string;
    maxDateStr?: string;
    minDateStr?: string;
    origin?: string;
    page?: number;
    pageSize?: number;
  },
): Promise<Result<AssetHistory>> {
  const path = `/assets/${customerId}/${assetId}`;
  const body: BAPIParamsAssetHistory = {
    destination: params.destination,
    load_empty_status: params.loadEmptyStatus,
    max_date_str: params.maxDateStr,
    min_date_str: params.minDateStr,
    origin: params.origin,
    page: params.page,
    page_size: params.pageSize,
  };

  const fetchResult = await fetchHandler(BAPI_COMMANDS.GET_ASSET_HISTORY, "POST", path, {
    body,
  });

  if (!fetchResult.success) {
    return fetchResult;
  }

  const jsonResult = await fetchResult.data.json();
  if (!jsonResult.success) return jsonResult;

  return {
    success: true,
    data: makeAssetHistory(jsonResult.data),
  };
}
