<script setup lang="ts">
import { AutocompleteUsers } from "@/components/autocomplete";
import { TgButton } from "@/components/common";

type Props = {
  customerId: string;
};

defineProps<Props>();
const emit = defineEmits<{ assign: [userId: string]; selfAssign: [] }>();
function submit(form: { assignee: string }) {
  emit("assign", form.assignee);
}
</script>

<template>
  <div class="divide-y rounded-md border bg-white shadow-md">
    <FormKit
      ref="form"
      type="form"
      :actions="false"
      :classes="{
        messages: 'hidden',
      }"
      @submit="submit"
    >
      <div class="flex items-start gap-3 p-3">
        <AutocompleteUsers :customer-id="customerId" required name="assignee" />
        <TgButton is-small color="primary">Assign</TgButton>
      </div>
    </FormKit>
    <div class="flex items-center justify-center py-3">
      <TgButton color="success" is-small @click="$emit('selfAssign')">Claim for Yourself</TgButton>
    </div>
  </div>
</template>
