import { reactive, toRef, Ref } from "vue";
import { v4 as uuid } from "uuid";
import { bfs, FormKitNode, register } from "@formkit/core";
import type { CIFPartyItem, PatternInputDetails } from "@telegraphio/papi-client";

type StepData = {
  valid: Ref<boolean>;
  submitted: Ref<boolean>;
  blockingCount: number;
  errorCount: number;
  seen: boolean;
};

export default function useFormPlugins(inputDetails: Ref<PatternInputDetails[]>, isPattern: boolean) {
  const steps = reactive<Record<string, StepData>>({});

  const buildPath = (node: FormKitNode) => {
    let path = node.name;
    let parent = node.parent;
    let previousName = null;

    while (parent) {
      // Top level segments are wrapped by a group with the same name. So if the name matches
      // the previous name, we know we've reached the top.
      if (parent.name === previousName) {
        break;
      }

      let name = parent.name;

      if (Number.isInteger(parseInt(name))) {
        name = `[${name}]`;
      }

      path = `${name}.${path}`;
      previousName = parent.name;
      parent = parent.parent;
    }

    return path;
  };

  const stepPlugin = (node: FormKitNode) => {
    if (node.props.type === "group") {
      // builds an object of the top-level groups
      steps[node.name] = steps[node.name] || { seen: false };

      node.on("created", () => {
        // use 'on created' to ensure context object is available
        if (node.context) {
          steps[node.name].valid = toRef(node.context.state, "valid");
          steps[node.name].submitted = toRef(node.context.state, "submitted");
        }
      });

      // NEW: Store or update the count of blocking validation messages.
      // FormKit emits the "count:blocking" event (with the count) each
      // time the count changes.
      node.on("count:blocking", ({ payload: count }: { payload: number }) => {
        steps[node.name].blockingCount = count;
      });

      // NEW: Store or update the count of backend error messages.
      node.on("count:errors", ({ payload: count }: { payload: number }) => {
        steps[node.name].errorCount = count;
      });

      // Stop plugin inheritance to descendant nodes
      return false;
    }
  };

  const inputPlugin = (node: FormKitNode) => {
    const excludeTypes = ["form", "group", "repeater"];

    if (!excludeTypes.includes(node.props.type)) {
      const path = buildPath(node);

      const nodeId = uuid();

      node.props.id = nodeId;

      const isVariableCheckbox = node.name.endsWith("-checkbox");

      const truePath = isVariableCheckbox ? path.split("-")[0] : path;

      const details = inputDetails.value.find((detail) => detail.target === truePath);

      if (isVariableCheckbox) {
        // We don't want the checkbox values in the output, so we intercept
        // the value and return undefined instead.
        node.hook.input((_, next) => {
          return next(undefined);
        });

        node.on("mounted", () => {
          const el = document.getElementById(nodeId) as HTMLInputElement;
          if (details?.optional === false) {
            el.checked = true;
          }
        });
      } else if (details) {
        if (details.name !== node.name) {
          node.props.attrs.originalLabel = node.props.label;
          node.props.label = details.name;
          node.props.labelClass = "text-blue-400";
          node.props.attrs.optionLabel = details.name;
        }

        if (details.optional === false) {
          node.props.attrs.optionRequired = true;
        }

        if (!details.optional && !isPattern) {
          node.props.validation = `required|${node.props.validation}`;
        }
      }

      register(node);
    }

    return true;
  };

  const locationPlugin = (node: FormKitNode) => {
    const lookupNodes = ["origin-lookup", "destination-lookup", "disposition-lookup", "party-lookup"];

    if (node.props.type === "autocomplete" && lookupNodes.includes(node.name)) {
      const parent = node.parent as FormKitNode;

      node.on("created", () => {
        let city = null;
        let state = null;
        let country = null;

        if (node.name === "disposition-lookup") {
          city = bfs(parent, "city_name_01", "name")?.value;
          state = bfs(parent, "state_or_province_code_02", "name")?.value;
          country = bfs(parent, "country_code_04", "name")?.value;
        } else {
          city = bfs(parent, "city_name_02", "name")?.value;
          state = bfs(parent, "state_or_province_code_03", "name")?.value;
          country = bfs(parent, "country_code_04", "name")?.value;
        }

        if (!city && !state && !country) {
          return;
        }

        const location = `${city || ""}, ${state || ""}, ${country || ""}`;

        node.input(location);
      });

      node.hook.commit((value, next) => {
        if (!value) {
          return next(value);
        }

        const [city, state, country] = value.split(", ");

        if (node.name === "disposition-lookup") {
          bfs(parent, "city_name_01", "name")?.input(city);
          bfs(parent, "state_or_province_code_02", "name")?.input(state);
          bfs(parent, "country_code_04", "name")?.input(country);
        } else {
          bfs(parent, "city_name_02", "name")?.input(city);
          bfs(parent, "state_or_province_code_03", "name")?.input(state);
          bfs(parent, "country_code_04", "name")?.input(country);
        }

        next(undefined);
      });
    }
  };

  const commodityPlugin = (node: FormKitNode) => {
    if (node.name === "commodity-lookup") {
      node.hook.commit((value, next) => {
        if (!value) {
          return;
        }

        const parent = node.parent as FormKitNode;
        const [code, description] = value.split(" - ");

        bfs(parent, "lading_description_02", "name")?.input(description);
        bfs(parent, "commodity_code_03", "name")?.input(code);

        next(undefined);
      });
    }
  };

  const partyPlugin = (node: FormKitNode) => {
    if (node.name === "party-lookup") {
      node.hook.commit((value: CIFPartyItem, next) => {
        if (!value) {
          return;
        }

        const parent = node.parent as FormKitNode;

        let state = value.state;
        if (value.state === "NL" && value.country) {
          state = `${value.country}-${value.state}`;
        }

        bfs(parent, "name_02", "name")?.input(value.customerName);
        bfs(parent, "identification_code_04", "name")?.input(value.customerId);
        bfs(parent, "address_information_01", "name")?.input(value.address1);
        bfs(parent, "address_information_02", "name")?.input(value.address2);
        bfs(parent, "city_name_01", "name")?.input(value.city);
        bfs(parent, "state_or_province_code_02", "name")?.input(state);
        bfs(parent, "postal_code_03", "name")?.input(value.postal);
        bfs(parent, "country_code_04", "name")?.input(value.country);

        next(undefined);
      });
    }

    if (node.props.attrs.ediPath === "geographic_location_N4.state_or_province_code_02") {
      node.on("mounted", () => {
        if (node.value === "NL") {
          const parent = node.parent?.parent as FormKitNode;
          const country = bfs(parent, "country_code_04", "name");

          if (country?.value) {
            node.input(`${country.value}-${node.value}`);
          }
        }
      });

      node.hook.commit((value: string, next) => {
        if (!value) {
          return;
        }

        let nextValue = value;

        if (value.includes("-")) {
          nextValue = value.split("-")[1];
        }

        return next(nextValue);
      });
    }
  };

  return {
    steps,
    buildPath,
    stepPlugin,
    inputPlugin,
    locationPlugin,
    commodityPlugin,
    partyPlugin,
  };
}
