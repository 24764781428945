import * as telegraph from "@telegraphio/papi-client";

class AuthMiddleware {
  constructor(public token: string) {}

  async pre(context: telegraph.RequestContext): Promise<telegraph.FetchParams | void> {
    context.init.headers = {
      ...context.init.headers,
      "x-tg-booking-auth": this.token,
    };

    return {
      url: context.url,
      init: context.init,
    };
  }
}

export function usePapiClient() {
  const config = new telegraph.Configuration({
    ...telegraph.DefaultConfig,
    basePath: import.meta.env.VITE_PAPI_CLIENT_API_URL,
  });

  const api = new telegraph.BookingApi(config);

  const token = localStorage.getItem("authToken") || "";
  const middleW = new AuthMiddleware(token);

  const papiApi = api.withMiddleware(middleW);

  return {
    papiApi,
  };
}
