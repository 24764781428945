import { BAPIProfilePicture, BAPIUser, ProfilePicture, User } from "../../types/users";

export function userProfilePictureFactory(data?: BAPIProfilePicture): ProfilePicture {
  if (!data) {
    return {
      normalizedUrl: "",
      thumbnailUrl: "",
      rawUrl: "",
      fileName: "",
      mimeType: "",
    };
  }
  return {
    normalizedUrl: data.normalized_url,
    thumbnailUrl: data.thumbnail_url,
    rawUrl: data.raw_url,
    fileName: data.filename,
    mimeType: data.mime_type,
  };
}

export function userFactory(data: BAPIUser): User {
  return {
    userId: data.user_id,
    firstName: data.first_name,
    lastName: data.last_name,
    isAdmin: data.is_admin,
    isInternal: data.is_internal,
    adminId: data.admin_id ?? undefined,
    token: data.token,
    companies: data.companies.map((company) => ({
      id: company.id,
      crmUrl: company.crm_url,
      name: company.name,
      domain: company.domain,
      logo: company.logo,
      type: company.type,
      typeId: company.type_id,
      status: company.status,
      zipCode: company.zip_code ?? "",
      roleId: company.role_id,
      roleName: company.role_name,
      integrations: company.integrations,
      waybillType: company.waybill_type,
      get searchTag() {
        return `${company.name}`.toUpperCase();
      },
    })),
    status: data.status,
    zipCode: data.zip_code ?? "",
    profilePicture: userProfilePictureFactory(data.profile_picture),
  };
}
