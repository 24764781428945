<script setup lang="ts">
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useNotificationStore from "@/stores/notifications";
import useTrackerStore from "@/stores/trackers";

const notifier = useNotificationStore();
const trackers = useTrackerStore();

async function reloadPage() {
  const response = await notifier.getLatestAppVersion();
  if (response.length) {
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_EVENT_UPDATE_BANNER_REFRESH, {
      current_version: response,
    });
  }
  const getIndex = await fetch(window.location.href, {
    headers: {
      "Cache-Control": "no-store",
      Pragma: "no-cache",
    },
  });
  if (getIndex.ok) {
    location.reload();
  }
}
</script>

<template>
  <Transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-out duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div v-show="notifier.showUpdateBanner" class="fixed inset-x-0 top-0 z-50">
      <div class="bg-blue px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <p class="text-center text-sm leading-6 text-white">
          <a class="underline hover:text-white-ice" href="#0" @click.prevent="reloadPage">
            <strong class="font-semibold">Update available! Click here to update to the latest version.</strong>
          </a>
        </p>
      </div>
    </div>
  </Transition>
</template>
