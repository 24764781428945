<script setup lang="ts">
import { computed } from "vue";
import type { BAPITicketAsset } from "@/bapi-client/types/customer-support";
import PopoverTag from "./PopoverTag.vue";

const props = defineProps<{ customerId: string; asset: BAPITicketAsset }>();
defineEmits<{ "open-asset": [assetId: string, waybillId: string] }>();
const reportingGroupLabels = computed(() => {
  const out: string[] = [];
  for (const rg of props.asset.reporting_group_name_map) {
    for (const id in rg) {
      out.push(rg[id]);
    }
  }
  return out;
});
</script>

<template>
  <div class="flex w-full items-center gap-3">
    <div class="inline-flex w-44 grow items-center gap-1.5 text-left">
      <span class="!mb-0 text-sm font-semibold !text-gray-500">Asset ID</span>
      <router-link
        v-if="asset.waybill_id"
        :to="{ name: 'assetDetails', params: { companyId: customerId, waybillId: asset.waybill_id } }"
        class="font-semibold text-blue hover:text-blue-700 hover:underline"
        @click="$emit('open-asset', asset.equipment_id, asset.waybill_id ?? '')"
      >
        {{ asset.equipment_id }}
      </router-link>
      <span v-else>{{ asset.equipment_id }}</span>
    </div>
    <div class="inline-flex grow items-center gap-1.5 text-left">
      <span class="!mb-0 text-sm font-semibold !text-gray-500">Shipment</span>
      <span v-if="asset.waybill_id" class="whitespace-nowrap">
        {{ asset.waybill_date }} #{{ asset.waybill_number }}
      </span>
      <span v-else> - - </span>
    </div>
    <p class="inline-flex grow items-center gap-1.5 text-left">
      <span class="whitespace-nowrap text-sm font-semibold !text-gray-500">Reporting Group</span>
      <span v-if="reportingGroupLabels.length">
        {{ reportingGroupLabels[0] }}
        <PopoverTag
          v-if="reportingGroupLabels.length > 1"
          class="ml-1.5 inline-block"
          name="reportingGroups"
          :label="`+ ${reportingGroupLabels.length - 1}`"
        >
          <ul class="divide-y">
            <template v-for="(label, idx) in reportingGroupLabels" :key="label">
              <li v-if="idx > 0" class="px-3 py-1.5 text-sm font-semibold">{{ label }}</li>
            </template>
          </ul>
        </PopoverTag>
      </span>
      <span v-else> - - </span>
    </p>
  </div>
</template>
