import { ref } from "vue";
import { watchEffect } from "vue";
import { useFetch } from "./fetch";
import { FileAttachment, AttachmentResponse, Thumbnail } from "@/bapi-client/types/common/attachment";

export function useAttachments() {
  // Provide a unique identifier to attach attachments to
  // could be a note ID, quote ID, etc.
  const attachments = ref<Record<string, AttachmentResponse[]>>({});
  const request = useFetch();

  // Provide the SAME unique ID here, so that thumbnails can
  // be tied back to arbitrary entities like a quote, comment,
  // note, project, user, etc.
  const thumbnails = ref<Record<string, Thumbnail[]>>({});
  const files = ref<Record<string, FileAttachment[]>>({});

  async function loadThumbnails(id: string) {
    if (!thumbnails.value[id]) return undefined;

    for (const thumbnail of thumbnails.value[id]) {
      if (thumbnail.src) return undefined;
      thumbnail.loading = true;
      const response = await request.get(thumbnail.url);
      thumbnail.loading = false;

      if (!response.ok) {
        return undefined;
      }

      const data = await response.blob().catch((e: Error) => {
        console.error(e);
        return undefined;
      });

      if (!data) return undefined;
      thumbnail.src = URL.createObjectURL(data);
    }
  }

  async function previewFile(id: string, fileId: string): Promise<string | undefined> {
    const attachment = attachments.value[id]?.find((att) => att.id === fileId);
    if (!attachment) return undefined;

    const response = await request.get(attachment.normalized_url);
    if (!response.ok) {
      return undefined;
    }

    const data = await response.blob().catch((e: Error) => {
      console.error(e);
      return undefined;
    });

    if (!data) return undefined;
    const url = URL.createObjectURL(data);
    return url;
  }

  async function downloadFile(id: string, fileId: string): Promise<undefined | true> {
    const attachment = attachments.value[id]?.find((att) => att.id === fileId);
    if (!attachment) return undefined;

    const response = await request.get(attachment.download_url);
    if (!response.ok) {
      return undefined;
    }

    const data = await response.blob().catch((e: Error) => {
      console.error(e);
      return undefined;
    });

    if (!data) return undefined;
    const link = window.document.createElement("a");
    const url = URL.createObjectURL(data);
    link.href = url;
    link.download = attachment.filename;
    window.document.body.append(link);
    link.click();
    window.document.body.removeChild(link);
    URL.revokeObjectURL(url);
    return true;
  }

  watchEffect(() => {
    for (const id in attachments.value) {
      if (attachments.value[id] !== undefined) {
        console.log(attachments.value[id]);

        for (const att of attachments.value[id]) {
          if (!att.mime_type.startsWith("image/")) {
            if (!files.value[id]) {
              files.value[id] = [];
            }
            files.value[id].push({
              id: att.id,
              fileName: att.filename,
              fileSize: att.filesize,
              mimeType: att.mime_type,
              extension: att.extension,
            });
            continue;
          }
          // Do we already have a thumbnail for this?
          if (!thumbnails.value[id]) {
            thumbnails.value[id] = [];
          }
          const thumb = thumbnails.value[id]?.find((thumb) => thumb.id === att.id);
          if (thumb) {
            continue;
          }
          thumbnails.value[id]?.push({
            id: att.id,
            url: att.thumbnail_url,
            loading: false,
            src: "",
          });
        }
      }
    }
  });

  return { attachments, thumbnails, files, loadThumbnails, downloadFile, previewFile };
}
