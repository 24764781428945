import { BAPI_COMMANDS } from "../types/commands";
import type { HandlerParams } from "../types/handlerParams";
import { fetchHandler } from "../fetch";
import { Result } from "../types";
import type { BAPIParamsCreateTemplate, BAPITemplate, BAPITemplateFieldList } from "../types/reportTemplate";

export async function getReportTemplate(customerId: string, templateId: string): Promise<Result<BAPITemplate>> {
  const path = `/reports/${customerId}/template/${templateId}`;

  const response = await fetchHandler(BAPI_COMMANDS.GET_REPORT_TEMPLATE, "GET", path, {
    canAbort: true,
  });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const jsonResult = await data.json();

  if (!jsonResult.success) {
    return jsonResult;
  }

  return {
    success: true,
    data: jsonResult.data,
  };
}

export async function getReportTemplates(
  opts: HandlerParams[typeof BAPI_COMMANDS.GET_REPORT_TEMPLATES],
): Promise<Result<BAPITemplate[]>> {
  const path = `/reports/${opts.customerId}/template`;

  const response = await fetchHandler(BAPI_COMMANDS.GET_REPORT_TEMPLATES, "GET", path, {
    canAbort: true,
  });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const jsonResult = await data.json();

  if (!jsonResult.success) {
    return jsonResult;
  }

  return {
    success: true,
    data: jsonResult.data.map((template) => {
      return {
        ...template,
        template_name: template.template_name.trim(),
      };
    }),
  };
}

export async function deleteReportTemplate(
  customerId: string,
  templateId: string,
): Promise<Result<{ success: boolean }>> {
  const response = await fetchHandler(
    "deleteReportTemplate",
    "DELETE",
    `/reports/${customerId}/template/${templateId}`,
  );

  if (!response.success) {
    return response;
  }

  const { data } = response;
  return await data.json();
}

export async function createReportTemplate(
  customerId: string,
  formData: BAPIParamsCreateTemplate,
): Promise<Result<BAPITemplate>> {
  const response = await fetchHandler(BAPI_COMMANDS.CREATE_REPORT_TEMPLATE, "POST", `/reports/${customerId}/template`, {
    body: formData,
  });

  if (!response.success) {
    return response;
  }

  const result = await response.data.json();
  return result.success ? { success: true, data: result.data } : result;
}

export async function updateReportTemplate(
  customerId: string,
  templateId: string,
  formData: BAPIParamsCreateTemplate,
): Promise<Result<BAPITemplate>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.UPDATE_REPORT_TEMPLATE,
    "PUT",
    `/reports/${customerId}/template/${templateId}`,
    {
      body: formData,
    },
  );

  if (!response.success) {
    return response;
  }

  const result = await response.data.json();
  return result.success ? { success: true, data: result.data } : result;
}

export async function getReportTemplateFields(
  opts: HandlerParams[typeof BAPI_COMMANDS.GET_REPORT_TEMPLATE_FIELDS],
): Promise<Result<BAPITemplateFieldList>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.GET_REPORT_TEMPLATE_FIELDS,
    "GET",
    `/reports/${opts.customerId}/template/columns`,
  );

  if (!response.success) {
    return response;
  }

  const jsonResult = await response.data.json();
  if (!jsonResult.success) {
    return jsonResult;
  }

  const { data } = jsonResult;

  return {
    success: true,
    data,
  };
}
