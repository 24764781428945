import dayjs from "dayjs";
import { defineStore } from "pinia";
import ForecastReport, { ForecastRows } from "@/models/reports/forecast/forecastReport";
import ForecastReportEquipment from "@/models/reports/forecast/reportEquipment";
import ReportingGroup from "@/models/reportingGroup";
import Location from "@/models/location";
import { useUserStore } from "./user";
import useTrackerStore from "./trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { createCsv, downloadCsv } from "@/utils/csv";
import useNotificationStore from "./notifications";
import { useFetch } from "@/composables/fetch";
import type { AnalyticsForecastGenerateEvent, AnalyticsForecastModalDataEvent } from "@/types/rudderstack";

interface State {
  report: ForecastReport | null;
  availableGroups: ReportingGroup[];
  availableDestinations: Location[];
  selectedGroupIds: string[];
  selectedDestinationIds: string[];
  sortDirection: "DESC" | "ASC";
  modalDate: string;
  modalGroupId?: string;
  fleetOne: string;
  fleetTwo: string;
  fleetThree: string;
}

interface Payload {
  destination?: string;
  groups?: string;
  eta_date?: string;
}

const useForecastStore = defineStore("forecast", {
  state: (): State => ({
    report: null,
    availableGroups: [],
    availableDestinations: [],
    selectedGroupIds: [],
    selectedDestinationIds: [],
    sortDirection: "DESC",
    modalDate: "",
    modalGroupId: "",
    fleetOne: "",
    fleetTwo: "",
    fleetThree: "",
  }),
  getters: {
    sortedDays: (state: State): ForecastRows =>
      state.report
        ? Object.keys(state.report.days)
            .sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? -1 : 0))
            .reduce((days: any, day: string) => {
              return {
                ...days,
                [day]: state.report?.days[day],
              };
            }, {})
        : {},
    grandTotal(): number {
      return Object.values(this.sortedDays).reduce((total: number, day: any) => {
        total += day.totalInbound;
        return total;
      }, 0);
    },
    fleetOneTotal(): number {
      return Object.values(this.sortedDays).reduce((total: number, day: any) => {
        if (day.groups[this.fleetOne]) {
          total += day.groups[this.fleetOne];
          return total;
        }
        return total;
      }, 0);
    },
    fleetTwoTotal(): number {
      return Object.values(this.sortedDays).reduce((total: number, day: any) => {
        if (day.groups[this.fleetTwo]) {
          total += day.groups[this.fleetTwo];
          return total;
        }
        return total;
      }, 0);
    },
    fleetThreeTotal(): number {
      return Object.values(this.sortedDays).reduce((total: number, day: any) => {
        if (day.groups[this.fleetThree]) {
          total += day.groups[this.fleetThree];
          return total;
        }
        return total;
      }, 0);
    },
  },
  actions: {
    async getDestinations(): Promise<void> {
      const user = useUserStore();
      const notifier = useNotificationStore();
      notifier.setLoading("Loading destinations");
      const request = useFetch();
      const response = await request.post(`/reports/${user.companyId}/destinations`);
      notifier.setLoading();
      if (!response.ok) {
        notifier.setToast("danger", "Unable to load destinations at this time.");
        return;
      }
      const data = await response.json();
      const destinations = data.data.map((destination: any) => new Location(destination));
      this.availableDestinations = destinations;
    },
    async getGroups(): Promise<void> {
      const user = useUserStore();
      const notifier = useNotificationStore();
      notifier.setLoading("Loading groups");
      const request = useFetch();
      const response = await request.get(`/reports/${user.companyId}/groups`);
      notifier.setLoading();
      if (!response.ok) {
        notifier.setToast("danger", "Unable to load groups at this time.");
        return;
      }
      const data = await response.json();
      const groups = data.data.map((group: any) => new ReportingGroup(group));
      this.availableGroups = groups;
    },
    setSelectedDestinations(destinationIds: string[]) {
      this.selectedDestinationIds = destinationIds;
    },
    setSelectedGroups(groupIds: string[]) {
      this.selectedGroupIds = groupIds;
    },
    async generateReport(): Promise<void> {
      if (!this.selectedDestinationIds.length && !this.selectedGroupIds.length) {
        this.report = null;
        return;
      }
      const user = useUserStore();
      const notifier = useNotificationStore();
      const trackerStore = useTrackerStore();

      const rsData: AnalyticsForecastGenerateEvent = {
        type: "",
        success: false,
      };
      const postBody: Payload = {};
      if (this.selectedDestinationIds.length) {
        const ids = this.selectedDestinationIds.join(",");
        rsData.destination_ids = ids;
        rsData.type = "destination";
        postBody.destination = ids;
      }
      if (this.selectedGroupIds.length) {
        const ids = this.selectedGroupIds.join(",");
        rsData.group_ids = ids;
        if (rsData.type.length) {
          rsData.type += ", groups";
        } else {
          rsData.type = "groups";
        }
        postBody.groups = ids;
      }
      notifier.setLoading("Generating report");
      const request = useFetch();
      const response = await request.post(`/reports/${user.companyId}/inbound_forecast`, {
        body: postBody,
      });
      notifier.setLoading();
      rsData.success = response.ok;

      if (!response.ok) {
        trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_FORECAST_GENERATE, rsData);
        if (response.status === 404) {
          notifier.setToast("info", "No data returned for the selected groups/destinations.");
          return;
        }
        notifier.setToast("danger", "Unable to load report at this time.");
        return;
      }

      const data = await response.json();
      if (data.record_count === 0) {
        notifier.setToast("info", "No data returned for the selected groups/destinations.");
      }
      rsData.results_count = data.record_count;
      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_FORECAST_GENERATE, rsData);
      this.report = new ForecastReport(data);
    },
    async triggerEquipmentModal(date: string, groupId: string) {
      if (groupId) {
        this.modalGroupId = groupId;
      }
      this.modalDate = date;
      await this.getEquipment();
    },
    async getEquipment() {
      const notifier = useNotificationStore();
      const userStore = useUserStore();
      const trackerStore = useTrackerStore();
      const rsData: AnalyticsForecastModalDataEvent = {
        group_id: this.modalGroupId || "",
        group_name: this.availableGroups.find((group: ReportingGroup) => group.id === this.modalGroupId)?.name,
        type: "",
        results_count: this.report?.equipment ? this.report.equipment.length : 0,
        success: false,
      };
      const postBody: Payload = {};

      postBody.eta_date = dayjs(this.modalDate).format("YYYY-MM-DD");
      if (this.selectedDestinationIds.length) {
        rsData.destination_ids = this.selectedDestinationIds;
        rsData.type = "destination";
        postBody.destination = this.selectedDestinationIds.join(",");
      }

      if (this.modalGroupId) {
        postBody.groups = this.modalGroupId;
      } else {
        if (this.selectedGroupIds.length) {
          rsData.group_ids = this.selectedGroupIds;
          if (rsData.type.length) {
            rsData.type += ", groups";
          } else {
            rsData.type = "groups";
          }
          postBody.groups = this.selectedGroupIds.join(",");
        }
      }
      notifier.setLoading("Loading equipment");
      const request = useFetch();
      const response = await request.post(`/reports/${userStore.companyId}/inbound_forecast/equipment`, {
        body: postBody,
      });
      notifier.setLoading();
      rsData.success = response.ok;
      if (!response.ok) {
        trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_FORECAST_GENERATE_EQUIPMENT_MODAL, rsData);
        if (response.status === 404) {
          notifier.setToast("info", "No equipment found for the selected date/group.");
          return;
        }
        notifier.setToast("danger", "Unable to load equipment for the selected date.");
        return;
      }

      const data = await response.json();
      if (this.report) {
        const report = data.data.map((asset: any) => new ForecastReportEquipment(asset));
        this.report.equipment = report;
      }
      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_FORECAST_GENERATE_EQUIPMENT_MODAL, rsData);
    },
    async prepareCsv() {
      if (this.report) {
        const trackerStore = useTrackerStore();
        const data: AnalyticsForecastModalDataEvent = {
          group_id: this.modalGroupId || "",
          group_name: this.availableGroups.find((group: ReportingGroup) => group.id === this.modalGroupId)?.name,
          type: "",
          results_count: this.grandTotal || 0,
        };
        if (this.selectedDestinationIds.length) {
          data.destination_ids = this.selectedDestinationIds;
          data.type = "destination";
        }
        if (this.selectedGroupIds.length) {
          data.group_ids = this.selectedGroupIds;
          if (data.type.length) {
            data.type += ", groups";
          } else {
            data.type = "groups";
          }
        }
        trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_FORECAST_DOWNLOAD_CSV, data);
        const headers: string[] = ["Estimated Arrival", "Day of the Week", "Total Inbound Assets"];
        headers.push(
          `"${this.availableGroups?.find((group: ReportingGroup) => group.id === this.fleetOne)?.name || ""}"`,
        );
        headers.push(
          `"${this.availableGroups?.find((group: ReportingGroup) => group.id === this.fleetTwo)?.name || ""}"`,
        );
        headers.push(
          `"${this.availableGroups?.find((group: ReportingGroup) => group.id === this.fleetThree)?.name || ""}"`,
        );

        const rows = Object.entries(this.sortedDays).map(([key, value]: [string, any]) => {
          return [
            key,
            dayjs(key).format("dddd"),
            value.totalInbound,
            value.groups[this.fleetOne] || "",
            value.groups[this.fleetTwo] || "",
            value.groups[this.fleetThree] || "",
          ];
        });

        rows.push(["Totals:", "", this.grandTotal, this.fleetOneTotal, this.fleetTwoTotal, this.fleetThreeTotal]);
        const csv = createCsv(headers, rows);
        downloadCsv(`forecast-report-${dayjs().format("MM-DD-YYYY")}.csv`, csv);
      }
    },
  },
});

export default useForecastStore;
