<script setup lang="ts"></script>

<template>
  <fieldset class="flex items-center gap-3">
    <FormKit type="group" name="priority">
      <FormKit
        type="checkbox"
        :value="true"
        name="low"
        :classes="{
          wrapper: '!m-0',
          outer: 'grow-0 p-2',
        }"
      >
        <template #label
          ><i class="fa-solid fa-fire-flame-simple pr-1 text-lg text-yellow-500"></i
          ><span class="font-semibold">Low</span></template
        >
      </FormKit>
      <FormKit
        type="checkbox"
        name="medium"
        :value="true"
        :classes="{
          wrapper: '!m-0',
          outer: 'grow-0 p-2',
          label: '!mb-0',
        }"
      >
        <template #label
          ><i class="fa-solid fa-fire-flame-simple pr-1 text-lg text-orange"></i
          ><span class="font-semibold">Medium</span></template
        >
      </FormKit>
      <FormKit
        type="checkbox"
        name="high"
        :value="true"
        :classes="{
          wrapper: '!m-0',
          outer: 'grow-0 p-2',
        }"
      >
        <template #label
          ><i class="fa-solid fa-fire-flame-simple pr-1 text-lg text-red-500"></i
          ><span class="font-semibold">High</span></template
        >
      </FormKit>
    </FormKit>
    <FormKit
      type="toggle"
      name="my_tickets"
      label="View My Tickets Only"
      :classes="{
        outer: 'grow-0 p-2 ml-10',
        label: 'font-semibold',
      }"
    />
  </fieldset>
</template>
