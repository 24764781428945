<script setup lang="ts">
import { inject, ref } from "vue";
import { useBapi } from "@/bapi-client";
import { BAPICustomerSupportTicket } from "@/bapi-client/types/customer-support";
import AssigneeField from "./AssigneeField.vue";

type Props = {
  ticket: BAPICustomerSupportTicket;
  currentUser: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  success: [ticketNumber: string];
  error: [];
}>();

const getTickets = inject<() => Promise<void>>("get_tickets", () => {
  console.error("get_tickets injected but not provided");
  return Promise.resolve();
});

async function selfAssign() {
  const response = await useBapi("updateTicket", {
    customer_id: props.ticket.customer_id,
    ticket_id: `${props.ticket.id}`,
    assignees: [props.currentUser],
    state: "In Progress",
  });
  if (!response.success) {
    return emit("error");
  }
  await getTickets();
  popover.value?.hidePopover();
  emit("success", `${response.data.id}`);
}

async function assign(userId: string) {
  const response = await useBapi("updateTicket", {
    customer_id: props.ticket.customer_id,
    ticket_id: `${props.ticket.id}`,
    assignees: [userId],
    state: "In Progress",
  });
  if (!response.success) {
    return emit("error");
  }
  await getTickets();
  popover.value?.hidePopover();
  emit("success", `${response.data.id}`);
}

const trigger = ref<HTMLButtonElement | null>(null);
const popover = ref<HTMLDivElement | null>(null);
function onToggle(e: ToggleEvent) {
  if (e.newState === "open") {
    if (trigger.value && popover.value) {
      popover.value.scrollIntoView({ behavior: "smooth" });
      const { right: triggerRight, bottom: triggerBottom } = trigger.value.getBoundingClientRect();
      const { width: popoverWidth } = popover.value.getBoundingClientRect();
      popover.value.style.top = triggerBottom + window.scrollY + 4 + "px";
      popover.value.style.left = `${triggerRight - popoverWidth}px`;
    }
  }
}
</script>

<template>
  <div class="relative">
    <button
      ref="trigger"
      :popovertarget="`${ticket.id}-assign`"
      class="rounded bg-transparent px-3 py-1.5 text-xs font-semibold text-blue-500 shadow-sm ring-1 ring-inset ring-blue-500 hover:bg-blue-50/35"
    >
      Assign
    </button>
    <div :id="`${ticket.id}-assign`" ref="popover" class="absolute inset-[unset]" popover @toggle="onToggle">
      <AssigneeField :customer-id="ticket.customer_id" @assign="assign" @self-assign="selfAssign" />
    </div>
  </div>
</template>
