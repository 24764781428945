import dayjs from "dayjs";
import { defineStore } from "pinia";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import { useUserStore } from "@/stores/user";
import useNotificationStore from "@/stores/notifications";
import ReportingGroup from "@/models/reportingGroup";
import FleetCompleteCycle from "@/models/fleet/fleetCompleteCycle";
import { createCsv, downloadCsv } from "@/utils/csv";
import { useFetch } from "@/composables/fetch";
import type { EquipmentFleetManagementLoadEquipmentEvent } from "@/types/rudderstack";
import { WaybillEvent } from "@/bapi-client/types/waybills";

interface FleetAsset {
  id: string;
  displayName: string;
  cycleCount: number;
}

interface State {
  selectedReportingGroup: string;
  selectedEquipmentId: string;
  reportingGroups: ReportingGroup[];
  assets: FleetAsset[];
  selectedEquipmentCycles: FleetCompleteCycle[] | null;
  selectedEquipmentEvents: WaybillEvent[] | null;
  fromDate: Date;
  toDate: Date;
  lastEquipmentError?: string;
}

const useFleetStore = defineStore("cycles", {
  state: (): State => ({
    selectedReportingGroup: "",
    selectedEquipmentId: "",
    selectedEquipmentCycles: null,
    selectedEquipmentEvents: null,
    reportingGroups: [],
    assets: [],
    fromDate: dayjs().subtract(180, "day").toDate(),
    toDate: dayjs().toDate(),
  }),
  actions: {
    async getReportingGroups() {
      const notifier = useNotificationStore();
      const user = useUserStore();
      const request = useFetch();
      const response = await request.get(`/reports/${user.companyId}/groups`);

      if (!response.ok) {
        notifier.setToast("danger", "Unable to load Reporting Groups at this time.");
        return undefined;
      }

      const data: {
        data: {
          deleted: boolean;
          id: string;
          name: string;
        }[];
        success: boolean;
      } = await response.json();

      const reportingGroups = data.data.map((group: any) => new ReportingGroup(group));
      this.reportingGroups = reportingGroups;
    },
    async selectReportingGroup(group: string) {
      this.selectedReportingGroup = group;
      this.selectedEquipmentEvents = null;
      this.selectedEquipmentCycles = null;
      this.selectedEquipmentId = "";
      await this.getGroupEquipment();
    },
    async getGroupEquipment() {
      const notifier = useNotificationStore();
      if (this.lastEquipmentError) {
        notifier.clearToast(this.lastEquipmentError);
      }

      const trackerStore = useTrackerStore();
      const user = useUserStore();
      const rsData: EquipmentFleetManagementLoadEquipmentEvent = {
        group: this.selectedReportingGroup,
        success: false,
      };
      notifier.setLoading();

      const request = useFetch();
      const response = await request.get(`/reports/${user.companyId}/cycles/${this.selectedReportingGroup}/equipment`);
      notifier.setLoading();

      if (!response.ok) {
        this.assets = [];
        trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT, rsData);
        if (response.status === 404) {
          this.lastEquipmentError = notifier.setToast("info", "No equipment found for the selected group");
          return undefined;
        }
        this.lastEquipmentError = notifier.setToast("danger", "Unable to load equipment at this time");
        return undefined;
      }

      const data: {
        success: boolean;
        total_records: number;
        equipment_ids: {
          cycle_count: 18;
          display_name: string;
          id: string;
        }[];
      } = await response.json();

      const assets = data.equipment_ids.map((asset: any) => ({
        id: asset.id,
        displayName: asset.display_name,
        cycleCount: asset.cycle_count,
      }));

      this.assets = assets;
      rsData.success = true;
      rsData.results_count = assets.length;
      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT, rsData);
    },
    async selectEquipment(value: string) {
      this.selectedEquipmentId = value;
      await this.getEquipmentCycles();
    },
    async selectDateRange(value: Date[]) {
      if (value[0] && value[1]) {
        this.fromDate = value[0];
        this.toDate = value[1];
        await this.getEquipmentCycles();
      }
    },
    async getEquipmentCycles() {
      const trackerStore = useTrackerStore();
      const user = useUserStore();
      const notifier = useNotificationStore();
      const rsData: EquipmentFleetManagementLoadEquipmentEvent = {
        equipment: this.selectedEquipmentId,
        success: false,
      };

      const from = dayjs(this.fromDate).format("YYYY-MM-DD");
      const to = dayjs(this.toDate).format("YYYY-MM-DD");
      const group = this.selectedReportingGroup;
      const asset = this.selectedEquipmentId;

      const request = useFetch();
      const response = await request.get(
        `/reports/${user.companyId}/cycles/${group}/cycles/${asset}?fromDate=${from}&toDate=${to}`,
      );

      if (!response.ok) {
        rsData.success = false;
        trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT_CYCLES, rsData);
        if (response.status === 404) {
          notifier.setToast("info", "No cycles found for selected equipment.");
          notifier.status = "ready";
          this.selectedEquipmentCycles = null;
          return undefined;
        }
        notifier.setToast("danger", "Unable to load cycles at this time.");
        return undefined;
      }

      const data = await response.json();
      const cycles = data.waybill_cycles?.map((cycle: any) => new FleetCompleteCycle(cycle)) ?? [];
      this.selectedEquipmentCycles = cycles;
      rsData.success = true;
      rsData.results_count = cycles.length || 0;
      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT_CYCLES, rsData);
    },
    async getEquipmentEvents() {
      const trackerStore = useTrackerStore();
      const user = useUserStore();
      const notifier = useNotificationStore();
      const rsData: EquipmentFleetManagementLoadEquipmentEvent = {
        equipment: this.selectedEquipmentId,
        success: false,
      };
      const from = dayjs(this.fromDate).format("YYYY-MM-DD");
      const to = dayjs(this.toDate).format("YYYY-MM-DD");
      const group = this.selectedReportingGroup;
      const asset = this.selectedEquipmentId;

      const request = useFetch();
      const response = await request.get(
        `/reports/${user.companyId}/cycles/${group}/events/${asset}?fromDate=${from}&toDate=${to}`,
      );
      if (!response.ok) {
        trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT_EVENTS, rsData);
        if (response.status === 404) {
          notifier.setToast("info", "No events found for selected equipment.");
          return undefined;
        }
        notifier.setToast("danger", "Unable to load events at this time.");
        return undefined;
      }

      rsData.success = true;
      const data = await response.json();
      const events = data.events;
      this.selectedEquipmentEvents = events;
      rsData.results_count = events.length;
      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT_EVENTS, rsData);
    },
    async downloadEventsCsv() {
      if (!this.selectedEquipmentEvents) return undefined;
      const trackerStore = useTrackerStore();
      const group = this.reportingGroups.find((group) => group.id === this.selectedReportingGroup);

      trackerStore.logRudderstackEvent(RUDDERSTACK_EVENTS.EQUIPMENT_FLEET_MANAGEMENT_DOWNLOAD_EVENTS, {
        groupName: group?.name,
        groupId: this.selectedReportingGroup,
        equipmentId: this.selectedEquipmentId,
      });

      const headers: string[] = ["Date/Time", "Location", "Event"];

      const rows = this.selectedEquipmentEvents.map((event) => [
        `"${dayjs(event.sighting_date.toString()).format("MM/DD HH:mm")}"`,
        `"${event.location.display_name}"`,
        `"${event.sighting_event_code_text}"`,
      ]);

      const csv = createCsv(headers, rows);

      downloadCsv(`${this.selectedEquipmentId}-events-${dayjs().format("MM-DD-YYYY")}.csv`, csv);
    },
  },
});

export default useFleetStore;
