import { ref, Ref } from "vue";

export function useDebounce(time: number) {
  const timeout: Ref<number | null> = ref(null);

  function debounce(callback: (...a: any[]) => any) {
    if (timeout.value) {
      clearTimeout(timeout.value);
    }
    timeout.value = window.setTimeout(callback, time);
  }

  return { debounce };
}
