import shipperActions from "@/models/shipperActions";
import useNotificationStore from "@/stores/notifications";
import { useShipperStore } from "@/stores/shipper";
import { RouteRecordRaw } from "vue-router";

const ShipperWrapper = () => import("@/pages/pricing/shipper/ShipperWrapper.vue");
const ShipperRequest = () => import("@/pages/pricing/shipper/ShipperRequest.vue");
const ShipperReview = () => import("@/pages/pricing/shipper/ShipperReview.vue");
const ShipperConfirmation = () => import("@/pages/pricing/shipper/ShipperConfirmationPage.vue");

const routes: RouteRecordRaw = {
  name: "shipperPage",
  path: "/shipper",
  component: ShipperWrapper,
  children: [
    {
      name: "shipperRequest",
      path: ":companyId/request/new",
      component: ShipperRequest,
      meta: {
        title: "Telegraph - New Quote Request",
      },
      beforeEnter: async (to) => {
        const shipperStore = useShipperStore();
        shipperStore.companyId = to.params.companyId as string;
      },
    },
    {
      name: "shipperReview",
      path: ":companyId/quote/:quoteId",
      component: ShipperReview,
      meta: {
        title: "Telegraph - Review Quote",
      },
      beforeEnter: async (to) => {
        const shipperStore = useShipperStore();
        const notifier = useNotificationStore();
        shipperStore.companyId = to.params.companyId as string;
        shipperStore.quoteId = to.params.quoteId as string;
        shipperStore.token = to.query.token as string;
        await shipperStore.getQuote().catch((error) => {
          console.error("Unable to load quote: ", error);
          notifier.setToast("danger", "Unable to load quote. Please submit a new request or contact your partner.", 0);
          return { name: "shipperRequest", params: { companyId: to.params.companyId as string } };
        });

        if (!shipperStore.receivedQuote) {
          notifier.setToast("danger", "Unable to load quote. Please submit a new request or contact your partner.", 0);
          return { name: "shipperRequest", params: { companyId: to.params.companyId as string } };
        }

        // Handle deleted first
        if (shipperStore.receivedQuote.deleted) {
          return {
            name: "shipperConfirmation",
            params: {
              companyId: to.params.companyId as string,
              quoteId: to.params.quoteId as string,
            },
            query: {
              action: shipperActions.DELETED,
            },
          };
        }

        switch (shipperStore.receivedQuote.statusCode) {
          case 50:
            return {
              name: "shipperConfirmation",
              params: {
                companyId: to.params.companyId as string,
                quoteId: to.params.quoteId as string,
              },
              query: {
                action: shipperActions.ACCEPT,
              },
            };
          case 3:
            return {
              name: "shipperConfirmation",
              params: {
                companyId: to.params.companyId as string,
              },
              query: {
                action: shipperActions.REJECT,
              },
            };
          case 4:
            return {
              name: "shipperConfirmation",
              params: {
                companyId: to.params.companyId as string,
                quoteId: to.params.quoteId as string,
              },
              query: {
                action: shipperActions.DELETED,
              },
            };
          default:
            return;
        }
      },
    },
    {
      name: "shipperConfirmation",
      path: ":companyId/confirmation/:quoteId",
      component: ShipperConfirmation,
      meta: {
        title: "Telegraph - Quote Confirmation",
      },
    },
  ],
};

export default routes;
