import { RouteRecordRaw } from "vue-router";
import { useAdminUserStore } from "@/stores/admin/users";

const AdminPage = () => import("@/pages/admin/AdminPage.vue");
const UserPage = () => import("@/pages/admin/UserPage.vue");
const UserList = () => import("@/components/admin/users/UserList.vue");
const UserView = () => import("@/components/admin/users/UserView.vue");
const UserCreate = () => import("@/components/admin/users/UserCreate.vue");
const CustomerPage = () => import("@/pages/admin/CustomerPage.vue");
const CustomerList = () => import("@/components/admin/customers/CustomerList.vue");
const CustomerCreate = () => import("@/components/admin/customers/CustomerCreate.vue");
const CustomerView = () => import("@/components/admin/customers/CustomerView.vue");

const routes: RouteRecordRaw = {
  name: "AdminPage",
  path: "/admin",
  component: AdminPage,
  meta: {
    authenticated: true,
    flag: "hasInternalToolsModule",
    title: "Telegraph - Internal Tools",
    breadcrumbs: [
      { label: "Internal" },
      { label: "Users", to: "adminUserList", on: ["adminUserList", "adminUserCreate", "adminUserEdit"] },
      { label: "New User", on: ["adminUserCreate"] },
      { label: "Edit User", on: ["adminUserEdit"] },
      {
        label: "Customers",
        to: "adminCustomerList",
        on: ["adminCustomerList", "adminCustomerEdit", "adminCustomerCreate"],
      },
      { label: "New Customer", on: ["adminCustomerCreate"] },
      { label: "Edit Customer", on: ["adminCustomerEdit"] },
    ],
  },
  children: [
    {
      name: "adminUsers",
      path: "users",
      component: UserPage,
      children: [
        {
          name: "adminUserList",
          path: "",
          component: UserList,
          beforeEnter(to) {
            if (!to.query.user_status) {
              to.query.user_status = "active";
              return to;
            }
          },
        },
        {
          name: "adminUserCreate",
          path: "new",
          component: UserCreate,
          meta: {
            title: "Telegraph - New User",
          },
        },
        {
          name: "adminUserView",
          path: ":userId",
          component: UserView,
          props: true,
          async beforeEnter(to) {
            const adminUsers = useAdminUserStore();
            adminUsers.selectedUserId = to.params.userId as string;
            await adminUsers.getUser();
            document.title = `Telegraph - ${adminUsers.selectedUser?.fullName ?? "View User"}`;
          },
        },
      ],
    },
    {
      name: "adminCustomers",
      path: "customers",
      component: CustomerPage,
      children: [
        {
          name: "adminCustomerList",
          path: "",
          component: CustomerList,
          beforeEnter(to) {
            if (!to.query.customer_status) {
              to.query.customer_status = "active";
              return to;
            }
          },
        },
        {
          name: "adminCustomerCreate",
          path: "new",
          component: CustomerCreate,
          meta: {
            title: "Telegraph - New Customer",
          },
        },
        {
          name: "adminCustomerView",
          path: ":customerId",
          component: CustomerView,
          props: true,
          async beforeEnter(to) {
            if (!to.params.customerId) {
              return { name: "adminCustomerList" };
            }
          },
        },
      ],
    },
  ],
};

export default routes;
