export interface CustomerTableauIntegration {
  config: {
    token: string;
  };
  views: {
    label: string;
    report_type: string;
    url: string;
  }[];
}

export interface CustomerParty {
  id: string;
  name: string;
  count: number;
}

export interface APICustomer {
  business_name: string;
  crm_url: string | null;
  domain: string;
  id: string;
  integrations?: Record<string, CustomerTableauIntegration>;
  logo: string;
  name: string;
  status: number;
  type: string;
  type_id: number;
  waybill_type: "X" | "R" | "BOTH";
  waybill_type_safe: string;
  zip_code: string | null;
}

export interface APICustomerDetails extends APICustomer {
  equipment_ids: string[];
  fleet_ids: string[];
  parties: CustomerParty[];
  reporting_groups: { id: string; name: string }[];
  scacs: string[];
  users: {
    id: string;
    full_name: string;
    status: number;
    role_id: number;
    role_name: string;
  }[];
}

export interface Customer {
  businessName: string;
  crmUrl: string | null;
  domain: string;
  customerId: string;
  integrations?: Record<string, CustomerTableauIntegration>;
  logo: string;
  customerName: string;
  status: number;
  type: string;
  typeId: number;
  waybillType: "X" | "R" | "BOTH";
  waybillTypeSafe: string;
  zipCode: string | null;
}

export interface CustomerDetails extends Customer {
  equipmentIds: string[];
  fleetIds: string[];
  parties: CustomerParty[];
  reportingGroups: { id: string; name: string }[];
  scacs: string[];
  users: {
    id: string;
    fullName: string;
    status: number;
    roleId: number;
    roleName: string;
  }[];
}

export interface CustomerForm {
  name: string;
  businessName: string;
  typeId: number;
  domain: string;
  logoUrl: string;
  waybillType: "X" | "R" | "BOTH";
  billingZip: string;
  editMode: "edit" | "create";
  errorMsg: string;
  userDidRequestDelete: boolean;
  isLoading: boolean;
}

export interface APICustomerForm {
  name: string;
  business_name: string;
  company_type: number;
  domain: string;
  logo_url: string;
  waybill_type: "X" | "R" | "BOTH";
  zip_code: string;
}

export interface APICustomerFleet {
  id: string;
  name: string;
  description: string;
  fleet_code: string;
}

export interface CustomerFleet {
  id: string;
  name: string;
  description: string;
  fleetCode: string;
}

export function makeCustomerFleet(data: APICustomerFleet): CustomerFleet {
  const { fleet_code, ...rest } = data;
  return {
    ...rest,
    fleetCode: fleet_code,
  };
}

export function makeCustomer(data: APICustomer): Customer {
  return {
    businessName: data.business_name,
    crmUrl: data.crm_url,
    domain: data.domain,
    customerId: data.id,
    integrations: data.integrations,
    logo: data.logo,
    customerName: data.name,
    type: data.type,
    typeId: data.type_id,
    waybillType: data.waybill_type,
    waybillTypeSafe: data.waybill_type_safe,
    zipCode: data.zip_code,
    status: data.status,
  };
}

export function makeCustomerDetails(data: APICustomerDetails): CustomerDetails {
  return {
    ...makeCustomer(data),
    equipmentIds: data.equipment_ids,
    fleetIds: data.fleet_ids,
    parties: data.parties,
    reportingGroups: data.reporting_groups,
    scacs: data.scacs,
    users: data.users.map((user) => ({
      id: user.id,
      fullName: user.full_name,
      status: user.status,
      roleId: user.role_id,
      roleName: user.role_name,
    })),
  };
}
