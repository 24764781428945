import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import type { HandlerParams } from "@/bapi-client/types/handlerParams";
import type { HandlerResponse } from "@/bapi-client/types/handlerResponse";
import type { BAPIParams } from "@/bapi-client/types/bapiParams";
import { fetchHandler } from "@/bapi-client/fetch";

export async function getOrigins(
  opts: HandlerParams[typeof BAPI_COMMANDS.GET_ORIGINS],
): HandlerResponse[typeof BAPI_COMMANDS.GET_ORIGINS] {
  const path = `/reports/${opts.customerId}/origins`;

  const body: BAPIParams[typeof BAPI_COMMANDS.GET_ORIGINS] = {
    reporting_group_ids: opts.reportingGroupIds,
  };
  if (opts.search) {
    body.search = opts.search;
  }
  const response = await fetchHandler(BAPI_COMMANDS.GET_ORIGINS, "POST", path, { body });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const jsonResult = await data.json();

  return !jsonResult.success ? jsonResult : { success: true, data: jsonResult.data.data };
}

export async function getDestinations(
  opts: HandlerParams[typeof BAPI_COMMANDS.GET_DESTINATIONS],
): HandlerResponse[typeof BAPI_COMMANDS.GET_DESTINATIONS] {
  const path = `/reports/${opts.customerId}/destinations`;

  const body: BAPIParams[typeof BAPI_COMMANDS.GET_DESTINATIONS] = {
    reporting_group_ids: opts.reportingGroupIds,
  };
  if (opts.search) {
    body.search = opts.search;
  }
  const response = await fetchHandler(BAPI_COMMANDS.GET_DESTINATIONS, "POST", path, { body });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const jsonResult = await data.json();

  return !jsonResult.success ? jsonResult : { success: true, data: jsonResult.data.data };
}

export async function getAllLocations(
  opts: HandlerParams[typeof BAPI_COMMANDS.GET_ALL_LOCATIONS],
): HandlerResponse[typeof BAPI_COMMANDS.GET_ALL_LOCATIONS] {
  const path = `/locations`;

  const body: BAPIParams[typeof BAPI_COMMANDS.GET_ALL_LOCATIONS] = {
    search: opts.search,
  };
  const response = await fetchHandler(BAPI_COMMANDS.GET_ALL_LOCATIONS, "POST", path, { body });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const jsonResult = await data.json();

  return !jsonResult.success ? jsonResult : { success: true, data: jsonResult.data.data };
}
