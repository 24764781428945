<script setup lang="ts">
import { computed, inject, ref } from "vue";
import AppPopover from "@/components/app/AppPopover.vue";
import { OutlineButton, TgButton, TgCard } from "@/components/common";

import type { InputOptionsData } from "@/types/ediBuilder";

const labelOptions = [
  { value: "carta_porte_uuid", label: "carta_porte_uuid" },
  { value: "commodity_quantity", label: "commodity_quantity" },
  { value: "commodity_weight", label: "commodity_weight" },
  { value: "equipment_initial", label: "equipment_initial" },
  { value: "equipment_number", label: "equipment_number" },
  { value: "lading_quantity", label: "lading_quantity" },
  { value: "line_item_weight", label: "line_item_weight" },
  { value: "m3_date", label: "m3_date" },
  { value: "m3_release_code", label: "m3_release_code" },
  { value: "m3_time", label: "m3_time" },
  { value: "n9_bol_date", label: "n9_bol_date" },
  { value: "n9_bol_number", label: "n9_bol_number" },
  { value: "n9_bol_time", label: "n9_bol_time" },
  { value: "n9_cn_number", label: "n9_cn_number" },
  { value: "net_weight", label: "net_weight" },
  { value: "shipment_number", label: "shipment_number" },
];

const props = defineProps<{
  fieldId: string;
  currentLabel: string;
}>();

const editForm = ref();
const selectedLabel = ref("carta_porte_uuid");

const isOriginalLabel = computed(() => {
  const labels = labelOptions.map((label) => label.value);
  return !labels.includes(props.currentLabel);
});

const data = inject<{
  isVisible: boolean;
  updateVariableInputData: (options: InputOptionsData) => void;
  resetInputData: (fieldId: string) => void;
}>("editEdiFormData");

function handleUpdateInputDetails() {
  if (data?.updateVariableInputData) {
    data.updateVariableInputData({ label: selectedLabel.value, fieldId: props.fieldId });
  }
}

function handleReset() {
  if (data?.resetInputData) {
    data.resetInputData(props.fieldId);
  }
}
</script>

<template>
  <AppPopover v-if="data?.isVisible" :allowed-placements="['bottom', 'bottom-end', 'bottom-start']">
    <template #popoverButton>
      <i class="fa-solid fa-pen text-blue-400" />
    </template>
    <template #default="{ close }">
      <TgCard class="!w-64 text-gray-900">
        <FormKit
          ref="editForm"
          v-slot="{ state: { valid } }"
          type="form"
          :actions="false"
          @submit="handleUpdateInputDetails()"
        >
          <FormKit
            v-model="selectedLabel"
            outer-class="mb-6"
            type="select"
            :options="labelOptions"
            label="Select label"
          />
          <div class="flex items-center justify-between">
            <OutlineButton
              is-small
              color="danger"
              :disabled="isOriginalLabel"
              @click.prevent="
                handleReset();
                close();
              "
            >
              Reset
            </OutlineButton>
            <TgButton
              is-small
              :disabled="!valid"
              @click="
                editForm?.value?.node.submit();
                close();
              "
            >
              Save
            </TgButton>
          </div>
        </FormKit>
      </TgCard>
    </template>
  </AppPopover>
</template>
