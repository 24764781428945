import ForecastReportEquipment from "./reportEquipment";

interface ForecastDay {
  totalInbound: number;
  groups: { [key: string]: number };
}

export type ForecastRows = { [key: string]: ForecastDay };

interface ForecastReportModel {
  groupIds: string[];
  days: ForecastRows;
  total: number;
  equipment: ForecastReportEquipment[];
}

export default class ForecastReport implements ForecastReportModel {
  constructor(
    response: any,
    public groupIds: string[] = response.data.group_ids,
    public days: ForecastRows = response.data.inbound
      ? Object.entries(response.data.inbound).reduce((days: ForecastRows, [date, forecast]: [string, any]) => {
          days[date] = {
            totalInbound: forecast.total_inbound,
            groups: forecast.groups,
          };
          return days;
        }, {})
      : {},
    public total: number = response.record_count,
    public equipment: ForecastReportEquipment[] = [],
  ) {}
}
