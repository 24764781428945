import dayjs from "dayjs";
import { RouteRecordRaw } from "vue-router";
import useNetworkMapStore from "@/stores/network";
import useForecastStore from "@/stores/forecast";
import { usePipelineStore } from "@/stores/pipeline";

const ReportsPage = () => import("@/pages/reports/ReportsPage.vue");
const ForecastReportPage = () => import("@/pages/reports/ForecastReportPage.vue");
const NetworkMapReportPage = () => import("@/pages/reports/NetworkMapReportPage.vue");
const PipelineReportPage = () => import("@/pages/reports/PipelineReportPage.vue");

const routes: RouteRecordRaw = {
  name: "reports",
  path: "/reports/:companyId",
  component: ReportsPage,
  props: true,
  meta: {
    authenticated: true,
    flag: "hasReportingModule",
    breadcrumbs: [
      { label: "Reporting" },
      { label: "Network Maps", on: ["networkMapReport"] },
      { label: "Forecasting", on: ["forecastReport"] },
      { label: "Pipeline View", on: ["pipelineReport"] },
      {
        label: "Report Builder",
        to: "customReports",
        on: ["customReports", "reportBuilderNew", "reportBuilderEdit", "reportBuilderView", "reportBuilderDashboard"],
      },
      {
        label: "New Report",
        on: ["reportBuilderNew"],
      },
      {
        label: "Edit Report",
        on: ["reportBuilderEdit"],
      },
      {
        label: "View Report",
        on: ["reportBuilderView"],
      },
    ],
  },
  children: [
    {
      name: "pipelineReport",
      path: "pipeline",
      component: PipelineReportPage,
      meta: {
        title: "Telegraph - Pipeline View",
      },
      beforeEnter: async (to) => {
        const store = usePipelineStore();
        if (to.query.groupIds) {
          store.selectedReportingGroups = (to.query.groupIds as string).split("|");
        }
        if (to.query.destinations) {
          store.selectedDestinations = (to.query.destinations as string).split("|");
        }

        if (to.query.maxAge) {
          store.maxAge = to.query.maxAge as string;
        }

        if (to.query.sortColumn) {
          store.sortColumn =
            to.query.sortColumn === "etaDate" ? ["teleETADate", "railETADate"] : "" + to.query.sortColumn;
        }

        if (to.query.sortDirection) {
          store.sortDirection = to.query.sortDirection as typeof store.sortDirection;
        }

        return;
      },
    },
    {
      name: "networkMapReport",
      path: "network",
      component: NetworkMapReportPage,
      meta: {
        flag: "hasNetworkMapModule",
        title: "Telegraph - Network Map",
      },
      beforeEnter: async (to) => {
        const store = useNetworkMapStore();

        await store.getGroups();

        if (to.query.groupId) {
          store.selectedGroupId = to.query.groupId.toString();

          if (to.query.fromDate) {
            store.fromDate = dayjs(to.query.fromDate.toString()).toDate();
          }

          if (to.query.toDate) {
            store.toDate = dayjs(to.query.toDate.toString()).toDate();
          }

          await store.generateReport();
        }
      },
    },
    {
      name: "forecastReport",
      path: "forecast",
      component: ForecastReportPage,
      meta: {
        flag: "hasForecastingReportModule",
        title: "Telegraph - Forecasting",
      },
      beforeEnter: async (to) => {
        const store = useForecastStore();

        if (to.query.destinations) {
          store.selectedDestinationIds = to.query.destinations.toString().split("|");
        }

        if (to.query.groups) {
          store.selectedGroupIds = to.query.groups.toString().split("|");
        }
        if (to.query.fleetOne) {
          store.fleetOne = to.query.fleetOne.toString();
        }

        if (to.query.fleetTwo) {
          store.fleetTwo = to.query.fleetTwo.toString();
        }

        if (to.query.fleetThree) {
          store.fleetThree = to.query.fleetThree.toString();
        }

        if (to.query.modalDate) {
          if (to.query.modalGroupId) {
            store.modalGroupId = to.query.modalGroupId.toString();
          }
          store.modalDate = to.query.modalDate.toString();
        }

        if (to.query.destinations || to.query.groups) {
          await store.generateReport();
        }
        await store.getDestinations();
        await store.getGroups();
      },
    },
    {
      name: "customReports",
      path: "custom/:sub?",
      redirect: (to) => {
        return {
          name: "reportBuilderDashboard",
          params: { companyId: to.params.companyId },
        };
      },
    },
  ],
};

export default routes;
