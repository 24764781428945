import { toAlphanumeric } from "@/utils/text";
import Location from "./location";

interface ReportingGroupModel {
  id: string;
  name: string;
  searchTag: string;
  destinations?: Location[];
  origins?: Location[];
  equipmentIds?: string[];
  partyIds?: string[];
}

export default class ReportingGroup implements ReportingGroupModel {
  constructor(
    response: any,
    public id: string = response.id,
    public name: string = response.name || "Unknown",
    public searchTag: string = response.name || response.label ? toAlphanumeric(response.name || response.label) : "",
    public destinations: Location[] = [],
    public origins: Location[] = [],
    public equipmentIds: string[] = response.equipment_ids || [],
    public partyIds: string[] = response.party_ids || [],
  ) {}
}
