import { App, Plugin } from "vue";

const plugin: Plugin = {
  install: (app: App) => {
    app.config.globalProperties.$status = (statusCode: number) => {
      switch (statusCode) {
        case 0:
          return "Released";
        case 10:
          return "Origin";
        case 20:
          return "Ingate";
        case 30:
          return "Ramped";
        case 40:
          return "In Transit";
        case 50:
          return "Destination";
        case 60:
          return "Deramped";
        case 70:
          return "CP";
        case 80:
          return "Notify";
        case 90:
          return "AP";
        case 100:
          return "Complete";
        default:
          return "NA";
      }
    };
  },
};

export default plugin;
