import type {
  AutocompleteUser,
  BAPIAutocompleteUser,
  AutocompleteAsset,
  BAPIAutocompleteAsset,
} from "@/bapi-client/types/autocomplete";
import { userProfilePictureFactory } from "../users";

export function autocompleteUserFactory(res: BAPIAutocompleteUser): AutocompleteUser {
  return {
    firstName: res.first_name,
    lastName: res.last_name,
    profilePicture: userProfilePictureFactory(res.profile_picture),
    email: res.email,
    userId: res.user_id,
    get fullName() {
      return `${this.firstName} ${this.lastName}`;
    },
  };
}

export function autocompleteAssetFactory(res: BAPIAutocompleteAsset): AutocompleteAsset {
  return {
    equipmentId: res.equipment_id,
    waybillId: res.waybill_id,
    loadEmptyStatusCode: res.load_empty_status_code,
  };
}
